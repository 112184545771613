import React, { useState, useEffect } from 'react';
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.css";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import CalendarInput from '../commons/input/CalendarInput';
import i18n from '../../utilities/i18n';
import { useSelector } from 'react-redux';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Label from '../commons/Label';
import { DRUG_UNITS, EmgList, MEASUREMENT_UNITS } from '../../utilities/dictionaryConstants';
import SelectInput from '../commons/input/SelectInput';
import ProcedureActivityView from '../ClaimsModule/SearchClaims/ProcedureActivityView';
import TextInput from '../commons/input/input';
import CurrencyInputs from '../commons/input/CurrencyInput';
import HistoryIcon from "@mui/icons-material/History";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DRUD_CODE_LENGTH } from '../../utilities/staticConfigs';
import { useDispatch } from 'react-redux';
import { GetCPTList, GetModifierList, GetPOSList, GetTOSList } from './StateManagement/DropDownOptionSlice';
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { GetCPTCodeListWithDate } from './StateManagement/asyncThunkAPI';
import { removeProcedureRowItem, updateModifierList, updatePOSList, updateProcedureCPT, updateProcedureOtherDetails, updateTOSList } from "./StateManagement/ClaimModuleSlice";
import service from './Services/service'
import moment from 'moment';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const debounce = (func, delay) => {
    let timeoutId;
    return function (input) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(input), delay);
    };
};

const Procedures = (props) => {

    const dispatch = useDispatch();
    const {
        inputM1Validated,
        inputM2Validated,
        inputM3Validated,
        inputM4Validated,
        claimPK,
        billingInfoDetails,
        professionalClaimDetails
    } = useSelector((state) => state.claimManagement);

    const {
        CPTLIST,
        posCodeList,
        tosCodeList,
        modifierList1,
        modifierList2,
        modifierList3,
        modifierList4,
        // claimStatus,
        // claimSubStatus,
    } = useSelector((state) => state.claimSelectInputManager);

    const { responsibility } = useSelector(
        (state) => state.claimSelectInputManager
    );


    const procedure = props.procedure;
    const propindex = props.index;
    /*************************** */
    // eslint-disable-next-line
    const [paymentDone, setPaymentDone] = useState(false);
    // increase drop-down list on click ('Load)
    const [maxResults, setMaxResults] = useState(5);
    const [procedureActivityAnchor, seProcedureActivityAnchor] = useState(null);
    const [isProcedureActivityViewOpen, setIsProcedureActivityViewOpen] = useState(false);
    //to expand other fields
    const [expand, setExpand] = useState(false);
    // line not mndatory line variable
    const [line_note_mandatory_line, setLine_note_mandatory_line] = useState(false);
    //variable for validating drag code
    const [validDrugCode, setValidDrugCode] = useState(true);

    useEffect(() => {
        if (props.procedure.line_note) {
            setLine_note_mandatory_line(() => {
                return props.procedure.line_note.length < 2;
            });
        } else {
            setLine_note_mandatory_line(false);
        }
    }, [props.procedure.line_note]);

    /**
     * For searching cpt list of practice
     * @param {*} query
     */
    const onSearchCPTCodes = debounce((query) => {
        let dos = "";
        if (procedure.dos_from) {
            dos = format(new Date(procedure.dos_from), "yyyy/MM/dd", {
                locale: enUS,
            });
        } else {
            dos = null;
        }
        dispatch(GetCPTList({ query: query, claimPK: claimPK, dos: dos }))
    }, 250);

    /**
     * function to search available pos code
     * @param {*} query 
     */
    const getPosCodes = debounce((query) => {
        dispatch(GetPOSList(query))
    }, 250)

    /**
     * Function to search available tos code
     * @param {*} query 
     */
    const getTosCodes = debounce((query) => {
        dispatch(GetTOSList(query));
    }, 250)

    /**
     * Function to search available modifier1 code
     * @param {*} query 
     */
    const onSearchModifiers1 = debounce((query) => {
        dispatch(GetModifierList({ query: query, modifier: "modifierList1" }));
    }, 250);

    /**
    * Function to search available modifier1 code
    * @param {*} query 
    */
    const onSearchModifiers2 = debounce((query) => {
        dispatch(GetModifierList({ query: query, modifier: "modifierList2" }));
    }, 250);

    /**
    * Function to search available modifier1 code
    * @param {*} query 
    */
    const onSearchModifiers3 = debounce((query) => {
        dispatch(GetModifierList({ query: query, modifier: "modifierList3" }));
    }, 250);

    /**
    * Function to search available modifier1 code
    * @param {*} query 
    */
    const onSearchModifiers4 = debounce((query) => {
        dispatch(GetModifierList({ query: query, modifier: "modifierList4" }));
    }, 250);

    function selectOnTab(e) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active")?.click();
        }
    }

    function selectOnTabPOS(e, propIndex) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active")?.click();
        } else {
            posHandleChange(e, propIndex);
        }
    }

    function selectOnTabTos(e, propIndex) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active")?.click();
        } else {
            tosHandleChange(e, propIndex);
        }
    }

    /**
     * open activity slider on button click
     */

    const handleCloseProcedureActivityView = () => {
        seProcedureActivityAnchor(null);
        setIsProcedureActivityViewOpen(false);
    };


    /**
     * target the view icon
     * @param {*} e 
     */
    const handleProcedureActivityViewIcon = (e) => {
        seProcedureActivityAnchor(e.currentTarget);
        setIsProcedureActivityViewOpen(true);
    };

    /**
     * to format entered drag code to universal format
     * @param {*} rawValue 
     * @returns 
     */
    const formatDrugCode = (rawValue) => {
        let formattedValue = '';
        for (let i = 0; i < rawValue?.length; i++) {
            if (i === 5 || i === 9) {
                formattedValue += '-';
            }
            formattedValue += rawValue.charAt(i);
        }
        return formattedValue;
    };

    /**
     * formatting drag code to universal format on input change checking it is validate or not
     * @param {*} value 
     * @returns 
     */
    const handleDrugCodeValidation = (value) => {
        const trimmedValue = String(value.trim()).replace(/[^\d]/g, '').substr(0, DRUD_CODE_LENGTH);
        const formattedValue = formatDrugCode(trimmedValue);
        const isValid = formattedValue.length === 13 || !formattedValue;
        setValidDrugCode(isValid);
        return formattedValue;
    };


    const onProcedureCPTHandleChange = (e, field, rowId) => {
        dispatch(updateProcedureCPT({ valueList: e, field: field, rowId: rowId, claimPK: claimPK }))
    }

    const posHandleChange = (e, rowId) => {
        dispatch(updatePOSList({ valueList: e.length > 0 ? e : [], rowId: rowId, claimPK: claimPK }))
    }


    const tosHandleChange = (e, rowId) => {
        dispatch(updateTOSList({ valueList: e.length > 0 ? e : [], rowId: rowId, claimPK: claimPK }))
    }

    const onProcedureModifierHandleChange = (e, name, rowId) => {
        dispatch(updateModifierList({ valueList: e.length > 0 ? e : [], name: name, rowId: rowId, claimPK: claimPK }))
    };

    const onProcedureHandleChange = (e, rowId) => {
        let name = e.target.name;
        let value = e.target.value;
        dispatch(
            updateProcedureOtherDetails({
                value: value,
                field: name,
                rowId: rowId,
                claimPK: claimPK,
            })
        );

    }

    const onProcedureDateHandleChange = (value, name, index) => {
        let type = "dropdown";
        const formattedDateForRedux = value instanceof Date ? moment(value).format("yyyy-MM-dd") : null;
        let query = procedure?.cpt_hcpcsList[0]?.name;
        if (query && formattedDateForRedux != 'Invalid date' && formattedDateForRedux!==null) {
            let dos = format(new Date(value), "yyyy/MM/dd", { locale: enUS });
            dispatch(
                GetCPTCodeListWithDate({
                    type: type,
                    query: query,
                    claimPK: claimPK,
                    dos: dos,
                    name: name,
                    index: index,
                    value: value
                })
            );
        }
        else {
            if (value) {
                try {
                    dispatch(
                        updateProcedureOtherDetails({
                            value: value,
                            field: name,
                            rowId: index,
                            claimPK: claimPK,
                        })
                    );
                }
                catch (e) {
                    dispatch(
                        updateProcedureOtherDetails({
                            value: "",
                            field: name,
                            rowId: index,
                            claimPK: claimPK,
                        })
                    );
                }
            }
            else {
               dispatch(
                   updateProcedureOtherDetails({
                       value: "",
                       field: name,
                       rowId: index,
                       claimPK: claimPK,
                   })
               ); 
            }
        }
    };

    const onBlur = (e, rowId, procedureList) => {
        if (procedureList?.length == 0 && e.target.value) {
            let result = service.SearchPosCodes(e.target.value);
            result.then((response) => {
                if (response.data) {
                    let data = [
                        {
                            id: response.data.results[0].id,
                            label: response.data.results[0].drop_down_name,
                            name: response.data.results[0].drop_down_name,
                        },
                    ];

                    dispatch(
                        updatePOSList({
                            valueList: data,
                            rowId: rowId,
                            claimPK: claimPK,
                        })
                    );
                }
            })
        }
    }

    const handleProcedureRowRemove = () => {
        dispatch(removeProcedureRowItem({ procedureId: procedure.id, index: props.index, claimPK: claimPK }))
    }



    return (
        <div className="procedure_box" id={`procedure_row_${procedure.id}`}>
            {propindex > 0 && !paymentDone && (
                <IconButton
                    className="float-right"
                    style={{ marginTop: 8, marginRight: 8 }}
                    onClick={handleProcedureRowRemove}
                >
                    <DeleteIcon />
                </IconButton>
            )}
            <Form>
                <div
                    className=""
                    style={
                        propindex > 0
                            ? {
                                marginLeft: 15,
                                paddingRight: 20,
                                paddingTop: 30,
                            }
                            : {
                                marginLeft: 15,
                                paddingRight: 20,
                                paddingTop: 20,
                            }
                    }>
                    <div className="justify-space-between">
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <CalendarInput
                                    id={`${procedure.id}'-'${propindex}`}
                                    name={"dos_from"}
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.serviceLine.dateOfService_Start"
                                    )}
                                    selected={procedure.dos_from}
                                    required={true}
                                    minDate={professionalClaimDetails?.patient_dob}
                                    maxDate={billingInfoDetails?.date_of_death ?? new Date()}
                                    onValueChange={(value) => onProcedureDateHandleChange(value, "dos_from", propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <CalendarInput
                                    id={`${procedure.id}'-'${propindex}`}
                                    name={"dos_to"}
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.serviceLine.dateOfService_End"
                                    )}
                                    selected={procedure.dos_to}
                                    required={true}
                                    minDate={procedure.dos_from?procedure.dos_from:professionalClaimDetails?.patient_dob}
                                    maxDate={billingInfoDetails?.date_of_death ?? new Date()}
                                    onValueChange={(value) => onProcedureDateHandleChange(value, "dos_to", propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.cpt")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    id={`${procedure.id}'-'${propindex}`}
                                    className={procedure.cpt_hcpcs ? "" : "input-error"}
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => onProcedureCPTHandleChange(e, "cpt_hcpcs", propindex)}
                                    options={CPTLIST}
                                    placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.cpt")}
                                    onSearch={onSearchCPTCodes}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    selected={procedure.cpt_hcpcsList}
                                    onMenuToggle={() => {
                                        if (procedure.cpt_hcpcsList) {
                                            onSearchCPTCodes()
                                        }
                                    }}
                                    clearButton={false}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "170px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div onClick={() => onProcedureCPTHandleChange([{ 'id': option.id, 'name': option.name, 'description': option.short_description }], 'cpt_hcpcs', propindex)}>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div onClick={() => onProcedureCPTHandleChange([{ 'id': option.id, 'name': option.name, 'description': option.short_description }], 'cpt_hcpcs', propindex)}>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        )
                                    }}

                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.pos")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    id={`${procedure.id}'-'${propindex}`}
                                    name={"procedure_pos_code" + propindex}
                                    className={!procedure.pos_id ? "input-error" : ""}
                                    labelKey={"name"}
                                    minLength={1}
                                    required={true}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onSearch={getPosCodes}
                                    options={posCodeList}
                                    onBlur={(e) => onBlur(e, propindex, procedure.pos_id_List)}
                                    onChange={(e) => posHandleChange(e, propindex)}
                                    selected={procedure.pos_id_List}
                                    onKeyDown={(e) => selectOnTabPOS(e, propindex)}
                                    placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.pos")}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "170px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    onMenuToggle={() => {
                                        if (!procedure.pos_id_List.length) return;
                                        const isItemAlreadyInList = posCodeList.some(item => item.id === procedure.pos_id_List[0].id);
                                        if (!isItemAlreadyInList) {
                                            getPosCodes(procedure.pos_id_List[0].id);
                                        }
                                    }}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.drop_down_name;
                                        return (
                                            <>
                                                <div>
                                                    <span>{description}</span>
                                                </div>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.tos")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    id={`${procedure.id}'-'${propindex}`}
                                    name={"procedure_tos_code" + propindex}
                                    labelKey={"name"}
                                    minLength={1}
                                    onSearch={getTosCodes}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    options={tosCodeList}
                                    onChange={(e) => tosHandleChange(e, propindex)}
                                    selected={procedure.tos_id_List}
                                    onKeyDown={(e) => selectOnTabTos(e, propindex)}
                                    placeholder={i18n.t("searchClaims.professionalClaims.serviceLine.tos")}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "170px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    onMenuToggle={() => {
                                        if (!procedure.tos_id_List.length) return;
                                        const isItemAlreadyInList = tosCodeList.some(item => item.id === procedure.tos_id_List[0].id);
                                        if (!isItemAlreadyInList) {
                                            getTosCodes(procedure.tos_id_List[0].id);
                                        }
                                    }}

                                    renderMenuItemChildren={(option) => {
                                        let description = option.drop_down_name;
                                        return (
                                            <>
                                                <div>
                                                    <span>{description}</span>
                                                </div>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text60">
                            <div className="form-group" >
                                <SelectInput
                                    id={`${procedure.id}'-'${propindex}`}
                                    data={EmgList}
                                    name={"emg"} value={procedure.emg} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.emg")} selectOptionRemove={true} />
                            </div>
                        </div>
                        <div className="input-procedure-text60">
                            <Label label={i18n.t("searchClaims.professionalClaims.serviceLine.modifiers")} />
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={!inputM1Validated[propindex] ? "input-error" : ""}
                                    id={`${procedure.id}'-'${propindex}`}
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => onProcedureModifierHandleChange(e, "m1_id", propindex)}
                                    options={modifierList1}
                                    placeholder="M1"
                                    onSearch={onSearchModifiers1}
                                    onMenuToggle={() => {
                                        if (!procedure?.m1List?.length) return;
                                        const isItemAlreadyInList = modifierList1.some(item => item.name === procedure.m1List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers1(procedure.m1List)
                                        }
                                    }}
                                    selected={procedure.m1List}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={inputM2Validated[propindex] ? "" : "input-error"}
                                    id={`${procedure.id}'-'${propindex}`}
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => onProcedureModifierHandleChange(e, "m2_id", propindex)}
                                    options={modifierList2}
                                    placeholder="M2"
                                    onSearch={onSearchModifiers2}
                                    selected={procedure.m2List}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    onMenuToggle={() => {
                                        if (!procedure?.m2List?.length) return;
                                        const isItemAlreadyInList = modifierList2.some(item => item.name === procedure.m2List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers2(procedure.m2List)
                                        }
                                    }}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={inputM3Validated[propindex] ? "" : "input-error"}
                                    id={`${procedure.id}'-'${propindex}`}
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => onProcedureModifierHandleChange(e, "m3_id", propindex)}
                                    options={modifierList3}
                                    placeholder="M3"
                                    onSearch={onSearchModifiers3}
                                    selected={procedure.m3List}
                                    onMenuToggle={() => {
                                        if (!procedure?.m3List?.length) return;
                                        const isItemAlreadyInList = modifierList3.some(item => item.name === procedure.m3List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers3(procedure.m3List)
                                        }
                                    }}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >
                                <AsyncTypeahead
                                    className={inputM4Validated[propindex] ? "" : "input-error"}
                                    id={`${procedure.id}'-'${propindex}`}
                                    labelKey="name"
                                    minLength={1}
                                    style={{ backgroundColor: '#fff', borderRadius: 10 }}
                                    onChange={(e) => onProcedureModifierHandleChange(e, "m4_id", propindex)}
                                    options={modifierList4}
                                    placeholder="M4"
                                    onSearch={onSearchModifiers4}
                                    selected={procedure.m4List}
                                    onMenuToggle={() => {
                                        if (!procedure?.m4List?.length) return;
                                        const isItemAlreadyInList = modifierList4.some(item => item.name === procedure.m4List[0].name);
                                        if (!isItemAlreadyInList) {
                                            onSearchModifiers4(procedure.m4List)
                                        }
                                    }}
                                    onKeyDown={(e) => selectOnTab(e)}
                                    renderMenuItemChildren={(option) => {
                                        let description = option.description;
                                        let isDescriptionTooLong = description.length > 40;
                                        let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                                        return (
                                            <>
                                                {isDescriptionTooLong ?
                                                    <OverlayTrigger
                                                        placement={'right'}
                                                        delay={{ show: 250 }}
                                                        overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                                    >
                                                        <div>
                                                            <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <div>
                                                        <strong>{option.name}</strong> <span>{shortDescription}</span>
                                                    </div>
                                                }
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="justify-space-between">
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <TextInput type="text" name={"dx_pointers"} required={true}
                                    id={`${procedure.id}'-'${propindex}`}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.DXPointers")}
                                    value={procedure.dx_pointers ? procedure.dx_pointers : ''}
                                    onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <TextInput type="text" name={"fee"} required={true}
                                    id={`${procedure.id}'-'${propindex}`}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.fee")}
                                    disabled={paymentDone ? "disabled" : ""}
                                    value={procedure.fee ? procedure.fee : ''} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group" >
                                <TextInput type="text" name={"fee_units"} required={true}
                                    id={`${procedure.id}'-'${propindex}`}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.units")} disabled={paymentDone ? "disabled" : ""}
                                    value={procedure.fee_units ? procedure.fee_units : ''} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <CurrencyInputs type="text" name={"charges"} required={true} isAdornment={false}
                                    id={`${procedure.id}'-'${propindex}`}
                                    defaultClassName={procedure.cpt_hcpcs && procedure.charges == 0 ? "input-error" : ""}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.charges")} disabled={paymentDone ? "disabled" : ""}
                                    value={procedure.charges ? procedure.charges : ''} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text60">
                            <div className="form-group">
                                <SelectInput data={EmgList} name={"cpt_epsdt"}
                                    id={`${procedure.id}'-'${propindex}`}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.espdt")}
                                    value={procedure.cpt_epsdt} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group" >
                                <SelectInput data={responsibility}
                                    id={`${procedure.id}'-'${propindex}`} name={"responsibility_type"} selectOptionRemove={true}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.responsibility")}
                                    value={procedure.responsibility_type} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        {/*
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <SelectInput
                                    id={`${procedure.id}'-'${propindex}`}
                                    data={claimStatus?.filter(item => item.priorities?.includes(procedure?.responsibility_type))}
                                    name="claim_status"
                                    label={i18n.t("searchClaims.professionalClaims.claimStatus")}
                                    value={procedure.claim_status}
                                    onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        <div className="input-procedure-text">
                            <div className="form-group">
                                <SelectInput
                                    id={`${procedure.id}'-'${propindex}`}
                                    data={claimSubStatus?.filter(item => item.parent_claim_status_ids?.includes(procedure?.claim_status))}
                                    name="claim_sub_status"
                                    label={i18n.t("searchClaims.professionalClaims.claimSubStatus")}
                                    value={procedure.claim_sub_status}
                                    onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                        </div>
                        */}
                        <div className="procedure_icons">
                            {
                                (claimPK && procedure.insurance_message) &&
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip style={{ opacity: 0.5 }}>
                                        {procedure.insurance_message}
                                    </Tooltip>}
                                >
                                    <IconButton sx={{ background: 'transparent', borderRadius: '8px' }} className='float-right' ><InfoOutlinedIcon fontSize='large' /></IconButton>
                                </OverlayTrigger>
                            }
                        </div>
                        <div className="input-procedure-text60">

                            <div className="form-group" >

                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >

                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >

                            </div>
                        </div>
                        <div className="input-modifier-text60">
                            <div className="form-group" >
                                {
                                    claimPK &&
                                    <>
                                        <OverlayTrigger
                                            placement={'top'}
                                            delay={{ show: 250 }}
                                            overlay={<Tooltip id={`tooltip-right-activity-${procedure?.id}`} style={{ opacity: 0.5 }}>Procedure Activity Timeline</Tooltip>}
                                        >
                                            <IconButton sx={{ background: '#fff', borderRadius: '8px' }} className='float-right' onClick={(e) => handleProcedureActivityViewIcon(e)}><HistoryIcon fontSize='large' /></IconButton>
                                        </OverlayTrigger>
                                        <ProcedureActivityView
                                            procedurePk={procedure?.id}
                                            openActivityView={isProcedureActivityViewOpen}
                                            anchorEl={procedureActivityAnchor}
                                            onClose={handleCloseProcedureActivityView}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    {!expand ?
                        <div className="expan_box" onClick={() => setExpand(!expand)}>
                            {expand ? <IconButton><ExpandLessIcon style={{ fontSize: 30 }} /></IconButton> : <IconButton onClick={() => setExpand(!expand)}> <ExpandMoreIcon style={{ fontSize: 30 }} /></IconButton>}
                        </div>
                        : <></>
                    }

                    {expand ?
                        <TransitionGroup>
                            <CSSTransition
                                key={`procedure-expandable-${procedure.id}`}
                                timeout={700}
                                classNames="fade"
                                appear
                                exit
                                enter={false}
                            >
                                <div>
                        <div className="justify-space-between margin-top20">
                            <div className="input-procedure-text">
                                <TextInput type="text" name={"proced_description"}
                                    id={`${procedure.id}'-'${propindex}`}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.procedDesc")}
                                    value={procedure.proced_description} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                            <div className="input-procedure-text">
                                <TextInput type="text" name={"line_note"}
                                    id={`${procedure.id}'-'${propindex}`}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.lineNote")}
                                    defaultClassName={line_note_mandatory_line ? "input-error" : ""}
                                    value={procedure.line_note} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                            <div className="input-procedure-text">
                                <div className="form-group" >
                                    <TextInput
                                        id={`${procedure.id}'-'${propindex}`}
                                        type="text"
                                        name={"drug_code"}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.drugCode")}
                                        value={formatDrugCode(procedure?.drug_code)}
                                        onValueChange={(e) => {
                                            const formattedValue = handleDrugCodeValidation(e.target.value);
                                            onProcedureHandleChange({ target: { name: 'drug_code', value: formattedValue } }, propindex);
                                        }}
                                        defaultClassName={validDrugCode ? '' : "input-error"}
                                        helperText={!validDrugCode ? i18n.t("validations.errors.drugCode") : ''}
                                    />
                                </div>
                            </div>
                            <div className="input-procedure-text">
                                <SelectInput data={DRUG_UNITS}
                                    id={`${procedure.id}'-'${propindex}`}
                                    className={(procedure.drug_code && !procedure.drug_unit_type) ? "input-error" : ""}
                                    name={"drug_unit_type"} label={i18n.t("searchClaims.professionalClaims.serviceLine.drugUnits")}
                                    value={procedure.drug_unit_type} onValueChange={(e) => onProcedureHandleChange(e, propindex)} />
                            </div>
                            <div className="input-procedure-text60">
                                <TextInput type={"text"} name={"drug_unit_value"}
                                    id={`${procedure.id}'-'${propindex}`}
                                    defaultClassName={(procedure.drug_code && !procedure.drug_unit_value) ? "input-error" : ""}
                                    label={i18n.t("searchClaims.professionalClaims.serviceLine.units")}
                                    value={procedure.drug_unit_value} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                />
                            </div>
                            <div className="input-procedure-text">
                                <SelectInput data={MEASUREMENT_UNITS} id={`${procedure.id}'-'${propindex}`} label={i18n.t("searchClaims.professionalClaims.serviceLine.measurements")}
                                    name={"measurement_unit_type"} value={procedure.measurement_unit_type}
                                    onValueChange={(e) => onProcedureHandleChange(e, propindex)} />
                            </div>
                            <div className="input-procedure-text60">
                                <div className="form-group" >
                                    <TextInput type="text" name={"measurement_unit_value"}
                                        id={`${procedure.id}'-'${propindex}`}
                                        defaultClassName={(procedure.measurement_unit_type && !procedure.measurement_unit_value) ? "input-error" : ""}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.units")}
                                        value={procedure.measurement_unit_value} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                    />
                                </div>
                            </div>
                            <div className="input-procedure-text_anthesia">
                                <div className="form-group" >
                                    <TextInput type="text" name={"anesthesia"}
                                        id={`${procedure.id}'-'${propindex}`}
                                        label={i18n.t("searchClaims.professionalClaims.serviceLine.anesthesia")}
                                        value={procedure.anesthesia} onValueChange={(e) => onProcedureHandleChange(e, propindex)}
                                    />
                                </div>
                            </div>
                                    </div>
                                    <div className="expan_box" onClick={() => setExpand(!expand)}>
                                        {expand ? <IconButton><ExpandLessIcon style={{ fontSize: 30 }} /></IconButton> : <IconButton onClick={() => setExpand(!expand)}> <ExpandMoreIcon style={{ fontSize: 30 }} /></IconButton>}
                                    </div>
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                        : ""
                    }
                </div>
            </Form>
        </div>
    );
}

export default Procedures;