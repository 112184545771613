import React, { useContext, useEffect, useState } from 'react';
import Notify from '../commons/notify';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link, useHistory } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import i18n from '../../utilities/i18n';
import { permission_key_values, permission_key_values_claim } from '../../utilities/permissions';
import { checkPermission } from '../../utilities/commonUtilities';
import service from "./Services/service";
import { getStorage, setStorage } from '../../utilities/browserStorage';
import { DEFAULT_PAGING_SIZE, ROUTE_PAGE_LOCKS, ROUTE_PATIENTS_LEDGER, TAB1, TAB2, TAB3, TAB4,ROUTE_CLAIM_ACTION_LOGS} from '../../utilities/staticConfigs';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import PatientDropDownSearch from '../patientManagement/patients/patientDropDownSearch';
import RightSidePanel from '../commons/RightSidePanel/RightSidePanel';
import PatientsAdvancedSearch from '../patientManagement/patients/PatientsAdvancedSearch';
import { AUTHORIZATION_REFERRAL } from '../../utilities/dictionaryConstants';
import SelectInput from '../commons/input/SelectInput';
import CalendarInput from '../commons/input/CalendarInput';
import TextInput from '../commons/input/input';
import { ActivateInactiveClaim, addActiveSubTab, addRemoveFromSelectedTab, createAndUpdateClaim, getEditDetails, getPatientDetails, getPayerInsuranceDetails } from './StateManagement/asyncThunkAPI';
import { getClaimDetailsByClaimPK, onPatientDropDownSelectAction,removeClaimDetails,resetNotifyWindow,setActiveSubTab,showModalWindow,updateParentComponentData } from './StateManagement/ClaimModuleSlice';
import BillingInfo from './BillingInfo';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import ServiceLine from './ServiceLine';
import AdditionalClaimInfo from './AdditionalClaimInfo';
import format from 'date-fns/format';
import LoadingContext from '../../container/loadingContext';
import { GetClaimStatusData, GetClaimSubStatusData,getAllSelectInput, GetResponsibilityType, GetClaimSubStatusPkData, GetAccidentType, fetchIdentifiers  } from './StateManagement/DropDownOptionSlice';
import CustomizedSmallDialogs from '../modalWindowComponent/CustomisedSmallDialog';
import ReactHtmlParser from "react-html-parser";
import WarningPopUp from "../commons/warningPopUp";
import ClaimNotes from './ClaimNotes';
import { resetResponsibilityOnPatientOrClaimRemove } from './StateManagement/DropDownOptionSlice';

const ProfessionalClaim = (props) => {
    const practicePK = getStorage("practice");
    const dispatch = useDispatch();
    /*selector values declared here */
    const {
        claimPK,
        professionalClaimDetails,
        infoLoaded,
        dataLoading,
        CallInsurance,
        patientSelectedData,
        selectedPatient,
        open_pks,
        billingInfoDetails,
        patient_PK,
        notifyMessage,
        showNotification,
        statusTag,
        formWarningMessage,
        saveConfirmation,
        saveCloseConfirmation,
        formWarningData,
        activeSubTab,
        inactiveClaimModal,
        activeClaimModal,
        savedClaimDetails,
    } = useSelector((state) => state.claimManagement);

    const {
        isSelectInputDataLoaded,
        responsibility,
        claimSubStatus,
        claimStatus,
        identifierData,
    } = useSelector((state) => state.claimSelectInputManager);

    const setShowLoadingBar = useContext(LoadingContext);
    const history = useHistory();
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    /**
     * patient drop down search state heandled here
     */
    const [patientPK, setPatientPK] = useState("");
    const [selectPatient, setSelectPatient] = useState(selectedPatient);
    const [patientSelected, setPatientSelected] = useState(patientSelectedData);
    const [patientAdvSearchData, setPatientAdvSearchData] =
        useState(patientSelectedData);
    const [patientRemoved, setPatientRemoved] = useState(false);

    // eslint-disable-next-line
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    /**
     * show messages
     */
    useEffect(() => {
        if (showNotification && notifyMessage) {
            showNotifyWindow("show", statusTag, notifyMessage, 4000);
            dispatch(resetNotifyWindow())
        }
    }, [showNotification]);

    /**
     * useEffect used to create new patient throught drop down search
     */
    useEffect(() => {
        if (patientPK) {
            if (!infoLoaded) {
                /**once the function called we set a variable to true because we doesn't need to call this when ever page switch
                 * value only change to false patient is removed
                 */
                let data = {
                    id: "",
                    pks: {
                        billing_info_pk: "",
                        diagnosises_pk: "",
                        procedures_pks: [],
                        additional_claim_info_pk: "",
                    },
                    custom_claim_id: "",
                    reference_number: "",
                    posted_date: format(new Date(), "yyyy-MM-dd"),
                    service_from_date: format(new Date(), "yyyy-MM-dd"),
                    service_to_date: format(new Date(), "yyyy-MM-dd"),
                    authorization_referral: "",
                    claim_type: 1,
                    claim_status: "",
                    claim_sub_status: "",
                    identifier: "",
                    locked_by_name: "",
                    claim_inactive: false,
                    claim_editable: true,
                    claim_locked: false,
                    active: true,
                    patient_id: patientPK,
                    patient_name: selectPatient,
                    patient: patientPK,
                    patient_dob: format(new Date(patientSelected[0]?.dob), "yyyy-MM-dd"),
                };
                dispatch(
                    onPatientDropDownSelectAction({
                        commonData: data,
                        patientSelected: patientSelected,
                        patientAdvSearchData: patientAdvSearchData,
                        selectPatient: selectPatient,
                    })
                );
            }
        }
    }, [patientPK, selectPatient]);

    /***
     * remove all information stored in redux on patient remove
     */
    useEffect(() => {
        if (patientRemoved == true) {
            dispatch(removeClaimDetails(claimPK));
            dispatch(resetResponsibilityOnPatientOrClaimRemove())
        }
    }, [patientRemoved]);

    /**
     * call edit or update details on claim pk change or active tab change
     */
    useEffect(() => {
        if (props.claimPK && !open_pks.includes(props.claimPK)) {
                dispatch(getEditDetails(props.claimPK));
          } else {
                dispatch(getClaimDetailsByClaimPK(props.claimPK));
        }
        if (props.activeSubTab) {
            dispatch(setActiveSubTab(props.activeSubTab));
        }
    }, [props.activeTab]);

    /**
     * calling all api for getting details for inital claim creation
     */
    useEffect(() => {
        if (professionalClaimDetails?.patient && !claimPK) {
            if (!open_pks.includes("add_new")) {
                dispatch(
                    getPatientDetails({
                        patient_pk: professionalClaimDetails?.patient,
                        claim_pk: claimPK,
                    })
                );
            }
        }

        if (professionalClaimDetails?.patient && responsibility.length === 0) {
            dispatch(GetResponsibilityType(professionalClaimDetails?.patient));
        }
    }, [professionalClaimDetails?.patient]);

    useEffect(() => {
        if (!isSelectInputDataLoaded) {
            dispatch(getAllSelectInput({ claimPK: claimPK }));
        }

        dispatch(
            GetClaimStatusData({
                pageSize: 0,
                page: 0,
                practicePK: practicePK,
                listType: null,
                patientPK: professionalClaimDetails?.patient,
                claimPK: props.claimPK,
            })
        );

        dispatch(
            GetAccidentType({
                pageSize: 0,
                page: 0,
                claimPK: props.claimPK,
            })
        );

        if (!props.claimPK) {
            dispatch(
                GetClaimSubStatusData({
                    pageSize: 0,
                    page: 0,
                    practicePK: practicePK,
                    claimPK: props.claimPK,
                })
            );
        }
    }, []);

    /**
     * show loading bar on fetch data
     */
    useEffect(() => {
        setShowLoadingBar(dataLoading);
    }, [dataLoading]);

    useEffect(() => {
        if (professionalClaimDetails?.claim_status) {
            dispatch(
                GetClaimSubStatusPkData({
                    pageSize: 0,
                    page: 0,
                    practicePK: practicePK,
                    claimPK: props.claimPK,
                    statusPK: professionalClaimDetails?.claim_status,
                })
            );
        }
    }, [professionalClaimDetails?.claim_status]);

    useEffect(() => {
        if (CallInsurance && !claimPK) {
            dispatch(
                getPayerInsuranceDetails({
                    pageSize: DEFAULT_PAGING_SIZE,
                    pageNum: 0,
                    patientPK: professionalClaimDetails?.patient,
                    priority: "primary",
                    claimPK: claimPK,
                })
            );
            dispatch(
                getPayerInsuranceDetails({
                    pageSize: DEFAULT_PAGING_SIZE,
                    pageNum: 0,
                    patientPK: professionalClaimDetails?.patient,
                    priority: "secondary",
                    claimPK: claimPK,
                })
            );
            dispatch(
                getPayerInsuranceDetails({
                    pageSize: DEFAULT_PAGING_SIZE,
                    pageNum: 0,
                    patientPK: professionalClaimDetails?.patient,
                    priority: "tertiary",
                    claimPK: claimPK,
                })
            );
        } else if (CallInsurance && claimPK) {
            dispatch(
                getPayerInsuranceDetails({
                    pageSize: DEFAULT_PAGING_SIZE,
                    pageNum: 0,
                    patientPK: professionalClaimDetails?.patient,
                    priority: "primary",
                    claimPK: claimPK,
                })
            );
            dispatch(
                getPayerInsuranceDetails({
                    pageSize: DEFAULT_PAGING_SIZE,
                    pageNum: 0,
                    patientPK: professionalClaimDetails?.patient,
                    priority: "secondary",
                    claimPK: claimPK,
                })
            );
            dispatch(
                getPayerInsuranceDetails({
                    pageSize: DEFAULT_PAGING_SIZE,
                    pageNum: 0,
                    patientPK: professionalClaimDetails?.patient,
                    priority: "tertiary",
                    claimPK: claimPK,
                })
            );
        }
    }, [CallInsurance]);

    /**
     * function for sub tab change in proffessional claims
     * @param {*} e
     * @param {*} tab
     */
    const onTabChange = (e, tab) => {
        /**if claim pk exist active sub tab is stored in tab */
        if (professionalClaimDetails?.custom_claim_id && props.claimPK) {
            let item = {
                pk: props.claimPK,
                claimId: professionalClaimDetails?.custom_claim_id,
                type: "claims",
                action: "add",
                active_tab: tab,
            };
            dispatch(addActiveSubTab({ payload: item }));
        } else {
            dispatch(setActiveSubTab(tab))
        }
    };

    /**
     * function to show and active drop down list
     */

    function remShowExport() {
        document.getElementById("export-dropdown").classList.remove("show");
        document.getElementById("export-menu").classList.remove("show");
        document.getElementById("export-dropdown").classList.remove("active");
        document.getElementById("export-menu").classList.remove("active");
    }

    function onShowExport() {
        document.getElementById("export-dropdown").classList.toggle("show");
        document.getElementById("export-menu").classList.toggle("show");
    }

    function exportPaperClaimForm(e, claimPK, withHcfa) {
        if (claimPK) {
            let query = "claim_pks=" + claimPK + "&with_hcfa=" + withHcfa;
            const result = service.GetPaperClaimFormPDF(query);
            result.then((response) => {
                response.headers["Access-Control-Allow-Headers"] =
                    "Origin, X-Requested-With, Content-Type, Accept";
                const fileURL = URL.createObjectURL(response.data);
                const pdfWindow = window.open();
                pdfWindow.location.href =
                    fileURL +
                    "#filename=" +
                    response.headers["content-disposition"];
            });
        }
    }

    function redirectToClaimLogs() {
        if (claimPK) {
            setStorage("claimPK", claimPK);
        }

        // eslint-disable-next-line
        const openClaimLogWindow = window.open(ROUTE_CLAIM_ACTION_LOGS);
        // passing claim info to header and user-logs components to dynamically show the page header as respective claim# and patient name
        openClaimLogWindow.claimInfo = {
            claim_ID: professionalClaimDetails?.id,
            patient_Name: professionalClaimDetails?.patient_name,
            custom_claimId: Number(professionalClaimDetails?.custom_claim_id),
        };
    }

    /**function to redirect to patient ledger */
    function redirectToPatientLedger() {
       const data = { patientPK: patient_PK };
        history.push(ROUTE_PATIENTS_LEDGER, data);
        setStorage("routeState", JSON.stringify(data));
    }

    /**
     * function to redirect to post payment
     */
    function claimPostPayment() {
        history.push("/payment/post_payments");
    }

    /**
     * function invoke while typing or selecting value to inputs
     * @param {} e
     */
    const onHandleChange = (e) => {
        if (e.target) {
            let name = e.target.name;
            let value =
                e.target.type === "checkbox"
                    ? e.target.checked
                    : e.target.value;
            dispatch(
                updateParentComponentData({
                    field: name,
                    value: value,
                    claimPK: claimPK,
                })
            );
            /**
             * calling identifier
             */
            if (name == "authorization_referral") {
                let patient = professionalClaimDetails?.patient;
                let provider =
                    value === 1
                        ? billingInfoDetails?.billing_provider
                        : billingInfoDetails?.referring_provider;
                setTimeout(() => {
                    dispatch(
                        fetchIdentifiers({
                            patient: patient,
                            auth: value,
                            provider: provider,
                        })
                    );
                }, 250);
            }
        }
    };

    function onInactiveClaim(e) {
        if (professionalClaimDetails?.patient) {
            e.preventDefault();
            dispatch(showModalWindow({ key: "inactiveClaimModal",value:true }));
        }
        else {
            showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.patient_not_selected")
            );
        }
    }

    const onSaveFormData = (e, isClose = false) => {
        e.preventDefault();
        dispatch(createAndUpdateClaim({ claimPK: claimPK, isClose: isClose }));
    };

    const ActivateClaim = () => {
        dispatch(ActivateInactiveClaim(claimPK));
    }


    /**
     * customized diagloge hide
     */

    const onSaveHide = () => {
        dispatch(showModalWindow({ key: "saveConfirmation", value: false, claimPK: claimPK }));
        if (savedClaimDetails?.claimPK && savedClaimDetails?.isSave) {
            let item1 = { pk: 'add_new', claimId: 'add_new', type: 'claims', action: 'remove' }
            let item2={pk: savedClaimDetails?.claimPK, claimId: savedClaimDetails?.claimId,
                type: 'claims', action: 'add', active_tab: activeSubTab
            }
            
            let claimTabRequest = [item1, item2];
            for (const item of claimTabRequest) {
                dispatch(addRemoveFromSelectedTab({ item: item, tab:item.pk!=="add_new" ? "editClaims" + item.pk:"editClaim" }));
            }
        }
    }

    /**
     * hide other modals either save on save close
     */
    const onHideIncativeModal = () => {
        dispatch(showModalWindow({ key: "inactiveClaimModal",value:false,claimPK:claimPK }));
    };

    const onHideActiveModalWindow = () => {
        dispatch(showModalWindow({ key: "activeClaimModal",value:false,claimPK:claimPK }));
    };

    const onSaveCloseHide = (hide) => {
        dispatch(showModalWindow({ key: "saveCloseConfirmation", value: false, claimPK: claimPK }));
        let claimTabRequest = [];
        if (!hide) {
            if (savedClaimDetails?.claimPK) {
                let item1 = {
                        pk: 'add_new', claimId: 'add_new', type: 'claims', action: 'remove',
                }
                let item2 = {
                    pk: savedClaimDetails?.claimPK, claimId: savedClaimDetails?.claimId,
                    type: 'claims', action: 'remove',
                    active_tab: activeSubTab
                }
                if (savedClaimDetails?.isSave) {
                    item2 = {
                        ...item2, action: "add", isClosed: true,
                    };
                    claimTabRequest = [item1, item2];
                }
                else {
                    claimTabRequest = [item2];
                }

                for (const item of claimTabRequest) {
                    dispatch(addRemoveFromSelectedTab({ item: item, tab:item.pk!=="add_new" ? "editClaims" + item.pk:"editClaim"  }));
                }
            }
        }
        else{
            onSaveHide()
        }
    }

    return (
        <>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <Form
                id="NewClaim"
                autoComplete="off"
                className="basic-info-padding">
                {claimPK && (
                    <div className="row mb-4 padding-left25">
                        <div className="dropdown" id="export-dropdown">
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="print-claim">
                                        {i18n.t(
                                            "searchClaims.professionalClaims.claimPrint"
                                        )}
                                    </Tooltip>
                                }>
                                <Link
                                    to="#"
                                    o
                                    className={
                                        claimPK
                                            ? "cursor-pointer dropdown-toggle"
                                            : ""
                                    }
                                    data-toggle="dropdown"
                                    onFocusOut={remShowExport}
                                    onBlur={remShowExport}
                                    onClick={claimPK ? onShowExport : ""}>
                                    <i
                                        className="fa fa-print navBarIcons"
                                        id="showExport-btn"
                                        style={{
                                            color: "#005cb9",
                                            fontSize: "13px",
                                        }}></i>
                                </Link>
                            </OverlayTrigger>
                            <div className="">
                                <ul
                                    className="dropdown-menu dropdown-menu-right download-dropdown-right"
                                    style={{
                                        minWidth: "150px !important",
                                        top: "-15px",
                                    }}
                                    id="export-menu">
                                    <li
                                        className="download-dropdown-menu-item"
                                        id="exportWithHCFA-btn">
                                        <Link
                                            to="#"
                                            onMouseDown={(e) =>
                                                exportPaperClaimForm(
                                                    e,
                                                    claimPK,
                                                    "yes"
                                                )
                                            }
                                            className="text-dec-none">
                                            {i18n.t("commons.exportWithHCFA")}
                                        </Link>
                                    </li>
                                    <li
                                        className="download-dropdown-menu-item"
                                        id="exportWithoutHCFA-btn">
                                        <Link
                                            to="#"
                                            onMouseDown={(e) =>
                                                exportPaperClaimForm(
                                                    e,
                                                    claimPK,
                                                    "no"
                                                )
                                            }
                                            className="text-dec-none">
                                            {i18n.t(
                                                "commons.exportWithoutHCFA"
                                            )}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {checkPermission(
                            permission_key_values_claim.audit_logs_module_view
                        ) && (
                            <div className="">
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="link-logs">
                                            {i18n.t(
                                                "searchClaims.professionalClaims.claimHistory"
                                            )}
                                        </Tooltip>
                                    }>
                                    <Link to="#">
                                        <i
                                            className="fa fa-history navBarIcons"
                                            id="redirectToClaimLogs-btn"
                                            style={{
                                                color: "#005cb9",
                                                fontSize: "13px",
                                            }}
                                            onClick={
                                                claimPK
                                                    ? redirectToClaimLogs
                                                    : ""
                                            }
                                            aria-hidden="true"></i>
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        )}
                        {checkPermission(
                            permission_key_values_claim.patient_ledger_sub_module_view
                        ) && (
                            <div className="pl-2">
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="link-logs">
                                            {i18n.t(
                                                "searchClaims.professionalClaims.patientLedger"
                                            )}
                                        </Tooltip>
                                    }>
                                    <Link to="#">
                                        <i
                                            className="fa fa-file navBarIcons"
                                            id="redirectToPatientLedger-btn"
                                            style={{
                                                color: "#005cb9",
                                                fontSize: "13px",
                                            }}
                                            onClick={
                                                claimPK
                                                    ? redirectToPatientLedger
                                                    : ""
                                            }
                                            aria-hidden="true"></i>
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        )}
                        {checkPermission(
                            permission_key_values_claim.post_payments_sub_module_view
                        ) && (
                            <div className="pl-2">
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="link-payments">
                                            {i18n.t(
                                                "searchClaims.professionalClaims.claimPayments"
                                            )}
                                        </Tooltip>
                                    }>
                                    <i
                                        className="fas fa-dollar-sign navBarIcons padding-left10 padding-right10 cursor-pointer"
                                        id="claimPostPayment-btn"
                                        style={{
                                            color: "#005cb9",
                                            fontSize: "13px",
                                        }}
                                        onClick={claimPostPayment}
                                    />
                                </OverlayTrigger>
                            </div>
                        )}
                        <div className="pl-2">
                            <i
                                className="fas fa-ellipsis-h navBarIcons"
                                style={{ color: "#005cb9", fontSize: "13px" }}
                            />
                        </div>
                    </div>
                )}
                {claimPK && (
                    <div className="">
                        {!professionalClaimDetails.claim_editable &&
                            checkPermission(
                                permission_key_values_claim.claims_search_claims_add,
                                permission_key_values_claim.claims_search_claims_modify
                            ) && (
                                <div className="padding-left15 margin-bottom15">
                                    <div className={"warning_lock"}>
                                        <span style={{ fontWeight: 500 }}>
                                            {" "}
                                            {i18n.t("commons.locking")} :{" "}
                                            <span style={{ fontWeight: 500 }}>
                                                {
                                                    professionalClaimDetails.locked_by_name
                                                }
                                            </span>
                                        </span>
                                        {checkPermission(
                                            permission_key_values.administration_page_locks_view
                                        ) && (
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="link-logs">
                                                        {i18n.t(
                                                            "searchClaims.professionalClaims.routeToPatientLock"
                                                        )}
                                                    </Tooltip>
                                                }>
                                                <IconButton
                                                    id="route-page-lock-btn"
                                                    onClick={() => {
                                                        history.push(
                                                            ROUTE_PAGE_LOCKS
                                                        );
                                                    }}>
                                                    <LaunchIcon
                                                        style={{
                                                            fontSize: "small",
                                                        }}
                                                    />
                                                </IconButton>
                                            </OverlayTrigger>
                                        )}
                                    </div>
                                </div>
                            )}
                    </div>
                )}
                {
                    claimPK /*  //Warning Message changes --- start*/ && (
                        <div className="margin-left15">
                            <WarningPopUp
                                notifyDescription={i18n.t(
                                    "warning.incompleteData"
                                )}
                                formWarningData={formWarningData}
                                formWarningStatus={formWarningData}
                            />
                        </div>
                    )
                    /*//Warning Message changes --- end*/
                }
                <div className="row margin-left1">
                    {!claimPK ? (
                        <>
                            <PatientDropDownSearch
                                id="claim-patient-dropdown-search"
                                setSelectPatient={setSelectPatient}
                                setPatientPK={setPatientPK}
                                patientSelected={patientSelected}
                                setPatientSelected={setPatientSelected}
                                inputClass="padding-top25"
                                orPaddingTop="55px"
                                patientAdvSearchData={patientAdvSearchData}
                                setPatientRemoved={
                                    setPatientRemoved
                                }></PatientDropDownSearch>
                            <div className="margin-top50 col-4">
                                <RightSidePanel
                                    title={i18n.t("commons.advancedSearch")}
                                    onclickLabel={i18n.t(
                                        "commons.advancedSearch"
                                    )}>
                                    <PatientsAdvancedSearch
                                        setSelectPatient={setSelectPatient}
                                        setPatientPK={setPatientPK}
                                        setPatientSelected={setPatientSelected}
                                        setPatientAdvSearchData={
                                            setPatientAdvSearchData
                                        }></PatientsAdvancedSearch>
                                </RightSidePanel>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
                <div className="row margin-left1 margin-top20">
                    {claimPK ? (
                        <div className="col-3">
                            <TextInput
                                id="patient-name-txt"
                                name="patient_name"
                                value={professionalClaimDetails.patient_name}
                                disabled={true}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.selectPatient"
                                )}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    {claimPK ? (
                        <div className="col-3 pl-0">
                            <TextInput
                                type="text"
                                id="claim-id-txt"
                                name="claimId"
                                label={i18n.t(
                                    "searchClaims.professionalClaims.claimId"
                                )}
                                disabled="disabled"
                                value={professionalClaimDetails.custom_claim_id}
                                onValueChange={onHandleChange}
                            />
                        </div>
                    ) : (
                        " "
                    )}
                    <div className={!claimPK ? "col-3" : "col-3 pl-0"}>
                        <TextInput
                            type="text"
                            id="reference_number-txt"
                            name="reference_number"
                            label={i18n.t(
                                "searchClaims.professionalClaims.internalReference"
                            )}
                            value={professionalClaimDetails.reference_number}
                            onValueChange={onHandleChange}
                        />
                    </div>

                    <div className="col-3 pl-0">
                        <CalendarInput
                            id="posted-date"
                            name="posted_date"
                            label={i18n.t(
                                "searchClaims.professionalClaims.createdDate"
                            )}
                            disabled={true} //claim created dates are disabled by default and no value change function is provided
                            selected={professionalClaimDetails.posted_date}
                            maxDate={new Date()}
                        />
                    </div>
                </div>

                <div className="row margin-left1">
                    <div className="col-3">
                        <SelectInput
                            id="claim_status"
                            data={claimStatus}
                            required={true}
                            name="claim_status"
                            value={professionalClaimDetails?.claim_status}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.claimStatus"
                            )}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="claim_sub_status"
                            data={claimSubStatus}
                            name="claim_sub_status"
                            value={professionalClaimDetails?.claim_sub_status}
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.claimSubStatus"
                            )}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput
                            id="authorization_referral"
                            data={AUTHORIZATION_REFERRAL}
                            name="authorization_referral"
                            value={
                                professionalClaimDetails?.authorization_referral
                            }
                            onValueChange={onHandleChange}
                            label={i18n.t(
                                "searchClaims.professionalClaims.authorizatonReferral"
                            )}
                        />
                    </div>
                    {professionalClaimDetails?.authorization_referral ? (
                        <div className="col-3 pl-0">
                            <SelectInput
                                id="auth-identifier 6"
                                data={identifierData}
                                required={
                                    professionalClaimDetails.authorization_referral
                                        ? true
                                        : false
                                }
                                name="identifier"
                                value={professionalClaimDetails.identifier}
                                onValueChange={onHandleChange}
                                label={i18n.t(
                                    "searchClaims.professionalClaims.identifier"
                                )}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {props.activeTab === "editClaims" + props.claimPK && (
                    <TabContext value={activeSubTab}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={onTabChange}
                                aria-label="lab API tabs example"
                                style={{
                                    marginLeft: 20,
                                    borderBottom: "1px solid #E0E0E0",
                                    marginTop: 35,
                                }}>
                                <Tab
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.billingInfo"
                                    )}
                                    value={TAB1}
                                    className="tab"
                                />
                                <Tab
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.serviceLines"
                                    )}
                                    value={TAB2}
                                    className="tab"
                                />
                                <Tab
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.additionalClaimInfo"
                                    )}
                                    value={TAB3}
                                    className="tab"
                                />
                                <Tab
                                    label={i18n.t(
                                        "searchClaims.professionalClaims.notes"
                                    )}
                                    value={TAB4}
                                    className="tab"
                                />
                            </TabList>
                        </Box>
                        <TabPanel value={TAB1} className="searchBoxTab">
                            <BillingInfo />
                        </TabPanel>
                        <TabPanel value={TAB2} className="searchBoxTab">
                            <ServiceLine />
                        </TabPanel>
                        <TabPanel value={TAB3} className="searchBoxTab">
                            <AdditionalClaimInfo />
                        </TabPanel>
                        <TabPanel value={TAB4} className="searchBoxTab">
                            <ClaimNotes claimPK={claimPK} />
                        </TabPanel>
                        {professionalClaimDetails?.claim_editable &&
                            activeSubTab === TAB1 && (
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        {checkPermission(
                                            permission_key_values_claim.search_claims_sub_module_modify
                                        ) &&
                                            claimPK && (
                                                <>
                                                    <div className="max-width-3 padding-left15 form-group">
                                                        <div className="custom-checkbox lh-0">
                                                            <input
                                                                type="checkbox"
                                                                id={
                                                                    "claim_inactive" +
                                                                    claimPK
                                                                }
                                                                checked={
                                                                    professionalClaimDetails?.claim_inactive
                                                                }
                                                                name="claim_inactive"
                                                                onChange={
                                                                    onHandleChange
                                                                }
                                                            />
                                                            <label
                                                                className="checkbox top-14"
                                                                htmlFor={
                                                                    "claim_inactive" +
                                                                    claimPK
                                                                }></label>
                                                        </div>
                                                    </div>
                                                    <div className="padding-top5 padding-left20">
                                                        <label className="padding-top5 margin-top-3px">
                                                            {i18n.t(
                                                                "patientPages.patientInfo.inactive"
                                                            )}
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                    {checkPermission(
                                        permission_key_values_claim.claims_search_claims_add,
                                        permission_key_values_claim.claims_search_claims_add
                                    ) && (
                                        <div className="justify-right padding-right20 padding-top5">
                                            <button
                                                type="button"
                                                className="btn btn-primary-blue mr-2 btn-common-height35 width-75"
                                                onClick={
                                                    professionalClaimDetails?.claim_inactive &&
                                                    !professionalClaimDetails?.active
                                                        ? (e) =>
                                                              onInactiveClaim(e)
                                                        : (e) =>
                                                              onSaveFormData(e)
                                                }>
                                                {i18n.t("buttons.save")}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary-blue btn-common-height35 width-75"
                                                onClick={
                                                    professionalClaimDetails?.claim_inactive &&
                                                    !professionalClaimDetails?.active
                                                        ? (e) =>
                                                              onInactiveClaim(e)
                                                        : (e) =>
                                                              onSaveFormData(
                                                                  e,
                                                                  true
                                                              )
                                                }>
                                                {i18n.t("buttons.saveClose")}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        {checkPermission(
                            permission_key_values_claim.search_claims_sub_module_modify,
                            permission_key_values_claim.claims_search_claims_add
                        ) &&
                            professionalClaimDetails.claim_editable &&
                            (activeSubTab === TAB3 || activeSubTab == TAB2) && (
                                <div className="justify-right padding-right15 padding-top5">
                                    <button
                                        type="button"
                                        className="btn btn-primary-blue mr-2 btn-common-height35 width-75"
                                        onClick={
                                            professionalClaimDetails?.claim_inactive &&
                                            !professionalClaimDetails?.active
                                                ? (e) => onInactiveClaim(e)
                                                : (e) => onSaveFormData(e)
                                        }>
                                        {i18n.t("buttons.save")}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary-blue btn-common-height35 width-75"
                                        onClick={
                                            professionalClaimDetails?.claim_inactive &&
                                            !professionalClaimDetails?.active
                                                ? (e) => onInactiveClaim(e)
                                                : (e) => onSaveFormData(e, true)
                                        }>
                                        {i18n.t("buttons.saveClose")}
                                    </button>
                                </div>
                            )}
                    </TabContext>
                )}
            </Form>
            <CustomizedSmallDialogs
                showModal={inactiveClaimModal}
                setShowModalWindow={onHideIncativeModal}
                header={i18n.t("commons.confrimInactiveHeader")}
                type="yes"
                onHide={onHideIncativeModal}
                inactveItem={onSaveFormData}
                resetForm={onHideIncativeModal}
                buttonStyle={"danger"}>
                <h5 className='fw-bold'>
                    {i18n.t("commons.confrimInactive") + " claim?"}
                </h5>
                <div className="padding-left2 mt-4 helper-text-box">
                    <ul>
                        <li>&#9432; {i18n.t("commons.claimInactiveHelperText1")} </li>
                        <li>&#9432; {i18n.t("commons.claimInactiveHelperText2")} </li>
                    </ul>
                </div>
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs
                showModal={activeClaimModal}
                setShowModalWindow={onHideActiveModalWindow}
                header={i18n.t("commons.confrimActiveHeader")}
                type="yes"
                onHide={onHideActiveModalWindow}
                inactveItem={ActivateClaim}
                resetForm={onHideActiveModalWindow}
                buttonStyle={"primary-blue"}>
                {i18n.t("commons.confrimActive") + " Claim?"}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs
                showModal={saveConfirmation}
                setShowModalWindow={() =>
                    dispatch(
                        showModalWindow({
                            key: "saveConfirmation",
                            value: false,
                            claimPK: claimPK,
                        })
                    )
                }
                alertOK={() => onSaveHide()}
                header={i18n.t("commons.warnings")}
                type="alert"
                onHide={() => onSaveHide()}>
                <div className="display-grid">
                    {ReactHtmlParser(formWarningMessage)}
                </div>
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs
                showModal={saveCloseConfirmation}
                setShowModalWindow={() =>
                    dispatch(
                        showModalWindow({
                            key: "saveCloseConfirmation",
                            value: false,
                            claimPK: claimPK,
                        })
                    )
                }
                inactveItem={() => onSaveCloseHide(false)}
                header={i18n.t("commons.warnings")}
                type="yes"
                saveType={true}
                onHide={() => onSaveCloseHide(true)}>
                <div className="display-grid">
                    {ReactHtmlParser(formWarningMessage)}
                </div>
                <p className="bold-font margin-top-16">
                    {i18n.t("commons.confirmClose")}
                </p>
            </CustomizedSmallDialogs>
        </>
    );
}

export default ProfessionalClaim; 