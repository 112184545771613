import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import "./style.css";

import arClaimService from './service';
import ARClaimTab from './ARClaimTab';
import { ARClaimTableData, MYARTableBodyData, MYARClaimTableData } from './ARClaimTableObject';


import i18n from '../../../utilities/i18n';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody, formatDateUS } from '../../../utilities/commonUtilities';
import { CLAIM_TYPES, PAYER_TYPE_LIST, ICD } from '../../../utilities/dictionaryConstants';
import { DEFAULT_PAGING_SIZE, PAGING_END_INDEX, TAB1, TAB2, ROUTE_PATIENTS_LIST, MAX_OPEN_TABS_PATIENTS, ROUTE_INSURANCE_COMPANIES_EDIT } from '../../../utilities/staticConfigs';

import searchClaimService from '../SearchClaims/service';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import CalendarInput from '../../commons/input/CalendarInput';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';

import Pagination from '../../commons/pagination';
import PatientsAdvancedSearch from '../../patientManagement/patients/PatientsAdvancedSearch';
import PatientDropDownSearch from '../../patientManagement/patients/patientDropDownSearch';
import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';

import LoadingContext from '../../../container/loadingContext';
import MaterialMultiSelectSearch from '../../../MaterialMultiselect/MaterialMultiSelectSearch';
import ExportIconButton from '../../commons/exportIconButton';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';

import { useDispatch, useSelector } from "react-redux";
import { getDropDownListData, getARClaimsData, updateSortField, updatePagination, onTab1Reset, resetSortFields } from "../ARManagement/ARClaimsSlice"
import { setCommonState } from '../../../store/CommonStateSlice';
import SelectInput from '../../commons/input/SelectInput';
import { permission_key_values_claim } from '../../../utilities/permissions';
import CommonButton from '../../commons/Buttons';
import { styled } from '@mui/material/styles';
const CustomIndicator = styled('span')(() => ({
    // Define your custom indicator styles here
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'
}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;
/**
 * NOTE: COMPONENT HAS MULTIPLE TABS & TAB-1's STATE IS HANDLED IN REDUX TO PERSIST THE DATA WHEN SWITCHING THE TABS.
 */
function ARClaims(props) {
    const history = useHistory();
    const practicePK = getStorage("practice");
    const ARClaimTableOpened = getStorage('ARClaimTableOpened');
    const dispatch = useDispatch();

    const { dropDownListData,
        isErrorGettingDropdownData,
        isFilterDropDownDataReceived,
        patient_Pk 
    } = useSelector((state) => state.arClaimsData);
    const searchQueryInput = useSelector((state) => state.arClaimsData.tab1LoadClaims.searchQuery);
    const resetFlag = useSelector((state) => state.arClaimsData.resetFlag);
    const arClaimsTableData = useSelector((state) => state.arClaimsData.tab1LoadClaims.arClaimsTableData);
    const isLoading = useSelector((state) => state.arClaimsData.loading);
    const patientSelectedData = useSelector(state => state.arClaimsData.tab1LoadClaims.searchQuery.selectedPatient)

    const [reloadARClaimData, setReloadARClaimData] = useState(false);
    const [selectedClaim, setSelectedClaim] = useState([]);
    const [searchStatus, setSearchStatus] = useState(false);
    const setShowLoadingBar = useContext(LoadingContext);
    const [selectedIDs, setselectedIDs] = useState([]);

    const [myArTableData, setMyArTableData] = useState([]);
    const [activeTab, setActiveTab] = useState("");
    const [patientSelected, setPatientSelected] = useState([]);

    const [patientPK, setPatientPK] = useState("");

    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
    const [clearSelectedPatient, setClearSelectedPatient] = useState(0);

    const [checkedAllClaim, setCheckedAllClaim] = useState(false);
    const [checkedClaim, setCheckedClaim] = useState([]);

    const [header, setHeader] = useState("");
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [assignClaimUsersList, setAssignClaimUsersList] = useState([]);
    const noTableRecords = i18n.t("commons.noRecords");
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [tableUpdateFlag, setTableUpdateFlag] = useState(0);

    const [totalPageMyAr, setTotalPageMyAr] = useState(1);
    const [activePageMyAr, setActivePageMyAr] = useState(1);
    const [startIndexMyAr, setStartIndexMyAr] = useState(0);
    const [endIndexMyAr, setEndIndexMyAr] = useState(PAGING_END_INDEX);

  
    const [initialOrderingClaimIdMyAr,setInitialOrderingClaimIdMyAr]= useState(true) 
    const [initialOrderingPatientNameMyAr, setInitialOrderingPatientNameMyAr] = useState(true);
    const [initialOrderingDosMyAr, setInitialOrderingDosMyAr] = useState(true);
    const [orderingFieldMyAr, setOrderingFieldMyAr] = useState('');
    const [claimOrderType,setClaimOrderType ] = useState('');

    // table column sorting
    MYARClaimTableData.tableHeadings[0].initialOrdering = initialOrderingClaimIdMyAr;
    MYARClaimTableData.tableHeadings[0].orderType = claimOrderType;
    MYARClaimTableData.tableHeadings[1].initialOrdering = initialOrderingPatientNameMyAr;
    MYARClaimTableData.tableHeadings[1].orderType = claimOrderType;
    MYARClaimTableData.tableHeadings[2].initialOrdering = initialOrderingDosMyAr;
    MYARClaimTableData.tableHeadings[2].orderType = claimOrderType;
    
    useEffect(() => {
        let newWidth = "";
        newWidth = window.innerWidth;
        let menuWidth = document.getElementById("push-bar").classList.contains('open');
        if (!menuWidth) {
            newWidth = newWidth + 250;
        } else {
            newWidth = window.innerWidth;
        }
        setInnerWidth(newWidth);
    }, [window.innerWidth]);

    /******* NOTIFY CONFIG *********/
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    /*******************************/


    const tableSorting = (e, fieldName) => {
        let name;
        fieldName = fieldName.replace(" ", "_").toLowerCase();
        if (fieldName === "claim_id") {
            name = "claim__custom_claim_id";
            setInitialOrderingClaimIdMyAr(false);
            setInitialOrderingPatientNameMyAr(true);
            setInitialOrderingDosMyAr(true);
        } else if (fieldName === "patient_name") {
            name = "claim__patient__full_name";
            setInitialOrderingPatientNameMyAr(false);
            setInitialOrderingClaimIdMyAr(true);
            setInitialOrderingDosMyAr(true);
        } else if (fieldName === "dos") {
            name = "claim__service_from_date";
            setInitialOrderingDosMyAr(false);
            setInitialOrderingClaimIdMyAr(true);
            setInitialOrderingPatientNameMyAr(true);
        }

        const newOrderType = claimOrderType === "-" ? "" : "-";
        setOrderingFieldMyAr(name);
        setClaimOrderType(newOrderType);
    };

    useEffect(() => {
        getMyArTableData();
    }, [activePageMyAr, orderingFieldMyAr, claimOrderType]); 

    function onPagePreviousMyAr() {
        let previousPage = startIndexMyAr + 1 - PAGING_END_INDEX;
        setActivePageMyAr(previousPage);
        if (startIndexMyAr !== 0) {
            setStartIndexMyAr(startIndexMyAr - PAGING_END_INDEX);
            setEndIndexMyAr(endIndexMyAr - PAGING_END_INDEX);
        }
    }
    function onPageUpMyAr(e) {
        let page = Number(e.target.id)
        setActivePageMyAr(page);
    }
    function onPageNextMyAr() {
        let nextPage = startIndexMyAr + 1 + PAGING_END_INDEX;
        if (endIndexMyAr === totalPageMyAr || totalPageMyAr <= PAGING_END_INDEX) {
            setActivePageMyAr(nextPage);
            setStartIndexMyAr(startIndexMyAr);
            setStartIndexMyAr(endIndexMyAr);
        } else {
            setActivePageMyAr(nextPage);
            setStartIndexMyAr(startIndexMyAr + PAGING_END_INDEX);
            setEndIndexMyAr(endIndexMyAr + PAGING_END_INDEX);
        }
    }

    /*** TAB-1 SORTING IMPLEMENTATION STARTS HERE */
    /************************************ */
    const sortField = useSelector((state) => state.arClaimsData.tab1LoadClaims.sortField); // Current SortField
    const tab1OrderType = useSelector((state) => state.arClaimsData.tab1LoadClaims.orderType); // Current Sort order - Asc or Dsc
    const allSortFieldStates = useSelector((state) => state.arClaimsData.tab1LoadClaims.allSortFieldStates); // All the sort required fields in Redux

    // Manipulating the Table Header object for desired sort order
    ARClaimTableData.tableHeadings[1].initialOrdering = allSortFieldStates.custom_claim_id;
    ARClaimTableData.tableHeadings[1].orderType = tab1OrderType;
    ARClaimTableData.tableHeadings[2].initialOrdering = allSortFieldStates.patient__full_name;
    ARClaimTableData.tableHeadings[2].orderType = tab1OrderType;
    ARClaimTableData.tableHeadings[3].initialOrdering = allSortFieldStates.service_from_date;
    ARClaimTableData.tableHeadings[3].orderType = tab1OrderType;

    // Helper Object to map and find out the correct key for the sortfield as from the table
    // When user clicks on the sort icon its passing the header as argument like "Claim Id" instead of custom_claim_id
    // Below object is used to identify the same
    const fieldToSortFieldMap = {
        "Claim ID": "custom_claim_id",
        "Patient Name": "patient__full_name",
        "DOS": "service_from_date",
    };

    function sortingFunction(e, name) {
        const sortField = fieldToSortFieldMap[name]; // This will return the actual key of the sortField
        dispatch(updateSortField({ sortField, sortOrder: !tab1OrderType ? "-" : "" })); // dispatch the sortField and sort order (asc or dsc) to Redux
        onSearchARClaims(sortField, !tab1OrderType ? "-" : "", currentPage); // Then call the search function to build the query and dispatch api call to redux
        handleCheckedClaimOnPaginationChange();
    }
    /************************************ */

    /*** TAB-1 PAGINATION FUNCTIONALITY STARTS HERE */
    /**************************************** */
    const totalPage = useSelector((state) => state.arClaimsData.tab1LoadClaims.totalPage);
    const currentPage = useSelector((state) => state.arClaimsData.tab1LoadClaims.currentPage);
    const startIndex = useSelector((state) => state.arClaimsData.tab1LoadClaims.startIndex);
    const endIndex = useSelector((state) => state.arClaimsData.tab1LoadClaims.endIndex);

    function onPagePrevious() {
        const previousPage = startIndex - PAGING_END_INDEX + 1;
        onSearchARClaims(sortField, tab1OrderType ? "-" : "", previousPage);
        if (startIndex !== 0) {
            dispatch(updatePagination({
                currentPage: previousPage,
                startIndex: startIndex - PAGING_END_INDEX,
                endIndex: endIndex - PAGING_END_INDEX
            }))
        } else {
            dispatch(updatePagination({
                currentPage: previousPage,
                startIndex: startIndex,
                endIndex: endIndex
            }))
        }
        handleCheckedClaimOnPaginationChange();
    }

    function onPageNext() {
        const nextPage = startIndex + PAGING_END_INDEX + 1;
        const canIncrementIndexes = endIndex === totalPage || totalPage <= PAGING_END_INDEX;
        onSearchARClaims(sortField, tab1OrderType ? "-" : "", nextPage);
        dispatch(updatePagination({
            currentPage: nextPage,
            startIndex: (canIncrementIndexes ? startIndex : startIndex + PAGING_END_INDEX),
            endIndex: (canIncrementIndexes ? startIndex : endIndex + PAGING_END_INDEX)
        }))
        handleCheckedClaimOnPaginationChange();
    }

    function onPageUp(e) {
        const page = Number(e.target.id);
        onSearchARClaims(sortField, tab1OrderType ? "-" : "", page);
        dispatch(updatePagination({
            currentPage: page,
            startIndex: startIndex,
            endIndex: endIndex
        }))
        handleCheckedClaimOnPaginationChange();
    }

    const handleCheckedClaimOnPaginationChange = () => {
        // Whenever pagination changes reset the selected patients checkbox state
        if (checkedClaim.length > 0) {
            setCheckedAllClaim(false);
        }
    };
    /*** TAB-1 PAGINATION FUNCTIONALITY ENDS HERE */
    /**************************************** */

    // TAB-1 Whenever arClaim table data gets updated in the redux resulted by api call, sync the latest data to the table
    useEffect(async () => {
        const rowArray = commonTableBody(arClaimsTableData, ARClaimTableData.tableBodyData[0])
        const newArray = await makeChecked(rowArray)
        ARClaimTableData.tableBodyData = newArray;
        setStorage('ARClaimTableOpened', true);
        setTableUpdateFlag(tableUpdateFlag + 1);
    }, [arClaimsTableData]);

    useEffect(() => {
        if (patientSelected.length > 0) {
            dispatch({ 
                type: "arClaims/patientAdvanceSearchData", 
                payload : { 
                    patientSelected: patientSelected,
                    patientAdvSearchData: patientAdvSearchData,
                    patientPk: patientPK
            }})
        }
    },[patientSelected])

    //when ever search clicked all data want to be cleared
    useEffect(() => {
        if (searchStatus) {
            setCheckedClaim([]);
            setselectedIDs([]);
            setSearchStatus(false);
            setCheckedAllClaim(false);
        }
    }, [searchStatus])

    const makeChecked = (arr) => {
        if (selectedIDs.length > 0) {
            selectedIDs.forEach(el => {
                arr.forEach((ele, index) => {
                    if (el === ele[0].id) {
                        arr[index][0].value = true
                    }
                })
            })
            return arr
        } else {
            return arr;
        }
    }

    useEffect(() => {
        if (isLoading) setShowLoadingBar(true);
        else setShowLoadingBar(false);
    }, [isLoading])

    // TAB-1 Reset functionality to destroy the state data on Reset btn click
    function onResetFilter() {
        dispatch(onTab1Reset());
        setClearSelectedPatient(clearSelectedPatient + 1);
        setPatientSelected([]);
        setPatientPK("");
        setselectedIDs([]);
        setCheckedClaim([]);
    }

    useEffect(() => {
        if (selectedClaim && selectedClaim.length > 0) {
            let openedTab = selectedClaim.find((element) => {
                return element.is_opend;
            })
            if (openedTab.pk === TAB1) {
                setActiveTab(TAB1)
            } else if (openedTab.pk === TAB2) {
                setActiveTab(TAB2)
            } else {
                setActiveTab("editClaim" + openedTab.pk);
            }
        } else if (!selectedClaim) {
            setActiveTab(TAB1)
        }
    }, [selectedClaim]);

    /** DISPATCH SEARCH QUERY / FILTER INPUTS TO REDUX ON VALUE CHANGE  */
    /********************************************************************/

    // Dispatch selected patient data to redux whenever a new patient has been selected from patient-drop-down component
    useEffect(() => {
        dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "selectedPatient", value: patientSelected } })
    }, [patientSelected])

    function onHandleDateOfServiceFrom(selected) {
        try {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "dosFrom", value: selected } })
        } catch {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "dosFrom", value: "" } })
        }
    }

    function onHandleDateOfServiceTo(selected) {
        try {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "dosTo", value: selected } })
        } catch {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "dosTo", value: "" } })
        }
    }

    function onHandleDateOfClaimEntered(selected) {
        try {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimEnteredFrom", value: selected } })

        } catch {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimEnteredFrom", value: "" } })
        }
    }

    function onHandleDateOfClaimEnteredTo(selected) {
        try {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimEnteredTo", value: selected } })

        } catch {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimEnteredTo", value: "" } })
        }
    }

    function onHandleDateOfclaimSubmitted(selected) {
        try {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimSubmittedFrom", value: selected } })
        } catch {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimSubmittedFrom", value: "" } })
        }
    }

    function onHandleDateOfclaimSubmittedTo(selected) {
        try {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimSubmittedTo", value: selected } })

        } catch {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimSubmittedTo", value: "" } })
        }
    }

    function handleMaterialMultiselectSearch(selected) {
        if (selected.length > 0){
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "selectedCpt", value: selected } })
        } else {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "selectedCpt", value: '' } })
        }
    }

    function MaterialMultiSelectHandle(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'serviceLocation') {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "serviceLocation", value: value } })
        }
        else if (name === 'provider') {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "provider", value: value } })
        }
        else if (name === "claimStatus") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimStatus", value: value } })
        }
        else if (name === "claimType") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "claimType", value: value } })
        }
        else if (name === "revenueCodes") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "revenueCodes", value: value } })
        }
        else if (name === "icd") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "icd", value: value } })
        }
        else if (name === "payerType") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "payerType", value: value } })
        }
        else if (name === "secondaryPayer") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "secondaryPayer", value: value } })
        }
        else if (name === "primaryPayer") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "primaryPayer", value: value } })
        }
        else if (name === "currentPayer") {
            dispatch({ type: "arClaims/setSearchQueryInputs", payload: { name: "currentPayer", value: value } })
        }
    }
    /*******************************************************************/

    // Display error notification if any error occurred while fetching filter dropdown list data
    useEffect(() => {
        if (isErrorGettingDropdownData === true)
            showNotifyWindow('show', 'error', i18n.t('errorMessages.error_getting_dropdown_data'));
    }, [isErrorGettingDropdownData]);

    useEffect(() => {
        if (resetFlag) {
            const query = buildARClaimsQuery();
            dispatch(getARClaimsData(query)); 
        }
    },[resetFlag])


    const OnExportMenuItemClicked = (e, type) => {
        // eslint-disable-next-line no-undef
        const fileDownload = require('js-file-download');
        setShowLoadingBar(true);
        const query = buildARClaimsQuery();
        searchClaimService.ExportARPatientClaims(query, type)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else {
                    showNotifyWindow('show', 'error', `${result.statusText}`);
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };

    // QUERY BUILDER UTILITY FUNCTION FOR TAB-1 AR CLAIMS SEARCH PARAMS
    function buildARClaimsQuery(sortField, sortOrder, page = 1) {
        let query = '?list=true&practice_pk=' + practicePK + '&patient_pk=' + patientPK + '&dos_from=' + formatDateUS(searchQueryInput.dosFrom) +
            '&dos_to=' + formatDateUS(searchQueryInput.dosTo) + '&claim_entered_from=' + formatDateUS(searchQueryInput.claimEnteredFrom) +
            '&claim_entered_to=' + formatDateUS(searchQueryInput.claimEnteredTo) + '&claim_submitted_from=' + formatDateUS(searchQueryInput.claimSubmittedFrom) + '&claim_submitted_to=' + formatDateUS(searchQueryInput.claimSubmittedTo) + '&billing_provider_pk=' + searchQueryInput.provider + '&claim_type=' + searchQueryInput.claimType +
            '&claim_status_pk=' + searchQueryInput.claimStatus + '&primary_payer_pk=' + searchQueryInput.primaryPayer + '&secondary_payer_pk=' + searchQueryInput.secondaryPayer +
            '&payer_type=' + searchQueryInput.payerType + '&cpt_hcps=' + searchQueryInput.selectedCpt + '&icd=' + searchQueryInput.icd +'&current_payer_pk=' +searchQueryInput.currentPayer;
        if (searchQueryInput.claimType === 2) {
            query += '&revenue_codes=' + searchQueryInput.revenueCodes
        }
        if (searchQueryInput.serviceLocation) {
            query += '&service_location=' + searchQueryInput.selectedServiceLocationsIds;
            query += '&service_location_type=' + searchQueryInput.serviceLocationType;
        }
        if (sortField && typeof (sortField) === "string") {
            query += '&column=' + (sortField ? sortOrder + sortField : "");
        }
        query += '&page_size=' + DEFAULT_PAGING_SIZE;
        query += '&page=' + (page ? page : 1);

        return query;
    }

    function onSearchARClaims(sortField, sortOrder, page) {
        if (!sortField) {
            dispatch(resetSortFields())
        }
        const query = buildARClaimsQuery(sortField, sortOrder, page);
        dispatch(getARClaimsData(query));
    }

    useEffect(() => {
        getMyArTableData();
        getSelectedClaims()
        if (!isFilterDropDownDataReceived) {
            // Dispatch Redux function to get the drop-down list data if it not there already in the state
            dispatch(getDropDownListData());
        }

        dispatch({ type: "arClaims/getDropDownListData", payload: {} })
        
        if(arClaimsTableData?.length == 0) {
            const query = buildARClaimsQuery();
            dispatch(getARClaimsData(query));
        }
    }, []);

    useEffect(() => {
        getAssignClaimList();
    }, [practicePK, props.claimPK]);
   

    const getMyArTableData = () => {
       
        setShowLoadingBar(true);
        const result = arClaimService.ListMyArClaim(DEFAULT_PAGING_SIZE, activePageMyAr, orderingFieldMyAr, claimOrderType);
        result.then(async (response) => {
            if (response.data) {
                        setTotalPageMyAr(Math.ceil(response.data.count / response.data.page_size));
                let rowArray = await getRowArray(response.data)
                if (rowArray.length > 0) {
                    MYARClaimTableData.tableBodyData = rowArray;
                }
                else {
                    MYARClaimTableData.tableBodyData = MYARTableBodyData;
                }
                setMyArTableData(response.data.results);
                setShowLoadingBar(false);
            }
            else {
                setShowLoadingBar(false);
            }
            if (patientSelectedData.length > 0) {
                setPatientAdvSearchData(patientSelectedData);
                setPatientSelected(patientSelectedData);
                setPatientPK(patient_Pk);
            }
            else {
                setPatientAdvSearchData([]);
                setPatientSelected([]);
                setPatientPK('');
            }
        })
    };

    //generating table row and column data 
    function getRowArray(data) {
        let anArray = [];
        let rowArray = [];
        let newData = Array(data.results.length).fill(JSON.parse(JSON.stringify(MYARClaimTableData.tableBodyData[0])));
        newData.map((row, rowIndex) => {
            row.map((col) => {
                let colObject = {};
                // item.claim_data.custom_claim_id patient_name dos_from charges balance responsible responsible_type claim_status_name last_fu_dt next_fu_dt age 
                if (col.type === 'responsiblePayer') {
                    if (data.results[rowIndex]['claim_data'].payer_types.type == 'patient') {
                        colObject = {
                            ...col, directLink: false, directLinkPath: '', directLinkState: {},
                            'value': {name:data.results[rowIndex]['claim_data'][col.name], payer_id:"", policy_id:""},
                            "id": data.results[rowIndex].id,
                        }
                    }
                    else if (data.results[rowIndex]['claim_data'].payer_types.type == 'insurance') {
                        colObject = {
                            ...col, directLink: true, directLinkPath: ROUTE_INSURANCE_COMPANIES_EDIT,
                            directLinkState: { selectedID: data.results[rowIndex]['claim_data'].payer_types.pk },
                            'value': data.results[rowIndex]['claim_data'][col.name],
                            "id": data.results[rowIndex].id,
                        }
                    }
                    anArray.push(colObject);
                }
                else {
                    colObject = {
                        ...col, 'value': data.results[rowIndex]['claim_data'][col.name],
                        "id": data.results[rowIndex].id,
                    }
                    anArray.push(colObject)
                }

            })
            rowArray.push(anArray)
            anArray = [];
        });
        return rowArray;
    }

    function onTabChange(e, tab, claimPK, claimName) {
        if (tab == TAB1 || tab == TAB2) {
            if (tab == "tab1") {
                setPatientAdvSearchData(searchQueryInput.selectedPatient);
            } else {
                // Set a flag in Redux to persist the values in the material multi-select search.
                // This flag is used to validate and prevent the input change function from being invoked by the material multi-select search on Tab change.
                dispatch(setCommonState({ name: "tabSwitchFlag", value: true }));
            }
            openClaimTab(tab, tab);
        } else {
            openClaimTab(claimPK, claimName);
            dispatch(setCommonState({ name: "tabSwitchFlag", value: true }));
        }
    }

    function closeClaimTab(e, id, name) {
        let tmpData = {
            "pk": id, "name": name, type: 'ar_claims',
            action: 'remove', practice_pk: getStorage("practice")
        }
        addRemoveFromSelectedTab(tmpData)
    }
    function openClaimTab(claimId, claimerName) {
        let tmpData = {
            "pk": claimId, "name": claimerName, type: 'ar_claims',
            action: 'add', practice_pk: getStorage("practice")
        }
        addRemoveFromSelectedTab(tmpData)
    }

    function addRemoveFromSelectedTab(item) {
        const result = arClaimService.AddRemoveSelectedTab(item);
        result.then(() => {
            getSelectedClaims();
        });
    }

    function getSelectedClaims() {
        const result = arClaimService.GetSelectedTabs('ar_claims', getStorage("practice"));
        result.then(response => {
            if (response?.data?.data) {
                setSelectedClaim(response.data.data);
            } else {
                setSelectedClaim([{
                    "pk": TAB1,
                    "is_opend": true
                }])
            }
        });
    }


    function onPatientNameClick(patientID) {
        const result = searchClaimService.GetSelectedTabs('patients', getStorage("practice"));
        result.then(response => {
            let openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks.filter(item => parseInt(item, 10) > 0) : [];
            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_PATIENTS && !openedPKs.includes(patientID)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_patient_tabs'));
            }
            else {
                let item = { selectedID: patientID }
                history.push(ROUTE_PATIENTS_LIST, item)
            }
        });
    }

    function checkIsAClaimTabAlreadyOpen(id) {
        if (selectedClaim?.some(item => item.pk !== "tab2" && item.pk !== "tab1" && item.pk != id)) {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.max_claim_tabs"));
            return true;
        }
        return false;
    }

    function onLinkClick(id, name) {
        if (name === 'custom_claim_id') {
            // only one claim tab is allowed to open, hence validating if there's any tab is already opened.
            if (checkIsAClaimTabAlreadyOpen(id)) {
                return;
            }
            let claim = arClaimsTableData.find((obj) => obj.id == id);
            if (!claim) {
                claim = myArTableData.find((obj) => obj.id === id);
            }

            openClaimTab(claim?.claim_data?.id ?? id, claim?.custom_claim_id ?? claim?.claim_data?.id);
        }
        else if (name === 'patient_name') {
            if (checkPermission(permission_key_values_claim.patient_list_sub_module_view)) {
                let patient = arClaimsTableData.find((obj) => obj.id == id);
                onPatientNameClick(patient.patient_pk);
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }

    function onMYARLinkClick(id, name) {
        let item = myArTableData.find((obj) => obj.id == id);
        if (name === 'custom_claim_id') {
            onLinkClick(id, name)
            // only one claim tab is allowed to open, hence validating if there's any tab is already opened.
            return;
        }
        else if (name === 'patient_name' || name === 'responsible') {
            if (checkPermission(permission_key_values_claim.patient_list_sub_module_view)) {
                onPatientNameClick(item.claim_data.patient_pk);
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }

    /**
     * TAB -1 TABLE CHECKBOX FUNCTIONALITY
     */
    function onClickGridCheckBox(e, clickedFrom, id) {
        if (clickedFrom == 'data') {
            handleCheckedOnChange(e, id);
            getSelectedIDS(id);
        }
        else if (clickedFrom == 'header') {
            handleAllCheckedOnChange(e);
        }
    }


    async function getSelectedIDS(id) {
        if (selectedIDs.length > 0) {
            const check = await checkAlreadyExis(id);
            if (check < 0) {
                selectedIDs.push(id)
            }
            else {
                selectedIDs.splice(check, 1)
            }
        } else {
            selectedIDs.push(id)
        }
    }

    async function checkAlreadyExis(id) {
        const index = selectedIDs.indexOf(id)
        return index
    }
    // Handle check all click from the thead
    const handleAllCheckedOnChange = (e) => {
        let currentSelectedIds = checkedClaim;
        let index;
        if (e.target.checked) {
            ARClaimTableData.tableBodyData.forEach(rowItem => {
                index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = true;
            });
            setCheckedAllClaim(true);
            arClaimsTableData.forEach((item) => {
                currentSelectedIds.push(item.id);
                selectedIDs.push(item.id)
            })
            setCheckedClaim(currentSelectedIds);
        } else {
            ARClaimTableData.tableBodyData.forEach(rowItem => {
                index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = false;
            });
            setCheckedClaim([]);
            setCheckedAllClaim(false);
        }
    }
    // Handle click on individual table item checkbox click
    const handleCheckedOnChange = (e, id) => {
        ARClaimTableData.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let index = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[index].value = e.target.checked;
            }
        });
        let currentSelectedIds = [];
        let pageAllItemSelected = true;
        checkedClaim.forEach((item) => {
            currentSelectedIds.push(item);
        })
        setCheckedClaim([]);
        let index = currentSelectedIds.indexOf(id);
        if (e.target.checked) {
            if (index === -1) {
                currentSelectedIds.push(id);
            }
        }
        else {
            if (index !== -1) {
                currentSelectedIds.splice(index, 1);
            }
        }
        setCheckedClaim(currentSelectedIds);
        arClaimsTableData.forEach((item) => {

            let checkedItem = currentSelectedIds.indexOf(item.id);
            if (checkedItem === -1) {
                pageAllItemSelected = false;
            }
        })
        if (pageAllItemSelected) {
            setCheckedAllClaim(true);
        } else {
            setCheckedAllClaim(false);
        }
    }
    /*************************************************/

    function assignClaim() {
        setHeader(i18n.t("arClaims.assignClaimTab.header"));
        setShowAssignModal(true);

    }

    function getAssignClaimList() {
        const result = arClaimService.ListARClaimPraciceUsers(practicePK);
        result.then((response) => {
            setAssignClaimUsersList(response.data);
        })
    }

    function resetForm() {
        setHeader("");
    }


    useEffect(() => {
        if (reloadARClaimData) {
            const query = buildARClaimsQuery(sortField, tab1OrderType ? "-" : "", currentPage);
            dispatch(getARClaimsData(query));
            getMyArTableData();
            setReloadARClaimData(false)
        }
    },[reloadARClaimData])

    function assign_A_user(data) {
        setShowLoadingBar(true);
        const result = arClaimService.AssignUserAgainstPractice(data);
        result.then((response) => {
            setShowLoadingBar(false);
            if (response.status === 200) {
                showNotifyWindow('show', 'success', i18n.t("arClaims.claimAssignSuccess"));
                resetForm();
                setShowAssignModal(false);
                setCheckedClaim([]);
                setCheckedAllClaim(false);
                setselectedIDs([]);
                onSearchARClaims("", "", currentPage);
                dispatch(updatePagination({
                    currentPage: currentPage,
                    startIndex: startIndex,
                    endIndex: endIndex
                }))
                handleCheckedClaimOnPaginationChange();
                // props.onSearchARClaims()
            }
           
            else {
                showNotifyWindow('show', 'error', i18n.t("arClaims.claimAssignError"));
            }
        })
    }
    return (
        <div className="col-md-12">
            <div className="pb-5 basic-info-padding">
                <div className="box-head padding-left5">
                    <Form autoComplete="off">
                        <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                        <TabContext value={activeTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <StyledTabList onChange={onTabChange} aria-label="lab API tabs example"
                                    TabIndicatorProps={{
                                        children: <CustomIndicator />, // Assigning the custom indicator here
                                    }}>
                                    {checkPermission(permission_key_values_claim.load_claims_tab_view) &&
                                        <Tab label={i18n.t("arClaims.loadClaims")} value={TAB1} className='tabMainMenu' />
                                    }
                                    {checkPermission(permission_key_values_claim.load_claims_tab_view) &&
                                        <Tab label={i18n.t("arClaims.myAr")} value={TAB2} className='tabMainMenu' />
                                    }
                                    {selectedClaim && selectedClaim.map((item, index) => {
                                        if (item.pk !== TAB1 && item.pk !== TAB2) {
                                            return (
                                                <Tab key={index} className="tabMainMenu" value={"editClaim" + item.pk}

                                                    label={
                                                        <div className="justify-normal">
                                                            <strong onClick={(e) => onTabChange(e, "editClaim" + item.pk, item.pk, item.name)}>
                                                                {item.name}
                                                            </strong>
                                                            <span className="margin-left15"></span>
                                                            <span className="closeIconCommonTab" onClick={(e) => closeClaimTab(e, item.pk, item.name)}>
                                                                <i className="fa fa-times" />
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                            )
                                        }

                                    })}
                                </StyledTabList>
                            </Box>
                            {checkPermission(permission_key_values_claim.load_claims_tab_view) &&
                                <TabPanel value={TAB1} className="bordering">
                                    <div className="">
                                        <div className="searchBox margin-top20">
                                            <Form autoComplete="off">
                                                <div className="row">
                                                    <PatientDropDownSearch setPatientPK={setPatientPK} patientSelected={patientSelected} setPatientSelected={setPatientSelected} patientAdvSearchData={patientAdvSearchData} clearSelectedPatient={clearSelectedPatient} ></PatientDropDownSearch>
                                                    <div className="col-2 pl-0">
                                                        <div className="margin-top25">
                                                            <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                                                <PatientsAdvancedSearch setPatientPK={setPatientPK} setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData}>
                                                                </PatientsAdvancedSearch>
                                                            </RightSidePanel>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row margin-top20">
                                                    <div className="col-2">
                                                        <CalendarInput name="dateOfServiceFrom"
                                                            selected={searchQueryInput.dosFrom} onValueChange={onHandleDateOfServiceFrom}
                                                            label={i18n.t("arClaims.dateOfServiceFrom")} />
                                                    </div>
                                                    <div className="col-2 pl-0">
                                                        <CalendarInput name="dateOfServiceTo"
                                                            selected={searchQueryInput.dosTo} minDate={searchQueryInput.dosFrom} onValueChange={onHandleDateOfServiceTo}
                                                            label={i18n.t("arClaims.dateOfServiceTo")} />
                                                    </div>
                                                    <div className="col-2 pl-0">
                                                        <CalendarInput name="claimEntered"
                                                            selected={searchQueryInput.claimEnteredFrom} onValueChange={onHandleDateOfClaimEntered}
                                                            label={i18n.t("arClaims.claimEntered")} />
                                                    </div>
                                                    <div className="col-2 pl-0">
                                                        <CalendarInput name="claimEnteredTo"
                                                            selected={searchQueryInput.claimEnteredTo} minDate={searchQueryInput.claimEnteredFrom} onValueChange={onHandleDateOfClaimEnteredTo}
                                                            label={i18n.t("arClaims.claimEnteredTo")} />
                                                    </div>
                                                    <div className="col-2 pl-0">
                                                        <CalendarInput name="claimSubmitted"
                                                            selected={searchQueryInput.claimSubmittedFrom} onValueChange={onHandleDateOfclaimSubmitted}
                                                            label={i18n.t("arClaims.claimSubmitted")} />
                                                    </div>
                                                    <div className="col-2 pl-0">
                                                        <CalendarInput name="claimSubmittedTo"
                                                            selected={searchQueryInput.claimSubmittedTo} minDate={searchQueryInput.claimSubmittedFrom} onValueChange={onHandleDateOfclaimSubmittedTo}
                                                            label={i18n.t("arClaims.claimSubmittedTo")} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4 ">
                                                        <MaterialMultiselect name="claimStatus" value={searchQueryInput.claimStatus} onValueChange={MaterialMultiSelectHandle}
                                                            options={dropDownListData.ClaimStatusList} label={i18n.t("arClaims.claimStatus")}
                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <SelectInput name="claimType"
                                                            data={CLAIM_TYPES}
                                                            value={searchQueryInput.claimType}
                                                            onValueChange={MaterialMultiSelectHandle}
                                                            label={i18n.t("arClaims.claimType")} />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <MaterialMultiselect name="provider" value={searchQueryInput.provider} onValueChange={MaterialMultiSelectHandle}
                                                            options={dropDownListData.ProviderList} label={i18n.t("arClaims.provider")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-4">
                                                        <MaterialMultiselect name="serviceLocation" value={searchQueryInput.serviceLocation} onValueChange={MaterialMultiSelectHandle}
                                                            options={dropDownListData.ServiceLocationList
                                                            } label={i18n.t("arClaims.serviceLocations")}
                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <MaterialMultiselect name="currentPayer" value={searchQueryInput.currentPayer} onValueChange={MaterialMultiSelectHandle}
                                                            options={dropDownListData.PayersList
                                                            } label={i18n.t("arClaims.currentPayer")}
                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <MaterialMultiselect name="primaryPayer" value={searchQueryInput.primaryPayer} onValueChange={MaterialMultiSelectHandle}
                                                            options={dropDownListData.PayersList} label={i18n.t("arClaims.primaryPayer")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-4">
                                                        <MaterialMultiselect name="secondaryPayer" value={searchQueryInput.secondaryPayer} onValueChange={MaterialMultiSelectHandle}
                                                            options={dropDownListData.PayersList} label={i18n.t("arClaims.secondaryPayer")}
                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <MaterialMultiselect name="payerType" value={searchQueryInput.payerType} onValueChange={MaterialMultiSelectHandle}
                                                            options={PAYER_TYPE_LIST} label={i18n.t("arClaims.payerType")}
                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <MaterialMultiSelectSearch
                                                            isDataPersistInTabSwitch={true}
                                                            options={dropDownListData.CptCodeList}
                                                            label={i18n.t("arClaims.cpt")}
                                                            name={"cpt"}
                                                            valuesArr={searchQueryInput.selectedCpt}
                                                            onValueChange={handleMaterialMultiselectSearch}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mt3'>
                                                    <div className="col-4">
                                                        <MaterialMultiselect name="icd" value={searchQueryInput.icd} onValueChange={MaterialMultiSelectHandle}
                                                            options={ICD} label={i18n.t("arClaims.icd")}
                                                        />
                                                    </div>
                                                    {searchQueryInput.claimType == 2 ? <div className="col-4 pl-0">
                                                        <MaterialMultiselect name="revenueCodes" value={searchQueryInput.revenueCodes} onValueChange={MaterialMultiSelectHandle}
                                                            options={dropDownListData.RevenueCodeList
                                                            } label={i18n.t("arClaims.revenueCodes")}
                                                        />
                                                    </div> : ''}
                                                    <div className="col justify-right">
                                                        <div className="padding-top25 margin-right10">
                                                            <CommonButton onClick={() => onResetFilter()} label={i18n.t("claimsFilter.reset")} variant="outlined" />
                                                        </div>
                                                        <div className="padding-top25">
                                                            <CommonButton onClick={() => { onSearchARClaims(), setSearchStatus(true) }} label={i18n.t("arClaims.search")} variant="contained" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        {ARClaimTableOpened &&
                                            <div>
                                                {checkPermission(permission_key_values_claim.assignlist_ar_tab_action) && checkedClaim.length > 0 &&
                                                    <div className="padding-bottom10 padding-left20 dropdown margin-left-15 float-left margin-top20">
                                                        <CommonButton label={i18n.t('arClaims.assignClaim')} onClick={() => assignClaim()} variant="contained" />
                                                    </div>}
                                                <div className="padding-bottom10 margin-top20 dropdown   float-right" id="export-dropdown">
                                                    <div className="">
                                                        {checkPermission(permission_key_values_claim.export_ar_tab_view) &&
                                                            <ExportIconButton
                                                                onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                                                                onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div>&nbsp;</div>
                                    <>
                                        <div style={{ overflowX: "auto", width: "100%", maxWidth: (innerWidth - 300) + "px", maxHeight: '1000px' }} className="mb-4">
                                            <Table tableObject={ARClaimTableData} isCheckboxDisabled={checkPermission(permission_key_values_claim.assignlist_ar_tab_action)} onLinkClick={onLinkClick} onClickGridCheckBox={onClickGridCheckBox} checkedHeaderCheckBox={checkedAllClaim} sortingFunction={sortingFunction} tableUpdateFlag={tableUpdateFlag} />
                                        </div>
                                        <Pagination totalPage={totalPage} activePage={currentPage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                                    </>
                                </TabPanel>
                            }
                            {checkPermission(permission_key_values_claim.my_ar_tab_view) &&
                                <TabPanel value={TAB2} className="bordering">
                                    <div style={{ overflowX: "auto", width: "100%", maxWidth: (innerWidth - 300) + "px"}} className="mb-4">
                                        <Table tableObject={MYARClaimTableData} onLinkClick={onMYARLinkClick} sortingFunction={tableSorting}/>
                                    </div>
                                    <Pagination totalPage={totalPageMyAr} activePage={activePageMyAr} startIndex={startIndexMyAr} endIndex={endIndexMyAr} onPagePrevious={onPagePreviousMyAr} onPageUp={onPageUpMyAr} onPageNext={onPageNextMyAr} />
                                </TabPanel>
                            }
                            {selectedClaim && selectedClaim.map((item, index) => {
                                if (item.pk !== TAB1 && item.pk !== TAB2) {
                                    let claimData = arClaimsTableData.find((element) => {
                                        return element.id === item.pk;
                                    })
                                    return (
                                        <TabPanel key={index} value={"editClaim" + item.pk} className="bordering">
                                            <ARClaimTab onSearchARClaims={onSearchARClaims} setReloadARClaimData={setReloadARClaimData} claimPK={item.pk} claimData={claimData} getMyArTableData={getMyArTableData} patientPK={patientPK} />
                                        </TabPanel>
                                    )
                                }

                            })}
                        </TabContext>
                    </Form>
                    <div className="">
                        <CustomizedDialogs type="save" header={header} showModal={showAssignModal} setShowModalWindow={setShowAssignModal} resetForm={resetForm}>
                            <table className="table" id="hover">
                                <thead>
                                    <tr>
                                        <th>{i18n.t("arClaims.assignClaimTab.slNum")}</th>
                                        <th>{i18n.t("arClaims.assignClaimTab.name")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!assignClaimUsersList && assignClaimUsersList.length === 0 &&
                                        <tr>
                                            <td align="center" colSpan='2'>
                                                {noTableRecords}
                                            </td>
                                        </tr>
                                    }
                                    {assignClaimUsersList.length > 0 && assignClaimUsersList.map((item, index) => {
                                        let selected = false;
                                        if (props.claimData && props.claimData.assigned_to_id
                                            && item.id.toString() === props.claimData.assigned_to_id.toString()) {
                                            selected = true;
                                        }
                                        return (
                                            <tr className={selected ? 'tr-selected' : ''} key={index} onClick={() => assign_A_user({ "claim": checkedClaim, "user": item.id })}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </CustomizedDialogs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ARClaims;