import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { DelayReasonCodeTableData } from './DelayReasonCodeTable';
import Table from '../../commons/Table/Table';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from '../../commons/Buttons';
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const DelayReasonCode = (props) => {
	const [showModalWindow, setShowModalWindow] = useState(false);
	const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
	const [delayCode, setDelayCode] = useState('');
	const [meaning, setMeaning] = useState('');
	const [deleteDelayCode, setDeleteDelayCode] = useState('');
	const [deleteDelayCodeId, setDeleteDelayCodeId] = useState('');
	const [editDelayCodeId, setEditDelayCodeId] = useState('');
	const [delayCodeList, setDelayCodeList] = useState([]);
	const [form_error, setFormError] = useState({ 'delayCode': '' });
	const [editForm, setEditForm] = useState(false);
	const [header, setHeader] = useState('');
	const [permission, setPermission] = useState(false);


	//start ==== Alert message properties
	const [showNotify, setShowNotify] = useState('hide');
	const [notifyDescription, setNotifyDescription] = useState('');
	const [notifyType, setNotifyType] = useState('success');
	const setShowLoadingBar = useContext(LoadingContext);

	function showNotifyWindow(action, type, desc, age = 3000) {
		if (action == 'show') {
			setTimeout(() => {
				setShowNotify('hide');
			}, age)
		}
		setShowNotify(action);
		setNotifyType(type);
		setNotifyDescription(desc);
	}
	//End ====

	//Pagination start
	const [totalPage, setTotalPage] = useState(1);
	const [activePage, setActivePage] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

	function onPagePrevious() {
		let previousPage = startIndex + 1 - PAGING_END_INDEX;
		setActivePage(previousPage);

		if (startIndex !== 0) {
			setStartIndex(startIndex - PAGING_END_INDEX);
			setEndIndex(endIndex - PAGING_END_INDEX);
		}
		getDelayReasonCodeData(DEFAULT_PAGING_SIZE, previousPage);
	}

	function onPageUp(e) {
		let page = Number(e.target.id)
		setActivePage(page);
		getDelayReasonCodeData(DEFAULT_PAGING_SIZE, page);
	}

	function onPageNext() {
		let nextPage = startIndex + 1 + PAGING_END_INDEX;
		if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
			setActivePage(nextPage);
			setStartIndex(startIndex);
			setStartIndex(endIndex);
		} else {
			setActivePage(nextPage);
			setStartIndex(startIndex + PAGING_END_INDEX);
			setEndIndex(endIndex + PAGING_END_INDEX);
		}
		getDelayReasonCodeData(DEFAULT_PAGING_SIZE, nextPage);
	}
	//Pagination ends

	function addNew() {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		setHeader(i18n.t("dictionariesPages.dictDelayCode.addHeader"));
		setShowModalWindow(true);
	}

	function onHide() {
		setEditForm(false);
	}

	function onDeleteHide() {
		setDeleteDelayCode('');
		setDeleteDelayCodeId('');
		setShowDeleteModalWindow(false);
	}

	function getDelayReasonCodeData(pageSize,page) {
		setShowLoadingBar(true);
		const result = service.ListDelayReasonCode(pageSize, page);
		result.then(response => {
			if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
			setShowLoadingBar(false);
            const rowArray = commonTableBody(response.data.results, DelayReasonCodeTableData.tableBodyData[0]);
            DelayReasonCodeTableData.tableBodyData = rowArray;
			setDelayCodeList(response.data.results);
		});
	}

	useEffect(() => {
		getDelayReasonCodeData(DEFAULT_PAGING_SIZE, activePage);
		setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
	}, []);

	function onEditDelayReasonCode(delayCodeId) {
		setHeader(i18n.t("dictionariesPages.dictDelayCode.editHeader"));
		setShowLoadingBar(true);
		const data = service.GetDelayReasonCode(delayCodeId);
		data.then(response => {
			setShowLoadingBar(false);
			setShowModalWindow(true);
			setDelayCode(response.data.name);
			setMeaning(response.data.description);
			setEditForm(true);
			setEditDelayCodeId(delayCodeId);
		});
	}

	function onDeleteDelayReasonCode(delayCodeId) {
		delayCodeList.map((item) => {
			if (item.id == parseInt(delayCodeId)) {
				setDeleteDelayCode(item.name);
				setDeleteDelayCodeId(item.id);
				setShowDeleteModalWindow(true);
			}
		});
	}

	function onDeleteAction() {
		setShowLoadingBar(true);
		const data = service.DeleteDelayCode(deleteDelayCodeId);
		data.then(() => {
			setShowLoadingBar(false);
			setDeleteDelayCode('');
			setDeleteDelayCodeId('')
			setShowDeleteModalWindow(false);
			showNotifyWindow('show', 'success', DELETE_SUCCESS);
			getDelayReasonCodeData(DEFAULT_PAGING_SIZE, activePage);
		});
	}

	function resetForm() {
		setDelayCode('');
		setMeaning('');
		setEditForm(false);
		setFormError({
			...form_error,
			['delayCode']: ''
		});

	}
	function onSaveFormData(e) {
		e.preventDefault();
		
			setFormError({
				...form_error,
				['delayCode']: !delayCode.trim() ? 'error' : ''
			});
			setShowLoadingBar(true);
			/**when editForm is true update api is called otherwise Add api is being called */
			let result = editForm ? service.UpdateDelayReasonCode(editDelayCodeId, { name: delayCode, description: meaning }) : service.AddDelayReasonCode({ name: delayCode, description: meaning });
			result.then(response => {
				setShowLoadingBar(false);
				if (response.status == 201 || response.status == 200) {
					if (response.status == 201)
						showNotifyWindow('show', 'success', ADD_SUCCESS);
					else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
					getDelayReasonCodeData(DEFAULT_PAGING_SIZE, activePage);
					resetForm();
					setShowModalWindow(false);
				} else if (response.status === 400 && response.data.record_already_exists !== undefined) {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
				} else {
					showNotifyWindow('show', 'error', ADD_ERROR);
				}
			});
		
	}

	function onHandleChange(e) {
  const { name, value } = e.target;
  const trimmedValue = value.trim();
  
  const errorName = name === "delayCode" ? name : "meaning";

  if (!trimmedValue) {
    setFormError({
      ...form_error,
      [errorName]: "error"
    });
  } else {
    setFormError({
      ...form_error,
      [errorName]: ""
    });
  }

  if (name === "delayCode") {
    setDelayCode(trimmedValue);
  } else if (name === "meaning") {
    setMeaning(trimmedValue);
  }
}



	function backToDictionaryPage() {
		props.history.push(ROUTE_SUPER_DICTIONARIES)
	}

	function dropDownFunction(id, name) {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        if (name.toLowerCase() == 'edit') {
            onEditDelayReasonCode(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeleteDelayReasonCode(Number(id));
        }
    }

	return (
		<React.Fragment>
			<Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
			<div className="col-md-12">
				<div className="box box-content-white">
					<div className="">
						<div className={'dataTables_filter'}>
							<div className="link dictionaries-back pl-0" onClick={backToDictionaryPage}><BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /></div>
						</div>
						<div className="alignRight padding-right5 margin-bottom3">
							<CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")}/>
						</div>
					</div>
					<div className="box-content">
						<div className="table-responsive">
							<Table tableObject={DelayReasonCodeTableData} dropDownFunction={dropDownFunction} />
						</div>
						<div className='mt-2 mb-2'>
						<Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
						</div>
					</div>
				</div>
			</div>
			<CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
				<Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
					<Form.Group>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group padding-top15">
									<TextInput type="text" id="delayCode" name="delayCode" required={true} label={i18n.t('dictionariesPages.dictDelayCode.lblDelayReasonCode')} onValueChange={onHandleChange} className={form_error.delayCode ? 'input-error' : ""} value={delayCode} />
								</div>
								<div className="form-group">
									<label htmlFor='desc-text-area'>{i18n.t('dictionariesPages.dictDelayCode.lblMeaning')}</label>
									<div className={'text-input'}>
										<textarea id='desc-text-area' placeholder={i18n.t('dictionariesPages.dictDelayCode.lblMeaning')} name="meaning" required={true} className={!meaning ? 'textarea-100 input-error' : "textarea-100"} value={meaning} onChange={(e) => onHandleChange(e)}></textarea>
									</div>
								</div>
							</div>
						</div>
					</Form.Group>
				</Form>
			</CustomizedDialogs>
			<CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
				{i18n.t("dictionariesPages.dictDelayCode.deletConfirmation") + "'" + deleteDelayCode + "'?"}
			</CustomizedSmallDialogs>
		</React.Fragment>
	)
}
export default DelayReasonCode
