import React, { useState, useEffect, useContext } from 'react';
import service from './service';
import { useHistory } from 'react-router-dom';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_FACILITIES_ADD, ACTIVE_TYPES, ROUTE_FACILITIES_EDIT } from '../../../utilities/staticConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';

import { getStorage } from '../../../utilities/browserStorage';
import SelectInput from '../../commons/input/SelectInput';
import Table from '../../commons/Table/Table';
import { FacilityTableData } from './FacilityTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import ExportIconButton from '../../commons/exportIconButton';
import { permission_key_values_accountsetup } from '../../../utilities/permissions';


const FacilitiesList = () => {
    const practicePK = getStorage("practice");
    const history = useHistory();
    const [facilitiesList, setFacilitiesList] = useState([]);


    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    //End ====
    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [activeType, setActiveType] = useState('2');

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getFacilitiesData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getFacilitiesData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getFacilitiesData(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends


    useEffect(() => {
        getFacilitiesData(DEFAULT_PAGING_SIZE, 1);
    }, [activeType]);

    function getFacilitiesData(pageSize, page) {
        setShowLoadingBar(true);
        let status = activeType == 1 ? 'all' : activeType == '2' ? 'active' : 'inactive';
        const result = service.ListFacilities(pageSize, page, practicePK, status);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, FacilityTableData.tableBodyData[0])
            FacilityTableData.tableBodyData = rowArray;
            setFacilitiesList(response.data.results);
        });
    }

    function onEditFacility(facilityID) {
        history.push(ROUTE_FACILITIES_EDIT, { facilityID: facilityID });
    }
    function addNewFacility() {
        history.push(ROUTE_FACILITIES_ADD)
    }
    function onHandleChange(e) {
        setActiveType(e.target.value);
    }
    function dropDownFunction(id, name) {
        if (name === 'Edit') {
            if(checkPermission(permission_key_values_accountsetup.account_setup_facility_management_modify)){
                onEditFacility(id);
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }

    const OnExportMenuItemClicked = (e, type) => {
        const fileDownload = require('js-file-download');
        setShowLoadingBar(true);
        let status = activeType === '1' ? 'all' : activeType === '2' ? 'active' : 'inactive';
        service.exportFacilities(type, practicePK, DEFAULT_PAGING_SIZE, 1, status)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else {
                    if (result.status === 404 && result.message === 'no_data_available_for_export') {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.no_record_found_to_export'));
                        setShowLoadingBar(false);
                    }
                    else {
                        throw new Error(`Unexpected Error ${result.status}`)
                    }
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-12">
                <div className="box  min-h-full box-content-white margin-right15">
                    <div className="">
                        {/* permission checking */}
						{checkPermission(permission_key_values_accountsetup.account_setup_facility_management_add)&&
                        <div className="alignRight padding-right5 addNew-btn">
                            <button type="button" onClick={addNewFacility} className="btn btn-primary-blue">{i18n.t("buttons.addNew")}</button>
                        </div>
                        }
                        <div className='alignRight margin-top18 ml-4'>
                            <ExportIconButton
                                disabled = {facilitiesList == undefined || facilitiesList?.length <= 0 ? true : false }
                                onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                                onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                        </div>
                        <div className="alignRight">
                            <SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onHandleChange} label={i18n.t('commons.status')} selectOptionRemove={true} labelClassName={"margin-bottom0"} />
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="table-responsive">
                            <Table tableObject={FacilityTableData} list={facilitiesList} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='mt-2'> 
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default FacilitiesList;