import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation  } from 'react-router-dom';
import service from './service';
import { FinancialTable } from './FinancialTable';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import LoadingContext from '../../../container/loadingContext';
import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import BackArrowWithLabel from "../../commons/Back"; 

import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, ROUTE_CODE_MANAGEMENT, DEFAULT_PAGING_SIZE, ROUTE_FINANCIAL_CLASSESS } from '../../../utilities/staticConfigs';
import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { commonTableBody, checkPermission } from '../../../utilities/commonUtilities';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import ExportIconButton from '../../commons/exportIconButton';
import { permission_key_values_accountsetup, super_admin_privileges, super_admin_permission_key_value  } from '../../../utilities/permissions';
import CommonButton from '../../commons/Buttons';

const FinancialClassList = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [financialClass, setFinancialClass] = useState('');
    const [deleteFinancialClassName, setDeleteFinancialClassName] = useState('');
    const [deleteFinancialClassId, setDeleteFinancialClassId] = useState('');
    const [editFinancialClassId, setEditFinancialClassId] = useState('');
    const [financialClassList, setFinancialClassList] = useState([]);
    const [form_error, setFormError] = useState({ 'financialClass': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);


    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getFinancialClassData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getFinancialClassData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);

        }
        getFinancialClassData(DEFAULT_PAGING_SIZE, nextPage);

    }
    //Pagination ends

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====
    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.dictFinancialClass.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function onDeleteHide() {
        setDeleteFinancialClassName('');
        setDeleteFinancialClassId('');
        setShowDeleteModalWindow(false);
    }

    function getFinancialClassData(pageSize, page) {
        setShowLoadingBar(true);
        let result = service.ListFinancialClasses(pageSize, (page ? page : 1), isAdminModule, isAdminModule === 'true' ? '' : practicePK)
        result.then(response => {
            if (response.data.results !== undefined) {
                let dataTotalPage = Math.ceil(response.data.count / response.data.page_size);
                setTotalPage(dataTotalPage);
                if (dataTotalPage < activePage) {
                    setActivePage(dataTotalPage);
                }
            }
            setShowLoadingBar(false);
            const rowArray = commonTableBody(response.data.results, FinancialTable.tableBodyData[0])
            FinancialTable.tableBodyData = rowArray;
            setFinancialClassList(response.data.results);

        });
    }

    useEffect(() => {
        getFinancialClassData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_FINANCIAL_CLASSESS){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_accountsetup.account_setup_code_management_add,
                 permission_key_values_accountsetup.account_setup_code_management_modify,
                 super_admin_privileges.super_admin_full_privilege));
        } 
    }, []);

    function onEditFinancialClass(financialClassId) {
        setHeader(i18n.t("dictionariesPages.dictFinancialClass.editHeader"));
        setShowLoadingBar(true);
        let data = service.GetFinancialClass(financialClassId ,isAdminModule == 'true' ? '' : practicePK )
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setFinancialClass(response.data.name);
            setEditForm(true);
            setEditFinancialClassId(financialClassId);
        });
    }

    function onDeleteFinancialClass(financialClassId) {
        financialClassList.map((item) => {
            if (item.id == parseInt(financialClassId)) {
                setDeleteFinancialClassName(item.name);
                setDeleteFinancialClassId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = service.DeleteFinancialClass(deleteFinancialClassId, isAdminModule == 'true' ? '' : practicePK)
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteFinancialClassName('');
            setDeleteFinancialClassId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            if (totalPage <= activePage) {
                getFinancialClassData(DEFAULT_PAGING_SIZE, 'last');
            } else {
                getFinancialClassData(DEFAULT_PAGING_SIZE, activePage);
            }
        });
    }

    function resetForm() {
        setFinancialClass('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['financialClass']: ''
        });
    }

    function onSaveFormData(e) {
        e.preventDefault();
        if (!financialClass.trim()) {
            setFormError({
                ...form_error,
                ['financialClass']: 'error'
            });
        } else {
            setShowLoadingBar(true);
            let data = isAdminModule == 'true' ? { name: financialClass } : { practice: practicePK, name: financialClass };
            let result = editForm ? service.UpdateFinancialClass(editFinancialClassId, data) : service.AddFinancialClass(data);
    
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    if (response.status === 201) {
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    }
                    else {
                        showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    }
                    getFinancialClassData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            });
        }
    }

    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (!trimmedValue.trim()) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setFinancialClass(trimmedValue);
    }

    function backToDictionaryPage() {
        props.history.push(ROUTE_SUPER_DICTIONARIES)
    }
    function backToCodes() {
        props.history.push(ROUTE_CODE_MANAGEMENT)
    }

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }

        if (name === 'Edit') {
            onEditFinancialClass(id);
        }
        else {
            onDeleteFinancialClass(id);
        }
    }
    const OnExportMenuItemClicked = (e, type) => {
        // eslint-disable-next-line no-undef
        const fileDownload = require('js-file-download');
        setShowLoadingBar(true);
        service.exportFinancialClass(type, practicePK)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-8">
                <div className="box box-content-white">
                    <div className="">
                        <div className={'dataTables_filter'}>
                            {isAdminModule === 'true' ?
                                <div className="link dictionaries-back" onClick={backToDictionaryPage}><BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /></div>
                                :
                                <div className="link dictionaries-back" onClick={backToCodes}><BackArrowWithLabel label={i18n.t("codes.codes")}  /></div> 
                            }
                        </div>
                            <div className="alignRight margin-bottom8">
                                <CommonButton variant='contained'  onClick={addNew} label={i18n.t("buttons.addNew")} />
                            </div>
                        <div className="alignRight mr-4 margin-bottom8">
                            <ExportIconButton
                                onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                                onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                        </div>
                    </div>
                    <div className="box-content ">
                        <div className="table-responsive">
                            <Table tableObject={FinancialTable} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='padding-top10'></div>
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group padding-top15">
                                    
                                    <TextInput type="text" id="financialClass" name="financialClass" label={i18n.t('dictionariesPages.dictFinancialClass.lblFinancialClass')} onValueChange={onHandleChange} className={form_error.financialClass ? 'input-error' : ""} value={financialClass} required={true} />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.dictFinancialClass.deletConfirmation") + "'" + deleteFinancialClassName + "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    )
}
export default FinancialClassList