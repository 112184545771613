import api from '../../../service/api'

const ListTransportTypes = async(pageSize, page, claimPK) =>{
	let path = 'super-admin/transport-reason/?page_size='+ pageSize +'&page='+ page;
	if (claimPK)
		path += '&opened_claim_pk='+ claimPK;
	return api.get(path);
}

const AddTransportType = async(data) =>{
	let path = 'super-admin/transport-reason/'
	return api.post(path,data);
}
const GetTransportType = async(transportId) =>{
	let path = 'super-admin/transport-reason/' + transportId
	return api.get(path);
}

const UpdateTransportType = async(transportId, data) =>{
	let path = 'super-admin/transport-reason/' + transportId
	return api.put(path,data);
}

const DeleteTransportType = async(transportId) =>{
	let path = 'super-admin/transport-reason/' + transportId
	return api.destroy(path);
}

export default {ListTransportTypes, AddTransportType, GetTransportType, UpdateTransportType, DeleteTransportType}