import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import service from "./service";

import {
  DEFAULT_PAGING_SIZE,
  PAGING_END_INDEX,
} from "../../utilities/staticConfigs";
import Notify from "../commons/notify";
import Table from "../commons/Table/Table";
import i18n from "../../utilities/i18n";
import { getStorage } from "../../utilities/browserStorage";
import {
  ListTemplateTableData,
  TemplateTableBodyData,
} from "./listTemplateTable";
import Pagination from "../commons/pagination";
import LoadingContext from "../../container/loadingContext";
import AddTemplate from "./AddTemplate";
import CustomizedSmallDialogs from "../modalWindowComponent/CustomisedSmallDialog";
import { DELETE_SUCCESS } from "../../utilities/labelConfigs";
import TextInput from "../commons/input/input";
import { checkPermission } from "../../utilities/commonUtilities";
import {module_submodule_permission, permission_key_values_messages, super_admin_privileges } from "../../utilities/permissions";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
function Template() {
  // notify
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const practicePK = getStorage("practice");

  // Pagination
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  const setShowLoading = useContext(LoadingContext);

  const [selectedTemplate, setSelectedTemplate] = useState("");

  const [templateDatas, setTemplateDatas] = useState([]);

  const [deleteTemplateId, setDeleteTemplateId] = useState("");
  const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [searchValueClose, setSearchValueClose] = useState(0);

  useEffect(() => {
    getMessageTemplates(DEFAULT_PAGING_SIZE, activePage);
  }, [searchValueClose]);

  const onCancelEdit = () => {
    setSelectedTemplate(undefined);
    getMessageTemplates(DEFAULT_PAGING_SIZE, activePage);
  };

  async function getMessageTemplates(pageSize, page) {
    // Show loader
    setShowLoading(true);
    try {
      const response = await service.GetMessageTemplateList(
        practicePK,
        pageSize,
        page,
        searchValue
      );
  
      // Hide loader
      setShowLoading(false);
  
      const results = response?.data?.results;
  
      if (!results) {
        return;
      }
  
      // Calculate total pages
      const dataTotalPage = Math.ceil(response.data.count / response.data.page_size);
  
      // Update total page state
      setTotalPage(dataTotalPage);
  
      // Update active page state if necessary
      if (dataTotalPage < activePage) {
        setActivePage(dataTotalPage);
      }
  
      // Process response data into usable format
      const processedData = results.map((result) => {
        return ListTemplateTableData.tableBodyData[0].map((col) => ({
          ...col,
          value: result[col.name],
          id: result.id,
        }));
      });
  
      // Update table data state
      ListTemplateTableData.tableBodyData = processedData.length > 0 ? processedData : TemplateTableBodyData;
  
      // Update template data state
      if (response.data) {
        setTemplateDatas(response.data.results);
      }
    } catch (error) {
      // Handle error
      console.error(error);
      setShowLoading(false);
    }
  }
  

  function onLinkClick(idValue) {
    let obj = templateDatas.find((item) => item.id == idValue);
    setSelectedTemplate(obj);
  }

  
  // Pagination Starts

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);

    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getMessageTemplates(DEFAULT_PAGING_SIZE, previousPage);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getMessageTemplates(DEFAULT_PAGING_SIZE, page);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getMessageTemplates(DEFAULT_PAGING_SIZE, nextPage);
  }
  
  if (selectedTemplate) {
    // pass selected template id to add template screen
    return (
      <AddTemplate
        selectedTemplate={selectedTemplate}
        cancelEdit={onCancelEdit}
      />
    );
  }

  function onDeleteTemplates(templateId) {
    templateDatas.map((item) => {
      if (item.id == parseInt(templateId)) {
        setDeleteTemplateId(item.id);
        setShowDeleteModalWindow(true);
      }
    });
  }
  function dropDownFunction(id, name) {
    const hasPermission = checkPermission(
      super_admin_privileges.super_admin_full_privilege,
      module_submodule_permission.message_template_sub_module_delete
    );
    if (!hasPermission) {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      return;
    }

    if (name.toLowerCase() === "delete") {
      onDeleteTemplates(Number(id));
    }
  }
  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onDeleteAction() {
    setShowLoading(true);
    const result = service.DeleteMessageTemplate(deleteTemplateId);
    result.then(() => {
      setShowLoading(false);
      setShowDeleteModalWindow(false);
      setDeleteTemplateId("");
      showNotifyWindow("show", "success", DELETE_SUCCESS);
      if (totalPage <= activePage) {
        getMessageTemplates(DEFAULT_PAGING_SIZE, "last");
      } else {
        getMessageTemplates(DEFAULT_PAGING_SIZE, activePage);
      }
    });
  }

  function onDeleteHide() {
    setDeleteTemplateId("");
    setShowDeleteModalWindow(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearchTemplate(e);
    }
  };
  function onHandleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let trimmedValue = "";
    trimmedValue = value;
    if (name === "searchValue") setSearchValue(trimmedValue);
  }

  function onSearchTemplate(e) {
    e.preventDefault();
    setActivePage(1);
    setStartIndex(0);
    setEndIndex(PAGING_END_INDEX);
    getMessageTemplates(DEFAULT_PAGING_SIZE, 1);
  }
  return (
    <div style={{ width: "100%" }}>
      <div className="col-md-12">
        <div className="box basic-info-padding">
          <Notify
            showNotify={showNotify}
            setShowNotify={setShowNotify}
            notifyDescription={notifyDescription}
            setNotifyType={setNotifyType}
            setNotifyDescription={setNotifyDescription}
            notifyType={notifyType}
          />

          <div className="patient_search">
            <div className="input-responsible ">
              <div className="form-group padding-top15 relative">
                <TextInput
                  type="text"
                  name="searchValue"
                  value={searchValue}
                  onValueChange={onHandleChange}
                  label="Template Name"
                  onKeyDown={handleKeyDown}
                />

                {(searchValue.length !== 0) && <IconButton
                  className="templateIconBtn_close"
                  onClick={() => {
                    setSearchValue('');
                    setSearchValueClose(searchValueClose + 1)
                  }} >
                  <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                </IconButton>
                }
              </div>
            </div>
            <div className="input-responsible padding-top15">
              <div className="margin-top25">
                <button
                  type="button"
                  onClick={(e) => onSearchTemplate(e)}
                  className="btn btn-primary-blue"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-space-between">
            <div className="col d-flex"></div>
            <div className="padding-right15 mt-4">
              {/* permission checking */}
              {checkPermission(permission_key_values_messages.can_add_templates_messages) &&
                <Link to="/add-template">
                  <button type="button" className="small_box btn-primary-blue btn">
                    Add Template
                  </button>
                </Link>
              }
            </div>
          </div>
          <div className="table-responsive">
            <Table
              tableObject={ListTemplateTableData}
              onLinkClick={onLinkClick}
              dropDownFunction={dropDownFunction} 
              tblRadiusClass={' table-border-radius8 '}
            />                
            <CustomizedSmallDialogs
              showModal={showDeleteModalWindow}
              header={i18n.t("commons.confirmDelete")}
              type="delete"
              deleteItem={onDeleteAction}
              onHide={onDeleteHide}
              setShowModalWindow={setShowDeleteModalWindow}
            >
              {"Are you sure you want to delete the Template?"}
            </CustomizedSmallDialogs>
          </div>
          <div className="mt-2">
            <Pagination
              totalPage={totalPage}
              activePage={activePage}
              startIndex={startIndex}
              endIndex={endIndex}
              onPagePrevious={onPagePrevious}
              onPageUp={onPageUp}
              onPageNext={onPageNext}
            />
            </div>
        </div>
      </div>
    </div>
  );
}

export default Template;
