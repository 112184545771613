import React, { useState, useEffect, useContext } from 'react';
import './style.css';
import service from './service';
import { Redirect, useHistory } from 'react-router-dom';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { permission_key_values_accountsetup } from '../../../utilities/permissions';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ACTIVE_TYPES, ROUTE_PROVIDER_EDIT,ROUTE_PROVIDER_ADD } from '../../../utilities/staticConfigs';

import LoadingContext from '../../../container/loadingContext';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import Pagination from '../../commons/pagination';
import SelectInput from '../../commons/input/SelectInput';
import ExportIconButton from '../../commons/exportIconButton';

import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import { ProviderTableData } from './ProviderTable';

const ProviderManagement = () => {
	const history = useHistory();
	const [providerList, setProviderList] = useState([]);
	const [gotoAdd, setGotoAdd] = useState(false);
	const [selectedID, setSelectedID] = useState('');
	const [deleteProviderName, setDeleteProviderName] = useState('');
	const [deleteProviderId, setDeleteProviderId] = useState('');
	const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
	

	// update default
	const [updateDefaultProviderName, setUpdateDefaultProviderName] = useState('');
	const [updateDefaultProviderId, setUpdateDefaultProviderId] = useState('');
	const [showUpdateDefaultModalWindow, setShowUpdateDefaultModalWindow] = useState(false);
	const [defaultclr, setDefaultClr] = useState('');

	const practicePK = getStorage("practice");

	//start ==== Alert message properties
	const [showNotify, setShowNotify] = useState('hide');
	const [notifyDescription, setNotifyDescription] = useState('');
	const [notifyType, setNotifyType] = useState('success');
	const [activeType, setActiveType] = useState('2');
	const setShowLoadingBar = useContext(LoadingContext);
	function showNotifyWindow(action, type, desc, age = 3000) {
		if (action === 'show') {
			setTimeout(() => {
				setShowNotify('hide');
			}, age)
		}
		setShowNotify(action);
		setNotifyType(type);
		setNotifyDescription(desc);
	}
	//End ====

	//Pagination start
	const [totalPage, setTotalPage] = useState(1);
	const [activePage, setActivePage] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

	function onPagePrevious() {
		let previousPage = startIndex + 1 - PAGING_END_INDEX;
		setActivePage(previousPage);
		if (startIndex !== 0) {
			setStartIndex(startIndex - PAGING_END_INDEX);
			setEndIndex(endIndex - PAGING_END_INDEX);
		}
		getProvidersData(DEFAULT_PAGING_SIZE, previousPage);
	}

	function onPageUp(e) {
		let page = Number(e.target.id)
		setActivePage(page);
		getProvidersData(DEFAULT_PAGING_SIZE, page);
	}

	function onPageNext() {
		let nextPage = startIndex + 1 + PAGING_END_INDEX;
		if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
			setActivePage(nextPage);
			setStartIndex(startIndex);
			setEndIndex(endIndex);
		} else {
			setActivePage(nextPage);
			setStartIndex(startIndex + PAGING_END_INDEX);
			setEndIndex(endIndex + PAGING_END_INDEX);
		}
		getProvidersData(DEFAULT_PAGING_SIZE, nextPage);
	}
	//Pagination ends

	function addNew() {
		setGotoAdd(true);
	}

	function resetForm() {
		setSelectedID("");
		setGotoAdd(false);
	}

	function onDeleteHide() {
		setDeleteProviderName('');
		setDeleteProviderId('');
		setShowDeleteModalWindow(false);
	}

	function onDeleteProvider(providerID) {
		providerList.map((item) => {
			
			if (item.id === parseInt(providerID)) {
				if(item.default_provider) {
				
					showNotifyWindow('show','error',  i18n.t('errorMessages.set-as-default-provider'));

				}
				else {
				setDeleteProviderName(item.full_name);
				setDeleteProviderId(item.id);
				setShowDeleteModalWindow(true);
				}
			}
		
		});
	}

	function onDeleteAction() {
		
		setShowLoadingBar(true);
		
		const data = service.deleteProvider(deleteProviderId);
		data.then(() => {
			setShowLoadingBar(false);
			setDeleteProviderName('');
			setDeleteProviderId('');
			setShowDeleteModalWindow(false);
			showNotifyWindow('show', 'success', DELETE_SUCCESS);
			if (totalPage <= activePage) {
				getProvidersData(DEFAULT_PAGING_SIZE, 'last');
			} else {
				getProvidersData(DEFAULT_PAGING_SIZE, activePage);
			}
		});
	//}
	}

	function onUpdateDefaultProvider(providerID) {
		// update default porvider pop to confirm
		providerList.map((item) => {
			if (item.id === parseInt(providerID)) {
				setUpdateDefaultProviderName(item.full_name);
				setUpdateDefaultProviderId(item.id);
				setShowUpdateDefaultModalWindow(true);
				setDefaultClr(item.id);
			}
		});
	}

	useEffect(() => {
		providerList.map((d)=> {
			if (d.default_provider === true) {
				setDefaultClr(d.id);
		}
		})
	}, [defaultclr,providerList])
	
	function onUpdateDefaultAction() {
		// update default provider API call
		setShowLoadingBar(true);
		let data = { 'practice_id': practicePK, 'default_provider': true }
		const result = service.UpdateDefaultProvider(updateDefaultProviderId, data);
		result.then(() => {
			setShowLoadingBar(false);
			setUpdateDefaultProviderName('');
			setUpdateDefaultProviderId('');
			getProvidersData(DEFAULT_PAGING_SIZE, activePage);
			setShowUpdateDefaultModalWindow(false);
			showNotifyWindow('show', 'success', UPDATE_SUCCESS);
		});
	}

	function onEditProvider(providerID) {
		setSelectedID(providerID);
		history.push(ROUTE_PROVIDER_EDIT, { selectedID: providerID });
	}

	useEffect(() => {
		getProvidersData(DEFAULT_PAGING_SIZE, 1);

	}, [activeType]);

	function getProvidersData(pageSize, active_page) {
		setShowLoadingBar(true);
		let status = activeType == 1 ? 'all' : activeType == '2' ? 'active' : 'inactive';
		const result = service.getProvidersData(pageSize, active_page, practicePK, '', status);
		result.then(response => {
			setShowLoadingBar(false);
			if (response.data && response.data.results !== undefined) {
				setTotalPage(Math.ceil(response.data.count / response.data.page_size));
			}
			const rowArray = commonTableBody(response.data.results, ProviderTableData.tableBodyData[0])
			ProviderTableData.tableBodyData = rowArray;
			setProviderList(response.data.results);
		});
	}
	function onHandleChange(e) {
		setActiveType(e.target.value);
	}

	if (gotoAdd) {
		return <Redirect to={{ pathname: ROUTE_PROVIDER_ADD, state: { selectedID: selectedID } }} />;
	}

	function dropDownFunction(id, name) {
  const permissions = checkPermission(permission_key_values_accountsetup.account_setup_provider_management_modify);

  if (name.trim().toLowerCase() === 'set as default' && permissions) {
    onUpdateDefaultProvider(id);
  } else if (name.toLowerCase() === 'edit' && permissions) {
    onEditProvider(id);
  } else if (name.toLowerCase() === 'delete' && permissions) {
    onDeleteProvider(id);
  } else {
    showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
  }
}

	
	const OnExportMenuItemClicked = (e, type) => {
		const fileDownload = require('js-file-download');
		setShowLoadingBar(true);
		let status = activeType == 1 ? 'all' : activeType == '2' ? 'active' : 'inactive';
		service.exportProvider(type, practicePK,DEFAULT_PAGING_SIZE,1,status)
			.then((result) => {
				if (result.status === 200 || result.status === 201) {
					fileDownload(result.data, result.headers['content-disposition']);
					setShowLoadingBar(false);
					showNotifyWindow('show', 'success', 'Export Success');
				} else {
					throw Error(result.statusText);
				}
			})
			.catch(() => {
				showNotifyWindow('show', 'error', 'Unexpected Error');
				setShowLoadingBar(false);
			});
	};


	return (
		<React.Fragment>
			<Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
			<div className="col-md-12 ">
				<div className="box box-content-white margin-right15">
					<div className=" ">
						<div className="alignRight padding-right5 addNew-btn">
							{/* permission checking */}
							{checkPermission(permission_key_values_accountsetup.account_setup_provider_management_add)&&
							<button type="button" className="btn btn-primary-blue btn-common-height35 " onClick={addNew}>{i18n.t("buttons.addNew")}</button>
							}
						</div>
						<div className='alignRight margin-top18 ml-4'>
							<ExportIconButton
								disabled={providerList == undefined || providerList?.length <= 0 ? true : false }
								onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
								onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
						</div>
						<div className="alignRight">
							<SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onHandleChange} label={i18n.t('commons.status')} selectOptionRemove={true} labelClassName={"margin-bottom0"} />
						</div>
					</div>
					<div className="box-content">
						<div className="table-responsive">
							<Table tableObject={ProviderTableData} dropDownFunction={dropDownFunction} defaultBold={defaultclr} />
						</div>
						<div className='padding-top10'></div>
						<Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
					</div>
				</div>
			</div>
			<CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
				{i18n.t("customerSetup.provider.deletConfirmation") + "'" + deleteProviderName + "'?"}
			</CustomizedSmallDialogs>
			<CustomizedSmallDialogs showModal={showUpdateDefaultModalWindow} header={i18n.t("commons.confirmSetDefault")} type="yes" inactveItem={onUpdateDefaultAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowUpdateDefaultModalWindow}>
				{i18n.t("customerSetup.provider.updateConfirmation") + "'" + updateDefaultProviderName + "'?"}
			</CustomizedSmallDialogs>
		</React.Fragment>
	)

}
export default ProviderManagement