import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import i18n from '../../../utilities/i18n'
import { Link } from 'react-router-dom';
import actionImg from "../../../assets/images/action.png";
import "./style.css";
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';
import PatientsAdvancedSearch from '../patients/PatientsAdvancedSearch';
import PatientDropDownSearch from '../../patientManagement/patients/patientDropDownSearch';
import { checkPermission } from '../../../utilities/commonUtilities';
import { permission_key_values } from '../../../utilities/permissions';

function PatientLetters() {
    const [activeRowId, setActiveRowId] = useState(0);
    const [patientSelected, setPatientSelected] = useState([]);
    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);

    const tableData = [
        {
            name: "Aiden, Carter",
            created_date: "10/15/2020 22:10"
        },
        {
            name: "Patricia, Robbins",
            created_date: "10/15/2021 22:12"
        },
        {
            name: "William, Nash",
            created_date: "10/15/2021 14:29"
        },
        {
            name: "Shawn, Bryant",
            created_date: "10/15/2021 05:25"
        },
    ]

    function onShowActionButtons(event, rowId) {
        let dropdown = document.getElementById("dropdown-" + rowId);
        let dropdownMenu = document.getElementById("dropdown-menu-" + rowId);
        dropdown.classList.toggle("show");
        dropdownMenu.classList.toggle("show");
        if (activeRowId === 0) {
            setActiveRowId(rowId);
        } else if (activeRowId === rowId) {
            setActiveRowId(0);
        } else if (this.state.activeRowId !== rowId) {
            dropdown = document.getElementById("dropdown-" + activeRowId);
            dropdownMenu = document.getElementById("dropdown-menu-" + activeRowId);
            dropdown.classList.toggle("show");
            dropdownMenu.classList.toggle("show");
            setActiveRowId(rowId);
        }
    }

    function onHideActionButtons(event, rowId) {
        let dropdown = document.getElementById("dropdown-" + rowId);
        let dropdownMenu = document.getElementById("dropdown-menu-" + rowId);
        dropdown.classList.remove("show");
        dropdownMenu.classList.remove("show");
        setActiveRowId(0);
    }
    
    
    function remShowExport() {
        document.getElementById("export-dropdown").classList.remove('show');
        document.getElementById("export-menu").classList.remove('show');
        document.getElementById("export-dropdown").classList.remove('active');
        document.getElementById("export-menu").classList.remove('active');
    }

    function onShowExport() {
        document.getElementById("export-dropdown").classList.toggle('show');
        document.getElementById("export-menu").classList.toggle('show');
    }

    return (
        <div className="col-md-12">
            <div className="box basic-info-padding">
                <Form autocomplete="off">
                    <div className="patient_search">
                        <PatientDropDownSearch inputClass={"padding-top15"} orPaddingTop="45px" patientSelected={patientSelected} setPatientSelected={setPatientSelected} patientAdvSearchData={patientAdvSearchData} ></PatientDropDownSearch>

                        <div className="">
                            <div className="input-responsible padding-top15">
                                <button className="btn btn-primary-blue">{i18n.t("patientLetters.search")}</button>
                            </div>
                        </div>

                        <div className="col pl-0 padding-top15">
                            <div className="input-responsible padding-top2">
                                <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                    <PatientsAdvancedSearch setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData} >
                                    </PatientsAdvancedSearch>
                                </RightSidePanel>
                            </div>
                        </div>
                    </div>

                    <div className="row padding-top20 ">
                        <div className="col-auto">
                            <div className="box bordering">
                                <p>John Doe(223424)</p>
                                <p>124, 12th Street, NY 10232</p>
                                <p>(415) 612 2513</p>
                                <p>Johndoe@testmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="padding-top20">
                            <div className="div-float-left padding-top10"><strong className="pl-1">{i18n.t("patientLetters.labelPatientLetters")}</strong>
                            </div>
                            <div className="div-float-right justify-right">
                                <div className='pr-0 justify-right'>
                                    <div className="dropdown" id="export-dropdown">
                                        <div className="row">
                                            <div className="col">
                                                {/* permission checking */}
                                                {checkPermission(permission_key_values.patients_patient_letters_add)&&
                                                <button type='button' id="test" className="btn btn-primary-blue mb-2" data-toggle="dropdown" onBlur={remShowExport} onClick={onShowExport}>
                                                    {i18n.t("patientLetters.labelAddNew")}
                                                </button>
                                                }
                                            </div>
                                        </div>
                                        <div className=''>
                                            <ul className="patientList dropdown-menu download-dropdown width-75" id="export-menu">
                                                <li className="download-dropdown-menu-item width-75">
                                                    <Link to="#"  className="text-dec-none" >{i18n.t('patientLetters.standardLetter')}</Link>
                                                </li>
                                                <li className="download-dropdown-menu-item width-75">
                                                    <Link to="#"  className="text-dec-none">{i18n.t('patientLetters.customLetter')}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{i18n.t("patientLetters.typeOfLetter")}</th>
                                    <th>{i18n.t("patientLetters.createdDate")}</th>
                                    <th>{i18n.t("patientLetters.edit")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Link to="#" className="advancedSearch">
                                                    {item.name}
                                                </Link>
                                            </td>
                                            <td align="center">{item.created_date}</td>
                                            <td align="center">
                                                <div className="">
                                                    <div className="dropdown" id={"dropdown-" + item.id}>
                                                        <button id={"dropdownMenuButton-" + item.id} className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                                                            onClick={(e) => onShowActionButtons(e, item.id)} onBlur={(e) => onHideActionButtons(e, item.id)}>
                                                            <img id="test" src={actionImg} alt="Action" />
                                                        </button>
                                                        <div className="dropdown-menu" id={"dropdown-menu-" + item.id}>
                                                            <Link className="dropdown-item edit" to="#" >
                                                                {i18n.t("patientAddAlert.edit")}
                                                            </Link>
                                                            <Link className="dropdown-item edit" to="#" >
                                                                {i18n.t("patientAddAlert.delete")}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="height-75">&nbsp;</div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default PatientLetters
