import api from '../../../service/api'

const ListBenefitTypes = async(pageSize, page) =>{
	let path = 'super-admin/assignment-benefit/?page_size='+pageSize+'&page='+page
	return api.get(path);
}

const AddBenefitType = async(data) =>{
	let path = 'super-admin/assignment-benefit/'
	return api.post(path,data);
}
const GetBenefitType = async(benefitId) =>{
	let path = 'super-admin/assignment-benefit/' + benefitId
	return api.get(path);
}

const UpdateBenefitType = async(benefitId, data) =>{
	let path = 'super-admin/assignment-benefit/' + benefitId
	return api.put(path,data);
}

const DeleteBenefitType = async(benefitId) =>{
	let path = 'super-admin/assignment-benefit/' + benefitId
	return api.destroy(path);
}

export default {ListBenefitTypes, AddBenefitType, GetBenefitType, UpdateBenefitType, DeleteBenefitType}