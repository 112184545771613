import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation  } from 'react-router-dom';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import TextInput from '../../commons/input/input';
import Pagination from '../../commons/pagination';
import BackArrowWithLabel from "../../commons/Back"; 

import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { permission_key_values_practice_dictionaries, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';
import { ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import service from './service';
import { PatientLanguageTable } from './PatientLanguageTable';
import CommonButton from '../../commons/Buttons';

const PatientLanguageList = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [languageType, setLanguageType] = useState('');
    const [deleteLanguageName, setDeleteLanguageName] = useState('');
    const [deleteLanguageId, setDeleteLanguageId] = useState('');
    const [editLanguageId, setEditLanguageId] = useState('');
    const [languageList, setLanguageList] = useState([]);
    const [form_error, setFormError] = useState({ 'LanguageType': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);

    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getLanguageData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getLanguageData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);

        }
        getLanguageData(DEFAULT_PAGING_SIZE, nextPage);

    }
    //Pagination ends

    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.dictPatientLanguage.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }
    function onDeleteHide() {
        setDeleteLanguageName('');
        setDeleteLanguageId('');
        setShowDeleteModalWindow(false);
    }

    function getLanguageData(pageSize,page) {
        setShowLoadingBar(true);
        let result = service.ListLanguageTypes(pageSize,page, isAdminModule,isAdminModule === 'true' ? '' : practicePK)
        result.then(response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
            const rowArray = commonTableBody(response.data.results, PatientLanguageTable.tableBodyData[0])
			PatientLanguageTable.tableBodyData = rowArray;
            setLanguageList(response.data.results);
        });
    }

    useEffect(() => {
        getLanguageData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === "/dictionaries/patient-language"){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                super_admin_privileges.super_admin_full_privilege))
        }
    }, []);

    function onEditLanguageType(languageId) {
        setHeader(i18n.t("dictionariesPages.dictPatientLanguage.editHeader"));
        setShowLoadingBar(true);
        let data = service.GetLanguageType(languageId,isAdminModule === 'true' ? '' : practicePK)
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setLanguageType(response.data.name);
            setEditForm(true);
            setEditLanguageId(languageId);
        });
    }

    function onDeleteLanguageType(languageId) {
        languageList.map((item) => {
            if (item.id == parseInt(languageId)) {
                setDeleteLanguageName(item.name);
                setDeleteLanguageId(item.id);
                setShowDeleteModalWindow(true);
            }

        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = service.DeleteLanguageType(deleteLanguageId,isAdminModule === 'true' ? '' : practicePK)
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteLanguageName('');
            setDeleteLanguageId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getLanguageData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setLanguageType('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['languageType']: ''
        });

    }
    function onSaveFormData(e) {
        e.preventDefault();
        if (!languageType.trim()) {
          setFormError(prevState => ({ ...prevState, languageType: 'error' }));
          return;
        }
      
        setShowLoadingBar(true);
        const data = isAdminModule === 'true' ? { name: languageType } : { practice: practicePK, name: languageType };
        const result = editForm ? service.UpdateLanguageType(editLanguageId, data) : service.AddLanguageType(data);
        result.then(response => {
          setShowLoadingBar(false);
          if (response.status === 201) {
            showNotifyWindow('show', 'success', ADD_SUCCESS);
          } else if (response.status === 200) {
            showNotifyWindow('show', 'success', UPDATE_SUCCESS);
          } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
          } else {
            showNotifyWindow('show', 'error', ADD_ERROR);
          }
          getLanguageData(DEFAULT_PAGING_SIZE, activePage);
          resetForm();
          setShowModalWindow(false);
        }).catch(() => {
          setShowLoadingBar(false);
          showNotifyWindow('show', 'error', ADD_ERROR);
        });
      }
      
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setLanguageType(trimmedValue);
    }


    function backToDictionaryPage() {
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES);
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES);
    }

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name === 'Edit'){
            onEditLanguageType(id);
        }
        else{
            onDeleteLanguageType(id);
        }
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-8">
                <div className="box box-content-white">
                    <div className="box-head ">
                        <div className={'dataTables_filter'}>
                            <div className="link dictionaries-back" onClick={backToDictionaryPage}>
                                <BackArrowWithLabel label={i18n.t("dictionariesPages.gotoDictionaries")} /> 
                            </div>
                        </div>
                        <div className="alignRight margin-bottom3">
                            <CommonButton  variant="contained" onClick={addNew} label={i18n.t("buttons.addNew")} />
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="table-responsive">
                            <Table tableObject={PatientLanguageTable} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='mt-2 mb-2'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                
                                    <TextInput type="text" required={true} id="languageType" name="languageType" label={i18n.t('dictionariesPages.dictPatientLanguage.lblPatientLanguage')} onValueChange={onHandleChange} className={form_error.languageType ? 'input-error' : ""} value={languageType} />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>  
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.dictPatientLanguage.deletConfirmation") + "'" + deleteLanguageName + "'?"}
            </CustomizedSmallDialogs>  
        </React.Fragment>
    )
}
export default PatientLanguageList