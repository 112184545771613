import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import Table from '../../commons/Table/Table';
import { TransportReasonTableData } from './TransportReasonTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from '../../commons/Buttons';
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const TransportList = (props) => {
	const [showModalWindow, setShowModalWindow] = useState(false);
	const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
	const [transportType, setTransportType] = useState('');
	const [deleteTransportName, setDeleteTransportName] = useState('');
	const [deleteTransportId, setDeleteTransportId] = useState('');
	const [editTransportId, setEditTransportId] = useState('');
	const [transportTypeList, setTransportTypeList] = useState([]);
	const [form_error, setFormError] = useState({ 'transportType': '' });
	const [editForm, setEditForm] = useState(false);
	const [header, setHeader] = useState('');
	const [permission, setPermission] = useState(false);


	//start ==== Alert message properties
	const [showNotify, setShowNotify] = useState('hide');
	const [notifyDescription, setNotifyDescription] = useState('');
	const [notifyType, setNotifyType] = useState('success');
	const setShowLoadingBar = useContext(LoadingContext);

	function showNotifyWindow(action, type, desc, age = 3000) {
		if (action == 'show') {
			setTimeout(() => {
				setShowNotify('hide');
			}, age)
		}
		setShowNotify(action);
		setNotifyType(type);
		setNotifyDescription(desc);
	}
	//End ====

	//Pagination start
	const [totalPage, setTotalPage] = useState(1)
	const [activePage, setActivePage] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

	function onPagePrevious() {
		let previousPage = startIndex + 1 - PAGING_END_INDEX;
		setActivePage(previousPage);

		if (startIndex !== 0) {
			setStartIndex(startIndex - PAGING_END_INDEX);
			setEndIndex(endIndex - PAGING_END_INDEX);
		}
		getTransportData(DEFAULT_PAGING_SIZE, previousPage);
	}

	function onPageUp(e) {
		let page = Number(e.target.id)
		setActivePage(page);
		getTransportData(DEFAULT_PAGING_SIZE, page);
	}

	function onPageNext() {
		let nextPage = startIndex + 1 + PAGING_END_INDEX;
		if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
			setActivePage(nextPage);
			setStartIndex(startIndex);
			setStartIndex(endIndex);
		} else {
			setActivePage(nextPage);
			setStartIndex(startIndex + PAGING_END_INDEX);
			setEndIndex(endIndex + PAGING_END_INDEX);
		}
		getTransportData(DEFAULT_PAGING_SIZE, nextPage);
	}
	//Pagination ends

	function addNew() {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		setHeader(i18n.t("dictionariesPages.transportList.addHeader"));
		setShowModalWindow(true);
	}

	function onHide() {
		setEditForm(false);
	}

	function onDeleteHide() {
		setDeleteTransportName('');
		setDeleteTransportId('');
		setShowDeleteModalWindow(false);
	}

	function getTransportData(pageSize, page) {
		setShowLoadingBar(true);
		const result = service.ListTransportTypes(pageSize, page);
		result.then(response => {
			if (response.data.results !== undefined) {
				setTotalPage(Math.ceil(response.data.count / response.data.page_size));
			}
			setShowLoadingBar(false);
			/**
			 * making a common table format
			 */
			const rowArray = commonTableBody(response.data.results, TransportReasonTableData.tableBodyData[0]);
			TransportReasonTableData.tableBodyData = rowArray;
			setTransportTypeList(response.data.results);
		});
	}

	useEffect(() => {
		getTransportData(DEFAULT_PAGING_SIZE, activePage);
		setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
	}, []);

	function onEditTransportType(transportId) {
		setHeader(i18n.t("dictionariesPages.transportList.editHeader"));
		setShowLoadingBar(true);
		const data = service.GetTransportType(transportId);
		data.then(response => {
			setShowLoadingBar(false);
			setShowModalWindow(true);
			setTransportType(response.data.name);
			setEditForm(true);
			setEditTransportId(transportId);
		});
	}

	function onDeleteTransportType(transportId) {
		transportTypeList.map((item) => {
			if (item.id == parseInt(transportId)) {
				setDeleteTransportName(item.name);
				setDeleteTransportId(item.id);
				setShowDeleteModalWindow(true);
			}
		});
	}

	function onDeleteAction() {
		setShowLoadingBar(true);
		const data = service.DeleteTransportType(deleteTransportId);
		data.then(() => {
			setShowLoadingBar(false);
			setDeleteTransportName('');
			setDeleteTransportId('');
			setShowDeleteModalWindow(false);
			showNotifyWindow('show', 'success', DELETE_SUCCESS);
			getTransportData(DEFAULT_PAGING_SIZE, activePage);
		});
	}

	function resetForm() {
		setTransportType('');
		setEditForm(false);
		setFormError({
			...form_error,
			['transportType']: ''
		});

	}
	function onSaveFormData(e) {
		e.preventDefault();
		//setting error
		setFormError({
			...form_error,
			['transportType']:!transportType.trim() ?  'error' : ''
		});
		if (transportType) {
			setShowLoadingBar(true);
			let result = null;
			//calling update and add api on edit form condition if it is true update will happend else new transport reason will be added
			result=editForm ? service.UpdateTransportType(editTransportId, { name: transportType }) : service.AddTransportType({ name: transportType }); 
			result.then(response => {
				setShowLoadingBar(false);
				if (response.status == 201 || response.status == 200) {
					if (response.status == 201)
						showNotifyWindow('show', 'success', ADD_SUCCESS);
					else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
					getTransportData(DEFAULT_PAGING_SIZE, activePage);
					resetForm();
					setShowModalWindow(false);
				} else if (response.status === 400 && response.data.record_already_exists !== undefined) {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
				} else {
					showNotifyWindow('show', 'error', ADD_ERROR);
				}
			});
		}
	}
	function onHandleChange(e) {
		const { name } = e.target;
		let value = e.target.value;
		let trimmedValue = '';
		if (e.target.type === "text") {
			trimmedValue = value;
		}
		if (!trimmedValue) {
			setFormError({
				...form_error,
				[name]: 'error'
			});
		} else {
			setFormError({
				...form_error,
				[name]: ''
			});
		}
		setTransportType(trimmedValue);
	}
	
	function backToDictionaryPage() {
		props.history.push(ROUTE_SUPER_DICTIONARIES)
	}

	function dropDownFunction(id, name) {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		if (name.toLowerCase() == 'edit') {
			onEditTransportType(Number(id));
		}
		if (name.toLowerCase() == 'delete') {
			onDeleteTransportType(Number(id));
		}
	}

	return (
		<React.Fragment>
			<Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
			<div className="col-md-8">
				<div className="box box-content-white">
					<div className="box-head ">
						<div className={'dataTables_filter'}>
							<div className="link dictionaries-back" onClick={backToDictionaryPage}><BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /></div>
						</div>
						<div className="alignRight padding-right5 margin-bottom2">
							<CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")}/>
						</div>
					</div>
					<div className="box-content">
						<div className="table-responsive">
							<Table tableObject={TransportReasonTableData} dropDownFunction={dropDownFunction} />
						</div>
						<div className='mt-2 mb-2'>
							<Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
						</div>
					</div>
				</div>
			</div>
			<CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
				<Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
					<Form.Group>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group padding-top15">
									<TextInput type="text" required={true} id="transportType" name="transportType" label={i18n.t('dictionariesPages.transportList.lblTransportReason')} onValueChange={onHandleChange} className={form_error.transportType ? 'input-error' : ""} value={transportType} />
								</div>
							</div>
						</div>
					</Form.Group>
				</Form>
			</CustomizedDialogs>
			<CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
				{i18n.t("dictionariesPages.transportList.deletConfirmation") + "'" + deleteTransportName + "'?"}
			</CustomizedSmallDialogs>
		</React.Fragment>
	)
}
export default TransportList