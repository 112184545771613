import React, { useState, useEffect, useContext } from "react";
import { useLocation  } from 'react-router-dom';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import service from "./service";
import {
  CPTTableObject,
  CPTTableBodyOnly,
  CPTCODEAddTableObject,
  CPTAdminTableObject,
} from "./CPTTableObject";
import i18n from "../../../utilities/i18n";
import { csvFileCheck } from "../../../utilities/validations";
import { getStorage } from "../../../utilities/browserStorage";
import { checkPermission, commonTableBody } from "../../../utilities/commonUtilities";
import {
  DELETE_SUCCESS,
} from "../../../utilities/labelConfigs";
import {
  ROUTE_SUPER_DICTIONARIES,
  PAGING_END_INDEX,
  DEFAULT_PAGING_SIZE,
  ROUTE_CODE_MANAGEMENT,
  ROUTE_CPT_CODES,ACTIVE_TYPES,
} from "../../../utilities/staticConfigs";
import LoadingContext from "../../../container/loadingContext";
import Notify from "../../commons/notify";
import Table from "../../commons/Table/Table";
import TextInput from "../../commons/input/input";
import Pagination from "../../commons/pagination";
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";
import SelectInput from '../../commons/input/SelectInput';
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { CptEdit } from "./CptEdit";
import { CptAddForm } from "./cptadd";
import ExportIconButton from "../../commons/exportIconButton";
import { permission_key_values, permission_key_values_accountsetup, super_admin_permission_key_value, super_admin_privileges  } from "../../../utilities/permissions";

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const CPTCodes = (props) => {
  const isAdminModule = getStorage("isAdminModule");
  const location = useLocation();
  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);
  const [CPTCodeList, setCPTCodeList] = useState([]);
  const [masterCPTCodeList, setMasterCPTCodeList] = useState([]);
  const [searchCPT, setSearchCPT] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchValueClose, setSearchValueClose] = useState(0);
  const [showModalWindow, setShowModalWindow] = useState(false);
  const [showManualModalWindow, setShowManualModalWindow] = useState(false);

  const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
  const [deleteCPTCode, setDeleteCPTCode] = useState("");
  const [deleteCPTCodeId, setDeleteCPTCodeId] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderingField, setOrderingField] =
    useState("name"); /* show the triangle only in clicked header */
  const [initialOrdering, setInitialOrdering] = useState(true);
  CPTTableObject.tableHeadings[0].initialOrdering = initialOrdering;
  CPTTableObject.tableHeadings[0].orderType = orderType;
  CPTAdminTableObject.tableHeadings[0].initialOrdering = initialOrdering;
  CPTAdminTableObject.tableHeadings[0].orderType = orderType;

  //start ==== Alert message properties
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  const [activeType, setActiveType] = useState('2');
  const [editModalwindow, setEditModalwindow] = useState(false);

  //Master Pagination start
  const [editId, setEditId] = useState();
  const [manualActiveStatus, setManualActiveStatus] = useState(false);
  const [addActiveStatus, setAddActiveStatus] = useState(false);
  const [editActiveStatus, setEditActiveStatus] = useState(false);

  //permission state
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    if(location.pathname === ROUTE_CPT_CODES){
      setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
  } else {
    setPermission(checkPermission(permission_key_values_accountsetup.account_setup_code_management_add,
       permission_key_values_accountsetup.account_setup_code_management_modify,
      super_admin_privileges.super_admin_full_privilege));
  }

  }, []);


  useEffect(() => {
    let newWidth = "";
    newWidth = window.innerWidth;
    let menuWidth = document
      .getElementById("push-bar")
      .classList.contains("open");
    if (!menuWidth) {
      newWidth = newWidth + 250;
    } else {
      newWidth = window.innerWidth;
    }
    return newWidth;
  }, [window.innerWidth]);

  // Pagination Starts

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);

    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getCPTCodeList(DEFAULT_PAGING_SIZE, previousPage, orderingField);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getCPTCodeList(DEFAULT_PAGING_SIZE, page, orderingField);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getCPTCodeList(DEFAULT_PAGING_SIZE, nextPage, orderingField);
  }
 

  //Pagination ends
  function getCPTCodeList(pageSize, page, fieldName, sorting) {
    setShowLoadingBar(true);
    let status = activeType == 1 ? 'all' : activeType == '2' ? 'active' : 'inactive';
    let fieldOrderType = "";
    if (sorting) {
      if (!orderType) fieldOrderType = "-";
    } else {
      fieldOrderType = orderType;
    }
    const moduleType = isAdminModule === "true" ? null : practicePK;
    const serviceArgs = [pageSize,  page, isAdminModule, moduleType, false, searchValue, null, fieldName, fieldOrderType];
    const result = service.ListCPTCodes(...serviceArgs,status)
    result.then(handleListCPTCodesResponse);
  }

  /**
   * cpt code api call response handling
   * @param {*} response 
   */
  async function handleListCPTCodesResponse(response) {
    setShowLoadingBar(false);
    const { results, count, page_size } = response?.data ?? {};
    if (results?.length > 0) {
      const dataTotalPage = Math.ceil(count / page_size);
      setTotalPage(dataTotalPage);
      const rowArray = await getRowData(results)
      const tableBodyData = rowArray?.length  > 0 ? rowArray : isAdminModule === "true" ? CPTTableBodyOnly : CPTTableBodyOnly;
      if (isAdminModule === "true") {
        CPTAdminTableObject.tableBodyData = tableBodyData;
      } else {
        CPTTableObject.tableBodyData = tableBodyData;
      }
      setCPTCodeList(results);
    } else {
      setCPTCodeList([]);
    }
  }

  /**
   * get table array format for listing the table
   * @param {*} results 
   * @param {*} index 
   * @returns 
   */
  function getRowData(results) {
    let rowArray = [];
    if (results?.length) {
      let newData = Array(results.length).fill(
        JSON.parse(
          JSON.stringify(
            isAdminModule === "true"
              ? CPTAdminTableObject.tableBodyData[0]
              : CPTTableObject.tableBodyData[0]
          )
        )
      );

      newData.map((row, rowIndex) => {
        let anArray = [];
        row.map((col) => {
          let colObject = {};
          if (isAdminModule === "false" || isAdminModule === "true") {
            colObject = {
              ...col,
              value: results[rowIndex][col.name],
              id: results[rowIndex].id,
            };
          }
          anArray.push(colObject);
        });
        rowArray.push(anArray);
        anArray = [];
      });
      return rowArray;
    }
  }



  function getMasterCPTCodeList(pageSize, page, search, fromSearch) {
    setShowLoadingBar(true);
    let result = null;
  
    const commonParams = [pageSize, page, isAdminModule, practicePK, true, "dropdown"];
  
    if (fromSearch || (searchCPT && searchCPT.length > 1)) {
      const searchParams = fromSearch ? search : searchCPT;
      result = service.ListCPTCodes(...commonParams, searchParams);
    } else {
      result = service.ListCPTCodes(...commonParams, null);
    }
  
    result
      .then((response) => {
        setShowLoadingBar(false);
        const rowArray = commonTableBody(response.data.results, CPTCODEAddTableObject.tableBodyData[0]);
        CPTCODEAddTableObject.tableBodyData = rowArray;
  
        setMasterCPTCodeList(response.data.results);
      })
      .catch(() => {
      });
  }
  

  useEffect(() => {
    if (activeType || searchValueClose)
      getCPTCodeList(DEFAULT_PAGING_SIZE, 1);
  }, [activeType, searchValueClose])

  function backToDictionaryPage() {
    props.history.push(ROUTE_SUPER_DICTIONARIES);
  }

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onHandleCsvImport(e) {
    e.preventDefault();
    document.getElementById("file").click();
  }

  function onHandleCSVChange(e) {
    setShowLoadingBar(true);
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    if (e.target.type === "file") {
      if (e.target.files[0] && csvFileCheck(e.target.files[0].name)) {
        const data = new FormData();
        data.append("file", value);
        let result = service.ImportCSVData(data);
        result.then((response) => {
          if (response.status == 200) {
            setShowLoadingBar(false);
            if (response.data.message !== undefined) {
              if (response.data.message === "no_file_records") {
                showNotifyWindow(
                  "show",
                  "error",
                  i18n.t("errorMessages.empty_file")
                );
              } else if (response.data.message === "invalid_headings") {
                showNotifyWindow(
                  "show",
                  "error",
                  i18n.t("errorMessages.invalid_headings")
                );
              } else if (response.data.message === "data_imported") {
                if (
                  response.data.existing_name &&
                  response.data.existing_name.length > 0
                ) {
                  showNotifyWindow(
                    "show",
                    "warning",
                    i18n.t("errorMessages.record_not_imported") +
                    " \n " +
                    response.data.existing_name.join(",\n")
                  );
                } else {
                  showNotifyWindow(
                    "show",
                    "success",
                    i18n.t("validations.success.import_success")
                  );
                }
                getCPTCodeList(DEFAULT_PAGING_SIZE, 1);
              }
            } else {
              showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.import_failed")
              );
            }
          } else if (response.status === 405) {
            setShowLoadingBar(false);
            showNotifyWindow(
              "show",
              "error",
              i18n.t("errorMessages.file_mismatch")
            );
          } else {
            setShowLoadingBar(false);
            showNotifyWindow(
              "show",
              "error",
              i18n.t("errorMessages.import_failed")
            );
          }
        });
      }
    }
    document.getElementById("file").value = "";
  }

  const addNew = () => {
    if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
    setShowModalWindow(true);
  };

  const addNewManual = () => {
    if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
    setShowManualModalWindow(true);
  };

  /**
   * storing input filed values
   * @param {*} e 
   */
  function onHandleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let trimmedValue = "";
    trimmedValue = value;
    switch (name) {
      case "searchCPT":
        setSearchCPT(trimmedValue);
        getMasterCPTCodeList(DEFAULT_PAGING_SIZE, 1, trimmedValue, true);
        break;
      case "searchValue":
        setSearchValue(trimmedValue);
        break;
      default:
        // handle default case
        break;
    }    
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearchCPTCodes(e);
    }
  };

  function onSearchCPTCodes(e, fieldName) {
    e.preventDefault();
    setActivePage(1)
    setStartIndex(0);
    setEndIndex(PAGING_END_INDEX);
    if (!fieldName) {
      // if not field name then call from ,search button click
      setInitialOrdering(true);
      setOrderType("");
    }
    getCPTCodeList(DEFAULT_PAGING_SIZE, 1 , fieldName, true);
  }

  function onSortingCPTCodes(e, fieldName) {
    e.preventDefault();
    setStartIndex(0);
    setEndIndex(PAGING_END_INDEX);
    if (!fieldName) {
      // if not field name then call from ,search button click
      setInitialOrdering(true);
      setOrderType("");
    }
    getCPTCodeList(DEFAULT_PAGING_SIZE, activePage , fieldName, true);
  }
  function onSaveNewCPT(type, message) {
    showNotifyWindow("show", type, message);

    getCPTCodeList(DEFAULT_PAGING_SIZE, activePage > 0 ? activePage : 1);
    setActivePage(activePage > 0 ? activePage :  1);
    resetForm();
   
  }

  function resetForm() {
    setSearchValue("");
    setSearchValueClose(0)
  }


  function onDeleteCPTCode(pk) {
    if (isAdminModule === "false" || isAdminModule === "true") {
      let item = CPTCodeList.find((obj) => obj.id == pk);
      setDeleteCPTCodeId(item.id);
      setDeleteCPTCode(item.name);
      setShowDeleteModalWindow(true);
    }
  }

  function onDeleteAction() {
    setShowLoadingBar(true);
    const path = isAdminModule === "true" ? "super-admin" : "practice";
    const result = service.DeleteCPTCodeFromPractice(deleteCPTCodeId, path);
    result.then(() => {
      setShowLoadingBar(false);
      setShowDeleteModalWindow(false);
      setDeleteCPTCode("");
      setDeleteCPTCodeId("");
      showNotifyWindow("show", "success", DELETE_SUCCESS);
      if (totalPage <= activePage) {
        getCPTCodeList(DEFAULT_PAGING_SIZE, "last");
      } else {
        getCPTCodeList(DEFAULT_PAGING_SIZE, activePage);
      }
    });
  }

  function onDeleteHide() {
    setDeleteCPTCode("");
    setDeleteCPTCodeId("");
    setShowDeleteModalWindow(false);
  }

  const tableSorting = (e) => {
    setInitialOrdering(false);
    setOrderingField("name");
    if (!orderType) setOrderType("-");
    else setOrderType("");
    onSortingCPTCodes(e, "name");
  };

  function backToCodes() {
    props.history.push(ROUTE_CODE_MANAGEMENT);
  }

  function onHandleChangeactive(e) {
    setActiveType(e.target.value);
}

  function dropDownFunction(id, name) {
    if (!permission) {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      return;
    }
    if (name.toLowerCase() == "delete") {
      onDeleteCPTCode(Number(id));
    } else if (name.toLowerCase() == "edit") {
      setEditId(id);
      setEditModalwindow(true);
    }
  }
  const closeEditWindow = () => {
    setEditModalwindow(false);
  };
  const OnExportMenuItemClicked = (e, type) => {
    // eslint-disable-next-line no-undef
    const fileDownload = require("js-file-download");
    setShowLoadingBar(true);
    let status = activeType === 1 ? 'all' : activeType === '2' ? 'active' : 'inactive';
    service
      .exportCPTCodes(type, practicePK,status)
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          fileDownload(result.data, result.headers["content-disposition"]);
          setShowLoadingBar(false);
          showNotifyWindow("show", "success", "Export Success");
        } else {
          showNotifyWindow("show", "error", "Unexpected Error");
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", "Unexpected Error");
      });
  };

  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <div className="col-md-12">
        <div className="box box-content-white">
          <div className="row">
            <div className="col pl-4 mb-1">
              <div className={"dataTables_filter"}>
                {isAdminModule === "true" ? (
                  <div className="link dictionaries-back" onClick={backToDictionaryPage} >
                    <BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /> 
                    </div>
                  ) : (
                    <div className="link dictionaries-back" onClick={backToCodes}
                    >
                     <BackArrowWithLabel label={i18n.t("codes.codes")} /> 
                    </div>
                )}
              </div>
            </div>
          </div>

          <div className="box-content">
            <div className="patient_search">
              <div className="input-responsible">
                <div className="form-group padding-top15 relative">
                  <TextInput
                    type="text"
                    name="searchValue"
                    value={searchValue}
                    onValueChange={onHandleChange}
                    label={i18n.t(
                      "dictionariesPages.dictCPTCode.cptOrDescription"
                    )}
                    onKeyDown={handleKeyDown} 
                  />
                  {(searchValue.length !== 0) && <IconButton
                    className="templateIconBtn_close"
                    onClick={() => {
                      setSearchValue('');
                      setActivePage(1);
                      setSearchValueClose(searchValueClose + 1)
                    }} >
                    <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                  </IconButton>
                  }
                </div>
              </div>
              <div className="input-responsible padding-top15">
                <div className="margin-top15">
                  <button
                    type="button"
                    onClick={(e) => onSearchCPTCodes(e)}
                    className="btn btn-primary-blue margin-top10"
                  >
                    {i18n.t("dictionariesPages.dictCPTCode.search")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box box-content-white margin-top10">
          <div className="table-responsive ">
              {isAdminModule === "true" && (
                <div className="alignRight margin-bottom10">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip
                        id="download-tooltip"
                        style={{
                          opacity: 0.4,
                        }}
                      >
                        {i18n.t("commons.importCSV")}
                      </Tooltip>
                    }
                  >
                    <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  />
                  </OverlayTrigger>
                  <input
                    name="csvFile"
                    type="file"
                    accept=".csv"
                    id="file"
                    onChange={onHandleCSVChange}
                    style={{ display: "none" }}
                  />
                </div>
              )}
              {isAdminModule !== "true" && (
                <div>
                      <div className="alignRight margin-bottom10 margin-top18">
                        <button
                          type="button"
                          className="btn btn-primary-blue"
                          onClick={addNew}
                        >
                          {i18n.t("buttons.addFromMaster")}
                        </button>
                      </div>
                      <div className="alignRight margin-bottom10 margin-top18 mr-4">
                        <button
                          type="button"
                          className="btn btn-primary-blue"
                          onClick={addNewManual}
                        >
                          {i18n.t("buttons.addManually")}
                        </button>
                      </div>
                  <div className="alignRight margin-bottom10 margin-top18 mr-4">
                    <ExportIconButton
                      onExportCsv={(e) => OnExportMenuItemClicked(e, "csv")}
                      onExportXls={(e) => OnExportMenuItemClicked(e, "xls")}
                    />
                  </div>
                  <div style={{marginRight:20,float:'right'}}>
                            <SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onHandleChangeactive} label={i18n.t('commons.status')} selectOptionRemove={true} labelClassName={"margin-bottom0"} />
                        </div>
                </div>
              )}
              
            <div className={""} style={{ overflowX: "auto", width: "100%"}}>
              <Table
                tableObject={
                  isAdminModule === "true"
                    ? CPTAdminTableObject
                    : CPTTableObject
                }
                dropDownFunction={dropDownFunction}
                isActionVisible={checkPermission(permission_key_values.dashBoard_add)}
                sortingFunction={tableSorting}
                list={masterCPTCodeList}
              />
            </div>
              
              <Pagination
                totalPage={totalPage}
                activePage={activePage}
                startIndex={startIndex}
                endIndex={endIndex}
                onPagePrevious={onPagePrevious}
                onPageUp={onPageUp}
                onPageNext={onPageNext}
              />
              <div className="clear-b-scroll-sector">&nbsp;</div>
              <div className="clear-b-scroll-sector">&nbsp;</div>
          </div>
        </div>
      </div>
      {/* Add CPT */}
      <CustomizedSmallDialogs
        chkInactive={true}
        setInactive={(value) => setAddActiveStatus(value)}
        inactive={addActiveStatus}
        showModal={showModalWindow}
        type="save"
        header={i18n.t("dictionariesPages.dictCPTCode.addHeaderMasterList")}
        setShowModalWindow={setShowModalWindow}
        resetForm={resetForm}
        fullWidth={true}
      >
        <CptAddForm
          showNotification={showNotifyWindow}
          activeStatus={addActiveStatus}
          manualAdding={false}
          closeModal={setShowModalWindow}
          onSaveNewCPT={onSaveNewCPT}
        />
      </CustomizedSmallDialogs>
      {/* Manual adding */}
      <CustomizedSmallDialogs
        chkInactive={true}
        setInactive={(value) => setManualActiveStatus(value)}
        inactive={manualActiveStatus}
        showModal={showManualModalWindow}
        type="save"
        header={i18n.t("dictionariesPages.dictCPTCode.addHeaderManually")}
        setShowModalWindow={setShowManualModalWindow}
        resetForm={resetForm}
        fullWidth={true}
      >
        <CptAddForm
          showNotification={showNotifyWindow}
          activeStatus={manualActiveStatus}
          manualAdding={true}
          closeModal={setShowManualModalWindow}
          onSaveNewCPT={onSaveNewCPT}
          showNotifyWindow={showNotifyWindow}
        />
      </CustomizedSmallDialogs>
      {/* Delete CPT */}
      <CustomizedSmallDialogs
        showModal={showDeleteModalWindow}
        header={i18n.t("commons.confirmDelete")}
        type="delete"
        deleteItem={onDeleteAction}
        resetForm={resetForm}
        onHide={onDeleteHide}
        setShowModalWindow={setShowDeleteModalWindow}
      >
        {i18n.t("dictionariesPages.dictCPTCode.confirmDelete") +
          "  " +
          deleteCPTCode +
          " ?"}
      </CustomizedSmallDialogs>
      {/* Edit CPT */}
      <CustomizedSmallDialogs
        chkInactive={true}
        setInactive={(value) => setEditActiveStatus(value)}
        inactive={editActiveStatus}
        showModal={editModalwindow}
        header={"Edit"}
        type="save"
        fullWidth={true}
        setShowModalWindow={setEditModalwindow}
      
      >
        <CptEdit
          showNotification={showNotifyWindow}
          pk={editId}
          activeStatus={editActiveStatus}
          setEditStatus={(val) => setEditActiveStatus(val)}
          closeModal={closeEditWindow}
          onSaveNewCPT={onSaveNewCPT}
        />
      </CustomizedSmallDialogs>
    </React.Fragment>
  );
};
export default CPTCodes;
