import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import service from './service';
import i18n from '../../../utilities/i18n';
import Notify from '../../commons/notify';
import TextInput from '../../commons/input/input';
import LoadingContext from '../../../container/loadingContext';
import Pagination from '../../commons/pagination';
import { csvFileCheck } from '../../../utilities/validations';

import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, POS_CSV_NAME } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS,DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import CalendarInput from '../../commons/input/CalendarInput';
import Table from '../../commons/Table/Table';
import { PosCodesTableData } from './PosCodesTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const POSCodesList = (props) => {

    const [arrPOSCodesList, setPOSCodesList] = useState([]);

    const [showModalWindow, setShowModalWindow] = useState(false);
    const [modalHeader, setModalHeader] = useState(i18n.t('dictionariesPages.dictPOSCodes.modalAddHeader'));

    const [POSCode, setPOSCode] = useState('');
    const [POSName, setPOSName] = useState('');
    const [POSDescr, setPOSDescr] = useState('');
    const [POSDateType, setPOSDateType] = useState('date');
    const [POSDate, setPOSDate] = useState("");
    const [POSDateString, setPOSDateString] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [permission, setPermission] = useState(false);

    const [deletePOScode, setDeletePOScode] = useState('');
    const [deletePOSId, setDeletePOSId] = useState('');
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false); 

    const [editPOSCodeId, setEditPOSCodeId] = useState('');

    const [form_error, setFormError] = useState({ 'POSCode': '', 'POSName': '', 'POSDescr': '', 'POSDate': '' });
    const [editForm, setEditForm] = useState(false);
    const [orderType, setOrderType] = useState('');
    const [orderingField, setOrderingField] = useState('name');
    
    const [initialOrderingCode, setInitialOrderingCode] = useState(true);
    const [initialOrderingServiceName, SetInitialOrderingServiceName] = useState(true);
    PosCodesTableData.tableHeadings[0].initialOrdering = initialOrderingCode;
    PosCodesTableData.tableHeadings[0].orderType = orderType;
    PosCodesTableData.tableHeadings[1].initialOrdering = initialOrderingServiceName;
    PosCodesTableData.tableHeadings[1].orderType = orderType;/* show the triangle only in clicked header */

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    const setShowLoadingBar = useContext(LoadingContext);

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    useEffect(() => {
        let newWidth = "";
        newWidth = window.innerWidth;
        let menuWidth = document.getElementById("push-bar").classList.contains('open');
        if (!menuWidth) {
            newWidth = newWidth + 250;
        } else {
            newWidth = window.innerWidth;
        }
        setInnerWidth(newWidth);
    }, [window.innerWidth]);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getPOSCodesData(DEFAULT_PAGING_SIZE, previousPage, orderingField);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getPOSCodesData(DEFAULT_PAGING_SIZE, page, orderingField);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getPOSCodesData(DEFAULT_PAGING_SIZE, nextPage, orderingField);
    }
    //Pagination ends

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        resetForm();
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function getPOSCodesData(pageSize, page, fieldName, sorting,search) {
        let fieldOrderType = '';
         fieldOrderType = sorting  ? !orderType ? '-': '' : orderType
         setShowLoadingBar(true);
        const result = service.GetPOSCodesList(pageSize, page, search, fieldName, fieldOrderType); // NO PAGINATION REQUIRED FOR THIS PAGE 
        result.then(response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, PosCodesTableData.tableBodyData[0]);
            PosCodesTableData.tableBodyData = rowArray;
            setPOSCodesList(response.data.results);
            setShowLoadingBar(false);
        });
    }

    useEffect(() => {
        getPOSCodesData(DEFAULT_PAGING_SIZE, activePage);
        setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add));
    }, []);

    function onEditPOSCode(POSCodeId) {
        resetForm();
        const data = service.GetPOSCode(POSCodeId);
        data.then(response => {
            setEditPOSCodeId(POSCodeId);
            setModalHeader(i18n.t('dictionariesPages.dictPOSCodes.modalEditHeader'));
            setEditForm(true);
            setShowModalWindow(true);
            setPOSCode(response.data.name);
            setPOSName(response.data.service_place_name);
            setPOSDescr(response.data.description);
            setPOSDate(new Date(response.data.effective_date));
            setPOSDateString(response.data.effective_date_string);
            if (response.data.effective_date) setPOSDateType('date');
            else if (response.data.effective_date_string) setPOSDateType('string');
        });

    }
    function onDeletePOSCode(POSCodeId) {
        arrPOSCodesList.map((item) => {
            if (item.id == parseInt(POSCodeId)) {
                setDeletePOScode(item.name);
                setDeletePOSId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }
    function onDeleteHide() {
        setDeletePOScode('');
        setDeletePOSId('');
        setShowDeleteModalWindow(false);
    }
    function onDeleteAction() {
        const data = service.DeletePOSCode(deletePOSId);
        data.then(() => {
            setDeletePOScode('');
            setDeletePOSId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getPOSCodesData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setPOSCode('');
        setPOSName('');
        setPOSDescr('');
        setPOSDate("");
        setPOSDateType('date');
        setPOSDateString('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['POSCode']: '',
            ['POSName']: '',
            ['POSDescr']: '',
            ['POSDate']: ''
        });
    }

    function onSaveFormData(e) {
        e.preventDefault();
        
            setFormError({
                ...form_error,
                ['POSCode']: !POSCode ? 'error' : '',
                ['POSName']: !POSName ? 'error' : '',
                ['POSDate']: POSDateType == 'date' && (!POSDate || !Date.parse(POSDate)) ? 'error' : '',

            });
       
            setShowLoadingBar(true);
            let result = null;
            if (editForm) {
                if (POSDateType == 'string') {
                    result = service.UpdatePOSCode(editPOSCodeId, { name: POSCode, service_place_name: POSName, description: POSDescr, effective_date_string: POSDateString });
                }
                else {
                    result = service.UpdatePOSCode(editPOSCodeId, { name: POSCode, service_place_name: POSName, description: POSDescr, effective_date: format(POSDate, 'yyyy-MM-dd') });
                }
            } else {
                if (POSDateType == 'string') {
                    result = service.AddPOSCode({ name: POSCode, service_place_name: POSName, description: POSDescr, effective_date_string: POSDateString });
                }
                else {
                    result = service.AddPOSCode({ name: POSCode, service_place_name: POSName, description: POSDescr, effective_date: format(POSDate, 'yyyy-MM-dd') });
                }
            }

            result.then(response => {
                setShowLoadingBar(false);
                if (response.status == 201 || response.status == 200) {
                    if (response.status == 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else showNotifyWindow('show', 'success', UPDATE_SUCCESS);

                    getPOSCodesData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);

                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }

            });
    }

    function onHandleChange(e) {
        const { name, value, validity } = e.target;
        
        if (name === "POSCode" && !validity.valid) {
          return;
        }
        
        const trimmedValue = value.trim();

      
        setFormError((prevErrors) => ({
          ...prevErrors,
          [name]: trimmedValue ? "" : "error",
        }));

        /**used switch case to aviod else if  */
        switch (name) {
          case "POSCode":
            setPOSCode(trimmedValue);
            break;
          case "POSName":
            setPOSName(trimmedValue);
            break;
            case "description":
            setPOSDescr(value);
            break;
          case "POSDateString":
            setPOSDateString(trimmedValue);
            break;
          case "searchValue":
            setSearchValue(trimmedValue);
            break;
          default:
            break;
        }
      }
      

    function onChangeOption(fld, fldValue) {
        setPOSDateType(fldValue);
    }

    function onHandleDateChange(selected) {
        setPOSDate(selected);
    }

    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById('file').click();
    }

    function onHandleCSVChange(e) {
        const value =
            e.target.type === "file" ? e.target.files[0] : e.target.value;

        if (e.target.type === "file") {
            if (e.target.files[0].name !== POS_CSV_NAME) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_name'));
            } else if (csvFileCheck(e.target.files[0].name)) {
                const data = new FormData();
                data.append('file', value);
                let result = service.ImportCSVData(data);
                result.then(response => {
                    setShowLoadingBar(false);
                    if (response.data.message !== undefined) {
                        if (response.data.message === 'no_file_records') {
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.empty_file'));
                        } else if (response.data.message === 'invalid_headings') {
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_headings'));
                        } else if (response.data.message === 'data_imported') {
                            if (response.data.existing_name && response.data.existing_name.length > 0) {
                                showNotifyWindow('show', 'warning', i18n.t('errorMessages.record_not_imported') + ' \n ' + response.data.existing_name.join(',\n'));
                            } else {
                                showNotifyWindow('show', 'success', i18n.t('validations.success.import_success'));
                            }
                            getPOSCodesData(DEFAULT_PAGING_SIZE, activePage);
                        }
                    } else {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
                    }
                });
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_format'));
            }
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearchPOSCodes(e);
        }
    }

    function onSearchPOSCodes(e, fieldName) {
        e.preventDefault()
        setActivePage(1)
        if (!fieldName) { // if not field name then call from ,search button click
            setOrderType('');
            setInitialOrderingCode(true);
            SetInitialOrderingServiceName(true);
        }
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        setTotalPage(0);
        getPOSCodesData(DEFAULT_PAGING_SIZE, 1, fieldName, true,searchValue)
    }

    function onSortingPOSCodes(e, fieldName) {
        e.preventDefault()
        if (!fieldName) { 
            setOrderType('');
            setInitialOrderingCode(true);
            SetInitialOrderingServiceName(true);
        }
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        setTotalPage(0);
        getPOSCodesData(DEFAULT_PAGING_SIZE, activePage, fieldName, true,searchValue)
    }

    const tableSorting = (e, fieldName) => {
        let name = i18n.t("dictionariesPages.dictPOSCodes.labelPOSCode") ? 'name' : 'service_place_name';
        if (fieldName == "Place of Service Name ") {
            setInitialOrderingCode(true);
            SetInitialOrderingServiceName(false);
        }
        else {
            setInitialOrderingCode(false);
            SetInitialOrderingServiceName(true)
        }
        setOrderingField(name);
        if (!orderType) setOrderType('-');
        else setOrderType('');
        onSortingPOSCodes(e, name);
    }

    function dropDownFunction(id, name) {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        if (name.toLowerCase() === 'edit') {
            onEditPOSCode(Number(id));
        }
        if (name.toLowerCase() === 'delete') {
            onDeletePOSCode(Number(id));
        }
    }

    function onHandleClick () {
        setSearchValue('');
        setActivePage(1)
        getPOSCodesData(DEFAULT_PAGING_SIZE, activePage);
    }

    function backToDictionaryPage() {
		props.history.push(ROUTE_SUPER_DICTIONARIES)
	}

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">

                    

                <div className="box box-content-white">

                    <div className="row div-backto-dictionaties">
                        <div className="link dictionaries-back" onClick={backToDictionaryPage} >
                            <BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} />
                        </div>
                    </div>
                    <div className="row px-15px">
                        <div className="patient_search width-100-perc">
                            <div className="input-responsible">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" name="searchValue" value={searchValue} onValueChange={onHandleChange} label={i18n.t("dictionariesPages.dictPOSCodes.posOrServiceName")} onKeyDown={handleKeyDown} />
                                    {searchValue.length !== 0 && <IconButton
										className="templateIconBtn_close"
										onClick={onHandleClick} >
										<CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
									</IconButton>}
                                </div>
                            </div>
                            <div className="padding-top25">
                                <div className="input-responsible padding-top15">
                                    <button type="button" onClick={(e) => onSearchPOSCodes(e)} className="btn btn-primary-blue">{i18n.t("dictionariesPages.dictPOSCodes.search")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="box box-content-white margin-top10">
                    <div className="width-100-perc">
                        <div className="alignRight ">
                            <div className="alignRight padding-top5 padding-bottom10 width-100-perc ">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="download-tooltip" style={{
                                        opacity: 0.4,
                                    }}>{i18n.t('commons.importCSV')}
                                    </Tooltip>}
                                >
                                    <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  />
                                </OverlayTrigger>
                                <input name="csvFile" type="file" accept=".csv" id="file" onChange={onHandleCSVChange} style={{ display: "none" }} /> 
                               <span className='ml-3'> <CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")}/></span>
                            </div> 
                        </div>
                    </div>  
                    <div className="width-100-perc">
                        <div className='margin-bottom10' style={{ overflowX: "auto", width: "100%", maxWidth: (innerWidth - 300) + "px" }}>
                            <Table tableObject={PosCodesTableData} list={arrPOSCodesList} dropDownFunction={dropDownFunction} sortingFunction={tableSorting} />
                        
                        </div>
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={modalHeader} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data" autoComplete="off"  >
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">

                                <div className="form-group padding-top15">
                                    <TextInput type="text" pattern="[0-9]*" id="POSCode" name="POSCode" required={true} label={i18n.t('dictionariesPages.dictPOSCodes.labelPOSCode')} onValueChange={onHandleChange} className={form_error.POSCode ? 'input-error' : ""} value={POSCode} />
                                </div>

                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="POSName" name="POSName" required={true} label={i18n.t('dictionariesPages.dictPOSCodes.labelPOSName')} onValueChange={onHandleChange} className={form_error.POSName ? 'input-error' : ""} value={POSName} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor='desc-text-area'>{i18n.t('dictionariesPages.dictAdjustmentReasonCode.lblDescription')}</label>
                                    <div className={'text-input'}>
                                        <textarea id='desc-text-area' placeholder={i18n.t('dictionariesPages.dictAdjustmentReasonCode.lblDescription')} name="description" required={true} className={!POSDescr ? 'textarea-100 input-error' : "textarea-100"} value={POSDescr} onChange={(e) => onHandleChange(e)}></textarea>
                                    </div>
                                </div>
                                <div className="checkbox-wrapper">
                                    <div className="custom-radio padding-right10">{i18n.t('dictionariesPages.dictPOSCodes.labelPOSEffectiveDate')}</div>
                                    <div className="custom-radio">
                                        <input type="radio" id="radio1" name="radio" defaultChecked="checked" onChange={(e) => onChangeOption(e, 'date')} checked={POSDateType == 'date' ? true : false} />
                                        <label className="radio" htmlFor="radio1"></label>
                                        <label className="labeltext" htmlFor="radio1">{i18n.t('dictionariesPages.dictPOSCodes.labelPOSDateTypeDate')}</label>
                                    </div>
                                    <div className="custom-radio">
                                        <input type="radio" id="radio2" name="radio" onChange={(e) => onChangeOption(e, 'string')} checked={POSDateType == 'string' ? true : false} />
                                        <label className="radio" htmlFor="radio2"></label>
                                        <label className="labeltext" htmlFor="radio2">{i18n.t('dictionariesPages.dictPOSCodes.labelPOSDateTypeText')}</label>
                                    </div>
                                    <div className="padding-left">
                                        {POSDateType.length > 0 && POSDateType === 'date' &&
                                            <CalendarInput placeholder="Date" selected={POSDate} required={true} onValueChange={selected => onHandleDateChange(selected)} name="POSDate" className={form_error.POSDate.length > 0 ? "input-error" : ''} />}
                                        {POSDateType.length > 0 && POSDateType === 'string' && <input type="text" data-testid="POSDateString" className={POSDateString ? "" : "input-error"} id="POSDateString" name="POSDateString" placeholder="" onChange={selDate => onHandleChange(selDate)} value={POSDateString} required={true} />}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t('commons.confirmDelete')} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t('dictionariesPages.dictPOSCodes.deletConfirmation') + " '" + deletePOScode + "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>

    )
}

export default POSCodesList;