import React, { useContext, useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import LoadingContext from "../../../container/loadingContext";

import i18n from "../../../utilities/i18n";
import { getStorage } from "../../../utilities/browserStorage";
import { PRINT_SELECTED_OPTION } from "../../../utilities/dictionaryConstants";
import { DEFAULT_PAGING_SIZE, PAGING_END_INDEX } from "../../../utilities/staticConfigs";

import Notify from "../../commons/notify";
import CommonButton from '../../commons/Buttons';
import TextInput from "../../commons/input/input";
import Pagination from "../../commons/pagination";
import RangeInput from "../../commons/input/RangeInput";
import SelectInput from "../../commons/input/SelectInput";
import CalendarInput from "../../commons/input/CalendarInput";
import RightSidePanel from "../../commons/RightSidePanel/RightSidePanel";
import { ResizableTable } from "../../commons/resizabletable/ResizableTable";

import service from "../patients/service";
import PatientDropDownSearch from "../patients/patientDropDownSearch";
import PatientsAdvancedSearch from "../patients/PatientsAdvancedSearch";

import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";

import { getTableData, setStateData, getDropDownListData, onTab1StateReset, setPatientPKData } from "./StatementManagmentRedux/statementManagementSlice";

function PatientsStatementManagement(props) {
  const dispatch = useDispatch();
  const PSMData = useSelector(
    (state) => state.statementMgData.tab1StatementManagment
  );

  const StatementQueryData = useSelector((state) => state.statementMgData.commons.statementQueries);
  const selectedEntries = useSelector((state) => state.statementMgData.tab1StatementManagment.selectedEntries);
  const loading = useSelector(
    (state) => state.statementMgData.loading
  );
  const { dosFrom, dosTo, claimEnteredFrom, claimEnteredTo, balanceType, selectInsurance,
    noStatementSent, lastStatement, blnce1, blnce2, selectProvider, selectFacility, serviceLocationType,
    serviceLocation, clmStatus, billPR, printSelectedOption, patientSelectedArray,patient_pk } = useSelector(
      (state) => state.statementMgData?.tab1StatementManagment?.statementManagementState
    );

  const resetflagtable =useSelector((state) => state.statementMgData.resetFlag)

  const { isLoading,
    isFilterDropDownDataReceived,
    InsuranceNameList,
    ProviderList,
    FacilityList,
    BillPRList,
    ClaimStatusList } = useSelector((state) => state.statementMgData?.dropDownListData)

  const [showNotify, setShowNotify] = useState('hide');
  const [notifyDescription, setNotifyDescription] = useState('');
  const [notifyType, setNotifyType] = useState('success');
  const [initialTableData, setInitialTableData] = useState(
    i18n.t("commons.noRecords")
  );
  const [patientPK, setPatientPK] = useState(patient_pk);
  const [balanceType1, setbalanceType] = useState("");
  const balanceTypeList = [
    { id: 1, name: 'All Balance', alert: '' },
    { id: 2, name: 'Patient Balance', alert: '' },
    { id: 3, name: 'Insurance Balance', alert: '' },
    { id: 4, name: 'All Charges Including Zero Balance', alert: '' },
  ];

  const practicePK = getStorage("practice");
  const [patientSelected, setPatientSelected] = useState([]);
  const patientsStatement = useSelector((state) => state.statementMgData?.tab1StatementManagment?.tableData);
  const printSelectedOptionList = PRINT_SELECTED_OPTION;
  const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
  const [statementQuery, setStatementQuery] = useState("");
  const [patientDob, setPatientDob] = useState("");

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  // eslint-disable-next-line no-undef
  let fileDownload = require('js-file-download');


//pagination
  const [totalPage, setTotalPage] = useState(1);
  const [activePages, setActivePages] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePages(previousPage);
    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getPatientStatement(DEFAULT_PAGING_SIZE,previousPage)
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePages(page);
    getPatientStatement(DEFAULT_PAGING_SIZE,page)
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePages(nextPage);
      setStartIndex(startIndex);
      setEndIndex(endIndex);
    } else {
      setActivePages(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);

    }
    getPatientStatement(DEFAULT_PAGING_SIZE,nextPage)
  }
 

  useEffect(() => {
    if (patientSelected.length > 0) {
      setPatientDob(patientSelected[0]?.dob)
      dispatch(setStateData({ value: patientSelected, node: "patientSelectedArray" }));
      dispatch(setPatientPKData({ value: patientSelected[0].id, node: "patient_pk" }));
    }
    else {
      dispatch(setStateData({ value: [], node: "patientSelectedArray" }));
      dispatch(setPatientPKData({ value:"", node: "patient_pk" }));
    }
  }, [patientSelected])

  useEffect(() => {
    if (patientSelectedArray.length > 0) {
      setPatientAdvSearchData(patientSelectedArray)
    }
  }, [])

useEffect(() => {
  if(resetflagtable){
    getPatientStatement()
  }
},[resetflagtable])


  useEffect(() => {
    if (balanceType1) {
      dispatch(setStateData({ value: balanceType1, node: "balanceType" }));
    }
  }, [balanceType1])

  useEffect(() => {
    let newWidth = "";
    newWidth = window.innerWidth;
    let menuWidth = document
      .getElementById("push-bar")
      .classList.contains("open");
    if (!menuWidth) {
      newWidth = newWidth + 250;
    } else {
      newWidth = window.innerWidth;
    }
    setInnerWidth(newWidth);
  }, [window.innerWidth]);


  function onHandleDosFirst(selected) {
    dispatch(setStateData({ value: selected, node: "dosFrom" }));
  }
  function onHandleDosSecond(selected) {
    dispatch(setStateData({ value: selected, node: "dosTo" }))
  }
  function onHandleClaimDate(selected) {
    dispatch(setStateData({ value: selected, node: "claimEnteredFrom" }))
  }
  function onHandleClaimDate2(selected) {
    dispatch(setStateData({ value: selected, node: "claimEnteredTo" }))
  }
  function onHandleChange(e) {
    if (e.target) {
      const name = e.target.name;
      let value = "";
      if (name === "noStatementSent" || name === "lastStatement") {
        value = String(e.target.value.trim())
          .replace(/[^\d]/g, "")
          .substr(0, 15);
      }
      else if (name === 'blnce1' || name === 'blnce2') {
        value = e.target.value.trim()
          .replace(/[^0-9.]/g, "")
          .replace(/\.{2,}/g, '.')
          .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1")
      }
      else {
        value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
      }
      let trimmedValue = "";
      let inputFields = [
        "dosFrom",
        "dosTo",
        "claimEnteredFrom",
        "claimEnteredTo",
        "balanceType",
        "selectInsurance",
        "noStatementSent",
        "lastStatement",
        "blnce1",
        "blnce2",
        "selectProvider",
        "selectFacility",
        "clmStatus",
        "printSelectedOption",
      ];
      if (inputFields.includes(name)) {
        trimmedValue = value;
      }
      if (name === "balanceType") dispatch(setStateData({ value: trimmedValue, node: name }));
      else if (name === "noStatementSent") dispatch(setStateData({ value: trimmedValue, node: name }));
      else if (name === "lastStatement") dispatch(setStateData({ value: trimmedValue, node: name }));
      else if (name === "blnce1") dispatch(setStateData({ value: trimmedValue, node: name }));
      else if (name === "blnce2") dispatch(setStateData({ value: trimmedValue, node: name }));
      else if (name === "printSelectedOption")
        dispatch(setStateData({ value: trimmedValue, node: name }));
    } else {
      if (e[0]) {
        setPatientPK(e[0].id);
      } else if (!e[0]) {
        setPatientPK("");
      }
    }

  }
  const activePage = 1
  const setShowLoadingBar = useContext(LoadingContext);


  // Dispatch Redux function to get the drop-down list data if it not there already in the state
  useEffect(() => {
    if (!isFilterDropDownDataReceived)
      dispatch(getDropDownListData())
  }, []);

  // Display error notification if any error occurred while fetching filter dropdown list data
  useEffect(() => {
    if (isLoading === true)
      setShowLoadingBar(true)
    else setShowLoadingBar(false)
  }, [isLoading])

  useEffect(() => {
    if (!loading) {   //PSM-> patientStatementData
      setInitialTableData(i18n.t("commons.noRecords"));
      setTotalPage(Math.ceil(PSMData.count / PSMData.pageSize))
      setActivePages(PSMData.currentPage)
      setShowLoadingBar(false);
    }
  }, [loading])

  function OnExportClicked(e, type,page) {
    let query = statementQuery;
    let pageSize = 20;
    query += '&page=0&export=true&export_type=' + type;
    service.ListPatientsStatements(query,pageSize,type,practicePK,page).then((result) => {
      if (result.status === 200 || result.status === 201) {
        fileDownload(result.data, result.headers['content-disposition']);
        setShowLoadingBar(false);
        showNotifyWindow('show', 'success', i18n.t('commons.successExportCsv'));
      } else {
        showNotifyWindow('show', 'error', result.statusText);
      }
    })
      .catch(() => {
        showNotifyWindow('show', 'error', 'Unexpected Error');
        setShowLoadingBar(false);
      });
  }

  function getPatientStatement(pageSize, page) {
    if (blnce1 !== '' && blnce2 !== '' && parseFloat(blnce1) > parseFloat(blnce2)) {
      return;
    } 
    setShowLoadingBar(true);
    let query = "";
    if(page){
      query += "&page=" + page;
    }
    if (patientPK) {
      query += "&patient_pk=" + patientPK;
    }
    if (dosFrom) {
      let dos_from = dosFrom && !isNaN(Date.parse(dosFrom)) ? format(dosFrom, "yyyy-MM-dd") : "";
      query += "&dos_from=" + dos_from;
    }
    if (dosTo) {
      let dos_to = dosTo && !isNaN(Date.parse(dosTo)) ? format(dosTo, "yyyy-MM-dd") : "";
      query += "&dos_to=" + dos_to;
    }
    if (claimEnteredFrom) {
      let claim_entered_from = claimEnteredFrom && !isNaN(Date.parse(claimEnteredFrom))
        ? format(claimEnteredFrom, "yyyy-MM-dd")
        : "";
      query += "&entered_from=" + claim_entered_from;
    }
    if (claimEnteredTo) {
      let claim_entered_to = claimEnteredTo && !isNaN(Date.parse(claimEnteredTo))
        ? format(claimEnteredTo, "yyyy-MM-dd")
        : "";
      query += "&entered_to=" + claim_entered_to;
    }
    if (balanceType) query += "&balance_type=" + balanceType;
    if (selectInsurance) query += "&insurance=" + selectInsurance;
    if (noStatementSent) query += "&no_of_statement=" + noStatementSent;
    if (lastStatement) query += "&since_last_statement=" + lastStatement;
    if (blnce1) query += "&claim_balance_from=" + blnce1;
    if (blnce2) query += "&claim_balance_to=" + blnce2;
    if (selectProvider) query += "&provider=" + selectProvider;
    if (selectFacility) {
      query += "&facility=" + serviceLocation;
      query += "&facility_type=" + serviceLocationType;
    }
    if (billPR) query += "&bill_pr_code=" + billPR;
    if (clmStatus) query += "&claim_status=" + clmStatus;

    const filterDatas = {
      dos_from: dosFrom,
      dos_to: dosTo,
      claim_entered_from: claimEnteredFrom,
      claim_entered_to: claimEnteredTo,
      balance_type: balanceType,
      select_insurance: selectInsurance,
      no_of_statement_sent: noStatementSent,
      last_statement: lastStatement,
      balance_1: blnce1,
      balance_2: blnce2,
      select_provider: selectProvider,
      select_facility: selectFacility,
      bill_pr: billPR,
      claim_status: clmStatus
    }

    let data = {
      query: query,
      pageSize: pageSize ?? DEFAULT_PAGING_SIZE,
      page: page,
      practicePK: practicePK,
      filterData: filterDatas
    }
    dispatch(getTableData(data));
    setStatementQuery(query);
  }

  function onPrintSelected() {
    setShowLoadingBar(true);
    const result = service.PrintPatientStatement(
      selectedEntries,
      printSelectedOption,
      practicePK
    );
    result.then((response) => {
      if (response.data) {
        fileDownload(response.data, response.headers['content-disposition']);
        getPatientStatement(DEFAULT_PAGING_SIZE, activePage)
        setShowLoadingBar(false);
      } else {
        setShowLoadingBar(false);
      }
    });
  }

  function onSendSelected() {
    setShowLoadingBar(true);
    let data = {
      patient_pks: selectedEntries
    };
    const result = service.sendPatientStatement(data);
    result.then(response => {
      if (response.status === 200) {
        if (response.data.message === "successfully_uploaded_file_to_sftp") {
          showNotifyWindow('show', 'success', i18n.t('commons.successfully_uploaded_file_to_sftp'));
        }
        setShowLoadingBar(false)
        getPatientStatement(DEFAULT_PAGING_SIZE, activePage)
      }
      else {
        if (response.status === 500) {
          if (response?.data?.message === "sftp_credentials_not_updated") {
            showNotifyWindow('show', 'error', i18n.t('commons.sftp_credentials_not_updated') );
          }
          else {
            showNotifyWindow('show', 'error',"Unexpected error occured");
          }
        }
        setShowLoadingBar(false)
      }
    })
  }

  useEffect(() => {
    if (!PSMData?.tableData?.length) {
      getPatientStatement(DEFAULT_PAGING_SIZE, activePage)
    }
  }, [])


  const handleCheckboxItemChange = (eventItem) => {
    // Mark the request item checked or unchecked in the table data state
    const updatedPatientsStatement = patientsStatement.map((item) =>
      item.id === eventItem ? { ...item, isChecked: !item.isChecked } : item
    );

    dispatch({
      type: 'statementMgmnt/updateFieldValues',
      payload: {
        state: 'tab1StatementManagment',
        field: 'tableData',
        value: updatedPatientsStatement,
      },
    });

    // Get the current state of selectedEntries from redux and make the update in the same
    // This state is used to reinstate the checked state on page change
    const currentSelectedEntries = PSMData.selectedEntries;
    const updatedSelectedEntries = currentSelectedEntries.includes(eventItem)
      ? currentSelectedEntries.filter(item => item !== eventItem) // Remove eventItem
      : currentSelectedEntries.concat(eventItem); // Add eventItem

    dispatch({
      type: 'statementMgmnt/updateFieldValues',
      payload: {
        state: 'tab1StatementManagment',
        field: 'selectedEntries',
        value: updatedSelectedEntries,
      },
    });
  }

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    let newCheckedPatientsList;

    if (isChecked) {
      // Add all patients to selectedEntries, retaining existing items from other pages if there's any
      newCheckedPatientsList = [...new Set([...selectedEntries, ...patientsStatement.map(patient => patient.id)])];
    } else {
      // Remove all patients from selectedEntries
      newCheckedPatientsList = selectedEntries.filter(id => !patientsStatement.some(patient => patient.id === id));
    }

    dispatch({
      type: 'statementMgmnt/updateFieldValues',
      payload: {
        state: 'tab1StatementManagment',
        field: 'selectedEntries',
        value: newCheckedPatientsList,
      },
    });

    // Mark the request item checked or unchecked in the table data state
    const updatedPatientsStatement = patientsStatement.map((item) => ({ ...item, isChecked: isChecked }));

    dispatch({
      type: 'statementMgmnt/updateFieldValues',
      payload: {
        state: 'tab1StatementManagment',
        field: 'tableData',
        value: updatedPatientsStatement,
      },
    });
  }

  function MaterialMultiSelectHandle(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "selectInsurance") {
      dispatch(setStateData({ value: value, node: name }));
    } else if (name === "selectProvider") {
      dispatch(setStateData({ value: value, node: name }));
    } else if (name === "selectFacility") {
      dispatch(setStateData({ value: value, node: name }));
      let service_locations = [];
      let service_location_types = [];
      if (value.length > 0) {
        value.forEach((item) => {
          service_locations.push(item.split("||")[0]);
          service_location_types.push(item.split("||")[1]);
        });
      }
      dispatch(setStateData({ value: service_locations, node: "serviceLocation" }));
      dispatch(setStateData({ value: service_location_types, node: "serviceLocationType" }));
    } else if (name === "clmStatus") {
      dispatch(setStateData({ value: value, node: name }));
    } else if (name === "billPR") {
      dispatch(setStateData({ value: value, node: name }));
    }
  }

  function getPatientPrevData() {
    let data = []
    patientsStatement.filter(item => {
      if (selectedEntries.includes(item.id)) {
        data.push(item)
      }
    })
    props.OnPreview(data, statementQuery)
  }

  function onResetFilter() {
    dispatch(onTab1StateReset())
    setbalanceType("")
    setPatientSelected([])
    setPatientPK("")
  }

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == 'show') {
      setTimeout(() => {
        setShowNotify('hide');
      }, age)
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }
  function remShowExport() {
    document.getElementById("export-dropdown").classList.remove("show");
    document.getElementById("export-menu").classList.remove("show");
    document.getElementById("export-dropdown").classList.remove("active");
    document.getElementById("export-menu").classList.remove("active");
  }

  function onShowExport() {
    document.getElementById("export-dropdown").classList.toggle("show");
    document.getElementById("export-menu").classList.toggle("show");
  }


  return (
    <div>
      <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
      <Form autoComplete="off" className="searchBox pl-4 pb-5 pr-4">
        <div className="row padding-bottom10 ">
          <PatientDropDownSearch
            setPatientPK={setPatientPK}
            patientSelected={patientSelected}
            setPatientSelected={setPatientSelected}
            patientAdvSearchData={patientAdvSearchData} 
            dateFieldClass={"margin-right20"} 
          ></PatientDropDownSearch>
          <div className="col justify-right">
            <div className="input-responsible padding-top22 justify-right">
              <RightSidePanel
                title={i18n.t("commons.advancedSearch")}
                onclickLabel={i18n.t("commons.advancedSearch")}
              >
                <PatientsAdvancedSearch
                  setPatientPK={setPatientPK}
                  setPatientSelected={setPatientSelected}
                  setPatientAdvSearchData={setPatientAdvSearchData}
                ></PatientsAdvancedSearch>
              </RightSidePanel>
            </div>
          </div>
        </div>

        <div className="row padding-top12">
          <div className="col-3">
            <CalendarInput
              label={i18n.t("statementManagement.dosFrom")}
              name="dosFrom"
              selected={dosFrom}
              minDate={patientDob ? patientDob : undefined}
              onValueChange={(selected) => onHandleDosFirst(selected)}
            />
          </div>
          <div className="col-3">
            <CalendarInput
              label={i18n.t("statementManagement.dosTo")}
              name="dosTo"
              selected={dosTo}
              minDate={patientDob ? new Date(patientDob) : (dosFrom ? new Date(dosFrom) : undefined)}
              onValueChange={(selected) => onHandleDosSecond(selected)}
            />
          </div>
          <div className="col-3">
            <CalendarInput
              label={i18n.t("statementManagement.claimFrom")}
              name="claimEnteredFrom"
              selected={claimEnteredFrom}
              minDate={patientDob ? patientDob : undefined}
              onValueChange={(selected) => onHandleClaimDate(selected)}
            />
          </div>
          <div className="col-3">
            <CalendarInput
              label={i18n.t("statementManagement.claimTo")}
              name="claimEnteredTo"
              selected={claimEnteredTo}
              minDate={patientDob ? new Date(patientDob) : (claimEnteredFrom ? new Date(claimEnteredFrom) : undefined)}
              onValueChange={(selected) => onHandleClaimDate2(selected)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <SelectInput
              data={balanceTypeList}
              name="balanceType"
              value={balanceType}
              setValue={setbalanceType}
              onValueChange={onHandleChange}
              label={i18n.t("statementManagement.balanceType")}
            />
          </div>
          <div className="col-3">
            <MaterialMultiselect
              name="selectInsurance"
              value={selectInsurance}
              onValueChange={MaterialMultiSelectHandle}
              options={InsuranceNameList}
              label={i18n.t("statementManagement.selectInsurance")}
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name="noStatementSent"
              label={i18n.t("statementManagement.noStatement")}
              onValueChange={onHandleChange}
              value={noStatementSent}
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name="lastStatement"
              label={i18n.t("statementManagement.lastStatement")}
              onValueChange={onHandleChange}
              value={lastStatement}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 padding-top7">
            <RangeInput
              name1="blnce1"
              name2="blnce2"
              value1={blnce1}
              value2={blnce2}
              onValueChange1={onHandleChange}
              onValueChange2={onHandleChange}
              label={i18n.t("statementManagement.claimBlnce")}
              ValidationMsg={(i18n.t("commons.balanceError"))}
            />
          </div>
          <div className="col-3 padding-top7">
            <MaterialMultiselect
              name="selectProvider"
              value={selectProvider}
              onValueChange={MaterialMultiSelectHandle}
              options={ProviderList}
              label={i18n.t("statementManagement.seleProvider")}
            />
          </div>
          <div className="col-3 padding-top7">
            <MaterialMultiselect
              name="selectFacility"
              value={selectFacility}
              onValueChange={MaterialMultiSelectHandle}
              options={FacilityList}
              label={i18n.t("statementManagement.selFacility")}
            />
          </div>
          <div className="col-3 padding-top7">
            <MaterialMultiselect
              name="billPR"
              value={billPR}
              onValueChange={MaterialMultiSelectHandle}
              options={BillPRList}
              label={i18n.t("statementManagement.billPR")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 padding-top7">
            <MaterialMultiselect
              name="clmStatus"
              value={clmStatus}
              onValueChange={MaterialMultiSelectHandle}
              options={ClaimStatusList}
              label={i18n.t("statementManagement.clmStatus")}
            />
          </div>
          <div className="col-9 div-float-right">
            <div className="form-group padding-top25">
              <div className="div-float-right"> 
                <span className="margin-right15" >  
                  <CommonButton 
                    onClick={onResetFilter} variant="outlined" label={i18n.t("claimsFilter.reset")}
                  />
                </span>

                <CommonButton 
                  onClick={() =>
                    getPatientStatement(DEFAULT_PAGING_SIZE, activePage)
                  } 
                  variant="contained"
                  label={i18n.t("statementManagement.runStatement")}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Form autoComplete="off">
        <div className="form-group padding-top20 d-flex align-items-center justify-content-end">
          <div className="div-float-right margin-right23 margin-bottom12">
            <button type="button" className="btn btn-primary-blue mt-3"
              onClick={getPatientPrevData} disabled={selectedEntries.length <= 0}
            >
              {i18n.t("statementManagement.previewAll")}
            </button>
          </div>
          <div className="div-float-right padding-right20 dropdown" id="export-dropdown">
            {

              PSMData.tableData.length ?
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="download-tooltip"
                      style={{
                        opacity: 0.4,
                      }}
                    >
                      {i18n.t("statementManagement.export")
                      }
                    </Tooltip>
                  }
                >
                  {/* <button
                className="header-btn border-none bg-white p-0"
                type="button"
                data-testid="export-dropdown-item"
                data-toggle="dropdown"
                onBlur={remShowExport}
                onClick={onShowExport}
              >
                <i className="las la-file-upload navBarIcons "></i>
              </button> */}
                  <CommonButton variant="outlined" label="Download" icon={'download'}
                    onClick={onShowExport} onBlur={remShowExport} />
                </OverlayTrigger> : <>
                  <CommonButton variant="outlined" label="Download" icon={'download'}
                    disabled={true} />
                  {/* <span className="btn  importBtn disabled">
                <i className="las la-file-upload navBarIcons"></i>
              </span> */}
                </>}

            <div>
              <ul style={{ marginRight: '16px' }}
                className="patientList dropdown-menu download-dropdown"
                id="export-menu"
              >
                <li className="download-dropdown-menu-item"
                  id="download-dropdown-menu-item-csv"
                  onMouseDown={(e) => OnExportClicked(e, 'csv')}
                >
                  <Link to="#" className="text-dec-none" data-testid="download-dropdown-menu-item-csv">
                    {i18n.t("commons.exportCSV")}
                  </Link>
                </li>
                <li
                  className="download-dropdown-menu-item"
                  onMouseDown={(e) => OnExportClicked(e, 'xls')}
                >
                  <Link to="#" className="text-dec-none" data-testid="download-dropdown-menu-item-xls">
                    {i18n.t("commons.exportXLS")}
                  </Link>
                </li>
              </ul>
            </div>

          </div>
          <div className="div-float-right margin-top-n16 col-3">
            <div className="mt-2">
              <SelectInput
                name="printSelectedOption"
                data={printSelectedOptionList}
                value={printSelectedOption}
                onValueChange={onHandleChange}
                label={i18n.t("statementManagement.noStatement")}
                selectOptionRemove={true}
              />
            </div>
          </div>
          <div className="div-float-right margin-top3 mr-4">
            <button type="button" onClick={onSendSelected} className="btn btn-primary-blue" disabled={selectedEntries.length <= 0} >
              {i18n.t("statementManagement.sndSelected")}
            </button>
          </div>
          <div className="div-float-right padding-right20  margin-top4">
            <button
              type="button"
              className="btn btn-primary-blue"
              onClick={onPrintSelected}
              disabled={selectedEntries.length <= 0}
            >
              {i18n.t("statementManagement.prntSelected")}
            </button>
          </div>
        </div>

        <div className="form-group padding-top20">
          <div
            style={{
              overflowX: "auto",
              width: "100%",
              maxWidth: innerWidth - 300 + "px",
              maxHeight: "500px",
            }}
          >
            <ResizableTable
              className="fixed_head "
              id={"overflow_auto"}
              tableHeadings={
                <tr>
                  <th className="max-width_21percent">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="gridCheckBox-select-all"
                        id="gridCheckBox-select-all"
                        checked={
                          (patientsStatement?.length && selectedEntries.length) ?
                            patientsStatement.every(patient => selectedEntries.includes(patient.id))
                            : false
                        }
                        onChange={(e) => { handleCheckAll(e) }}
                        disabled={false} />
                      <label className="checkbox" htmlFor="gridCheckBox-select-all">
                      </label>
                    </div>
                    <span className="resize-handle"></span>
                  </th>
                  <th className="longTableHead textLeft">
                    {i18n.t("statementManagement.guarntorName")}
                    <span className="resize-handle"></span>
                  </th>
                  <th className="longTableHead textLeft">
                    {i18n.t("statementManagement.patientName")}
                    <span className="resize-handle"></span>
                  </th>
                  <th className="shortTableHead textLeft">
                    {i18n.t("statementManagement.patientId")}
                    <span className="resize-handle"></span>
                  </th>
                  <th className="shortTableHead textLeft">
                    {i18n.t("statementManagement.statementBlnc")}
                    <span className="resize-handle"></span>
                  </th>
                  <th className="shortTableHead textLeft">
                    {i18n.t("statementManagement.statementSent")}
                    <span className="resize-handle"></span>
                  </th>
                  <th className="shortTableHead">
                    {i18n.t("statementManagement.preview")}
                    <span className="resize-handle"></span>
                  </th>
                  
                </tr>
              }
              tableRows={
                patientsStatement.length === 0 ? (
                  <tr>
                    <td align="center" colSpan={8}>
                      {initialTableData}
                    </td>
                  </tr>
                ) : (
                    patientsStatement.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="width-125px" align="center">
                          <div className="custom-checkbox lh-1" style={{ top: "-3px" }}>
                            <input
                              type="checkbox"
                              id={`chkPatient-${item.id}`}
                              checked={item.isChecked}
                              name="chkPatient"
                              onChange={() => handleCheckboxItemChange(item.id)}
                              className={`checkbox-${selectedEntries.indexOf(item.id) === -1 ? false : true}`}
                            />
                            <label className="checkbox" htmlFor={`chkPatient-${item.id}`}>
                              {''}
                            </label>
                          </div>
                        </td>
                        <td>{item.guarantor_name}</td>
                        <td>{item.patient_name}</td>
                        <td className="width-125px" align="center">
                          {item.custom_patient_id}
                        </td>
                        <td className="width-125px" align="right">
                          {item.statement_balance
                            ? "$ " + Number(item.statement_balance).toFixed(2)
                            : "$ " + 0}
                        </td>
                        <td className="width-125px" align="center">
                          {item.no_of_statement_sent}
                        </td>
                        <td className="width-125px" align="center">
                          <Link
                            to="#"
                            className="button"
                            onClick={() => props.OnPreview([item], StatementQueryData)}
                          >
                            {i18n.t("statementManagement.view")}
                          </Link>
                        </td>
                        
                      </tr>
                    );
                  })
                )
              }
            />
          </div>
          <div className="mt-3 mb-2">
            <Pagination
              totalPage={totalPage}
              activePage={activePages}
              startIndex={startIndex}
              endIndex={endIndex}
              onPagePrevious={onPagePrevious}
              onPageUp={onPageUp}
              onPageNext={onPageNext}
            />
          </div>        
        </div>
      </Form>
    </div>
  );
}

export default PatientsStatementManagement;