import React,{useState, useEffect, useContext} from 'react';
import { Form} from 'react-bootstrap';
import { useLocation  } from 'react-router-dom';

import service from './service';
import { DocumentTypesTableObject, DocumentTypesTableBodyOnly } from './DocumentTypesTable';

import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission } from '../../../utilities/commonUtilities';
import { permission_key_values_practice_dictionaries, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';
import {ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS} from '../../../utilities/labelConfigs';
import {ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_DOCUMENT_TYPES} from '../../../utilities/staticConfigs';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table'; 
import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import BackArrowWithLabel from "../../commons/Back"; 

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import CommonButton from '../../commons/Buttons';

const DocumentList = (props) =>{
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [deleteDocumentName, setDeleteDocumentName] = useState('');
    const [deleteDocumentId, setDeleteDocumentId] = useState('');
    const [editDocumentId, setEditDocumentId] = useState('');
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [form_error, setFormError] = useState({'documentType':''});
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);

    //start ==== Alert message properties
    const [showNotify,setShowNotify] = useState('hide');
    const [notifyDescription,setNotifyDescription] = useState('');
    const [notifyType,setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action,type,desc,age=3000){
        if(action == 'show'){
            setTimeout(()=> {
                setShowNotify('hide');
            },age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
      let previousPage = startIndex+1-PAGING_END_INDEX;
      setActivePage(previousPage);

      if(startIndex!==0){
          setStartIndex(startIndex - PAGING_END_INDEX);
          setEndIndex(endIndex - PAGING_END_INDEX);
      }
      getDocumentData(DEFAULT_PAGING_SIZE,previousPage);          
    }

    function onPageUp(e){
        let page = Number(e.target.id)
        setActivePage(page);
        getDocumentData(DEFAULT_PAGING_SIZE,page); 
    }

    function onPageNext() {
        let nextPage = startIndex+1+PAGING_END_INDEX;
        if(endIndex===totalPage || totalPage<=PAGING_END_INDEX){
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        }else{
          setActivePage(nextPage);
          setStartIndex(startIndex + PAGING_END_INDEX);
          setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getDocumentData(DEFAULT_PAGING_SIZE,nextPage);
    }
    //Pagination ends

    function addNew(){
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.documentList.addHeader"));
        setShowModalWindow(true);
    }

    function onHide(){
        setEditForm(false);
    }

    function onDeleteHide(){
        setDeleteDocumentName('');
        setDeleteDocumentId('');
        setShowDeleteModalWindow(false);
    }

    function getDocumentData(pageSize, page){
        setShowLoadingBar(true);
        let result = service.ListDocumentTypes(pageSize,page, isAdminModule, isAdminModule === 'true' ? '' : practicePK)
       
        result.then(response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
            
            let anArray = [];
            let rowArray = [];
            let newData = Array(response.data.results.length).fill(JSON.parse(JSON.stringify(DocumentTypesTableObject.tableBodyData[0])));
            newData.map((row, rowIndex) => {
                row.map((col) => {
                    let colObject = {};
                        colObject = {
                            ...col, 'value': response.data.results[rowIndex][col.name],
                            "id": response.data.results[rowIndex].id,
                    }
                    anArray.push(colObject)
                })
                rowArray.push(anArray)
                anArray = [];
            });
            if(rowArray.length > 0) {
                DocumentTypesTableObject.tableBodyData = rowArray;
            }
            else {
                DocumentTypesTableObject.tableBodyData = DocumentTypesTableBodyOnly;
            }

            setDocumentTypeList(response.data.results);

        });
    }

    useEffect(() => {
        getDocumentData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_DOCUMENT_TYPES) {
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                super_admin_privileges.super_admin_full_privilege))
        }
    }, []);

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name.toLowerCase() == 'edit') {
            onEditDocumentType(Number(id));
        } else if (name.toLowerCase() == 'delete') {
            onDeleteDocumentType(Number(id));
        }
    }

    function onEditDocumentType(documentId){
        setHeader(i18n.t("dictionariesPages.documentList.editHeader"));
        setShowLoadingBar(true);
        let data = isAdminModule === 'true' ? service.GetDocumentType(documentId) : service.GetDocumentType(documentId, practicePK);
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setDocumentType(response.data.name);
            setEditForm(true);
            setEditDocumentId(documentId);
        });
    }

    function onDeleteDocumentType(documentId){
        documentTypeList.map((item) => {
            if(item.id == parseInt(documentId)){
                setDeleteDocumentName(item.name);
                setDeleteDocumentId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction(){
        setShowLoadingBar(true);
        let data =isAdminModule === 'true' ? service.DeleteDocumentType(deleteDocumentId) : service.DeleteDocumentType(deleteDocumentId, practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteDocumentName('');
            setDeleteDocumentId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show','success',DELETE_SUCCESS);
            getDocumentData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm(){
        setDocumentType('');
        setEditForm(false);
        setFormError({
              ...form_error,
              ['documentType']: ''
         });

    }
    function onSaveFormData(e){
        e.preventDefault();
        if(!documentType.trim()){
            setFormError({
              ...form_error,
              ['documentType']: 'error'
            });
        }else{
            setShowLoadingBar(true);
            let data = isAdminModule === 'true' ? {name: documentType} : {practice:practicePK, name: documentType};
            let result = editForm ? service.UpdateDocumentType(editDocumentId, data) : service.AddDocumentType(data);
            result.then(response => {
                setShowLoadingBar(false);
                if(response.status == 201 || response.status == 200){
                    if(response.status == 201)
                        showNotifyWindow('show','success',ADD_SUCCESS);
                    else
                        showNotifyWindow('show','success',UPDATE_SUCCESS);
                    getDocumentData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                }else if(response.status === 400 && response.data.record_already_exists !== undefined){
                    showNotifyWindow('show','error',i18n.t('errorMessages.record_exists'));
                }else{
                    showNotifyWindow('show','error',ADD_ERROR);
                }
            });
        }
    }
    function onHandleChange(e){
        const {name}  = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if(e.target.type==="text"){
            trimmedValue = value; 
        }
        if(!trimmedValue.trim()){
            setFormError({
              ...form_error,
              [name]: 'error'
            });
        }else{
            setFormError({
              ...form_error,
              [name]: ''
            });
        }
        setDocumentType(trimmedValue);
    }
 

    function backToDictionaryPage(){
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES)
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES)
    }
    
    return(
        <React.Fragment>
        <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
        <div className="col-md-8">
             <div className="box box-content-white">
                <div className="box-head pl-0 pr-0">
                    <div className={'dataTables_filter'}>
                        <div className="link dictionaries-back pl-0" onClick={backToDictionaryPage}>
                            <BackArrowWithLabel label={i18n.t("dictionariesPages.gotoDictionaries")} /> 
                        </div>
                     </div>
                     <div className="alignRight margin-bottom3">
                        <CommonButton  variant="contained" onClick={addNew} label={i18n.t("buttons.addNew")} />
                     </div>
                </div>
                <div className="box-content">
                    <div className="table-responsive">
                        <Table tableObject={DocumentTypesTableObject} dropDownFunction={dropDownFunction} />
                    </div>
                    <div className='mt-2 mb-2'>
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext}/>
                    </div>
                </div>
             </div>
        </div>
        <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
            <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                <Form.Group>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="documentType" name="documentType" label={i18n.t('dictionariesPages.documentList.lblDocumentType')} onValueChange={onHandleChange} className={form_error.documentType ? 'input-error' : ""} value={documentType} />
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </CustomizedDialogs>  
        <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
            {i18n.t("dictionariesPages.documentList.deletConfirmation")+"'"+deleteDocumentName+"'?"}
        </CustomizedSmallDialogs>  
        </React.Fragment>
    )
}
export default DocumentList