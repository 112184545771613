import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import service from "./service";
import { Redirect, useHistory, Link,useLocation } from "react-router-dom";
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ACTIVE_TYPES, ROUTE_PRACTICES_EDIT, ROUTE_PRACTICES_ADD } from "../../../utilities/staticConfigs";
import Pagination from "../../commons/pagination";
import LoadingContext from "../../../container/loadingContext";
import i18n from "../../../utilities/i18n";

import { getStorage } from "../../../utilities/browserStorage";
import SelectInput from "../../commons/input/SelectInput";
import Table from "../../commons/Table/Table";
import { PracticeTableData } from "./PracticeTable";
import { checkPermission, commonTableBody } from "../../../utilities/commonUtilities";
import ExportIconButton from "../../commons/exportIconButton";
import { permission_key_values_accountsetup } from "../../../utilities/permissions";
import Notify from "../../commons/notify";

const Practices = () => {
  const history = useHistory();
  const location=useLocation();
  const practicePK = getStorage("practice");
  const isAdminModule = getStorage("isAdminModule");
  const [practiceList, setPracticeList] = useState([]);

  //start ==== Alert message properties
  const setShowLoadingBar = useContext(LoadingContext);
  const [gotoAdd, setGotoAdd] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [activeType, setActiveType] = useState("2");

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);
    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getPracticesData(DEFAULT_PAGING_SIZE, previousPage);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getPracticesData(DEFAULT_PAGING_SIZE, page);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getPracticesData(DEFAULT_PAGING_SIZE, nextPage);
  }
  //Pagination ends

  const [showNotify, setShowNotify] = useState('hide');
  const [notifyDescription, setNotifyDescription] = useState('');
  const [notifyType, setNotifyType] = useState('success');
  function showNotifyWindow(action, type, desc, age = 3000) {
      if (action == 'show') {
          setTimeout(() => {
              setShowNotify('hide');
          }, age)
      }
      setShowNotify(action);
      setNotifyType(type);
      setNotifyDescription(desc);
  }

  useEffect(() => {
    setActivePage(location?.state?.callbackactivepage ? location.state.callbackactivepage : activePage)
    getPracticesData(DEFAULT_PAGING_SIZE, location?.state?.callbackactivepage ?location.state.callbackactivepage :activePage);
  }, [activeType]);

  function getPracticesData(pageSize, page) {
    setShowLoadingBar(true);
    let result = null;
    let status = activeType === "1" ? "all" : activeType === "2" ? "active" : "inactive";
    result = service.ListPrcatices(pageSize, page,isAdminModule === "true" ? "": practicePK, status);
    result.then((response) => {
      setShowLoadingBar(false);
      const rowArray = commonTableBody(response.data.results, PracticeTableData.tableBodyData[0])
      PracticeTableData.tableBodyData = rowArray;
      setPracticeList(response.data.results);
      if (response.data && response.data.results !== undefined) {
        setTotalPage(Math.ceil(response.data.count / response.data.page_size));
      }
    });
  }

  function addNew() {
    history.push(ROUTE_PRACTICES_ADD)
  }

  function onEditPractice(praciceID) {
    setSelectedID(praciceID);
    setGotoAdd(true);
  }

  function onHandleChange(e) {
    setActiveType(e.target.value);
  }

  if (gotoAdd) {
    return <Redirect to={{ pathname: ROUTE_PRACTICES_EDIT, state: { selectedID: selectedID },callbackactive:activePage }} />
  }

  function dropdownFunction(id, name) {
    if (name.toLowerCase() === 'edit') {
      if(checkPermission(permission_key_values_accountsetup.account_setup_practice_management_modify, )){
        onEditPractice(id);
      } else {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
    }
    }
  }
  const OnExportMenuItemClicked = (e, type) => {
    const fileDownload = require('js-file-download');
    setShowLoadingBar(true);
    service.exportPractices(type, practicePK)
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          fileDownload(result.data, result.headers['content-disposition']);
          setShowLoadingBar(false);
        } else {
          throw result.statusText ? result.statusText :'error';
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  return (
    <React.Fragment>
      <div className="col-md-12">
      <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
        <div className="box min-h-full margin-right15">

          {/* When no practice is created by the Customer admin then display the below helper text and button indicating to create one to continue */}
          {practiceList && practiceList.length === 0 && (activeType === "2" ) && !practicePK && (
            <div className="">
              <div className="box-content">
                <label>To continue please add your first practice.</label>
                <Link to="#" className="addNew" onClick={addNew}>
                  {i18n.t("buttons.addNew")}
                </Link>
              </div>
            </div>
          )}

          {((practiceList && practiceList.length > 0) || practicePK) && (
            <div className="box-content-white">
              <div className="row">
                <div className="col"></div>
                <div className="mr-1 justify-right">
                  <SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onHandleChange} label={i18n.t("commons.status")} selectOptionRemove={true} labelClassName={"margin-bottom0"} />
                </div>
                <div className="justify-right padding-right5 addNew-btn">
                  <div className="mr-4">
                    <ExportIconButton
                      disabled = {practiceList == undefined || practiceList?.length <= 0 ? true : false }
                      onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                      onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                  </div>
                  {/* permission checking */}
                  {checkPermission(permission_key_values_accountsetup.account_setup_practice_management_add)&&
                  <button type="button" className="btn btn-primary-blue mr-3 btn-common-height35" onClick={addNew}>
                    {i18n.t("buttons.addNew")}
                  </button>
                  }
                </div>
              </div>
              <div className="box-content">
                <div className="table-responsive">
                  <Table tableObject={PracticeTableData} dropDownFunction={dropdownFunction} />
                </div>
                <div className='padding-top10'></div>
                <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Practices;
