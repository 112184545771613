import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import { getStorage } from '../../utilities/browserStorage';
import service from './service';
import LoadingContext from '../../container/loadingContext';
import i18n from '../../utilities/i18n'
import Notify from "../commons/notify";
import TextInput from '../commons/input/input'
import RichTextEditor from '../commons/RichTextEditor/RichTextEditor';
import MultiSearch from '../../MaterialMultiselect/MultiSearch';
import * as staticConfigs from '../../utilities/staticConfigs'
import { Form, Modal } from 'react-bootstrap';
import { ADD_ERROR, MESSAGE_TEMPLATE_CREATION_FAILED, MESSAGE_TEMPLATE_CREATION_SUCCESS, PREVIOUS_MESSAGE, SAVE_MESSAGE_DRAFT, SEND_MESSAGE_FAILED, SEND_MESSAGE_SUCCESS } from '../../utilities/labelConfigs';
import Table from "../commons/Table/Table";
import { ListTemplateModal } from "./listTemplateTable";
import { TableBodyData } from './InboxTable';
import Pagination from '../commons/pagination';
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_messages } from '../../utilities/permissions';
import CommonButton from '../commons/Buttons';



function ComposeScreen() {
    const [show, setShow] = useState("");
    const [shows, setShows] = useState("");
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const history = useHistory();
    const [messageId, setMessageId] = useState('');
    const [prevMessageDate, setPrevMessageDate] = useState('');
    // user List
    const [userList, setUserList] = useState([]);

    const [subject, setSubject] = useState("");
    const [richText, setRichText] = useState('');
    const [warningRichText, setWarningRichText] = useState(false);
    const [draftMessageId, setDraftMessageId] = useState("");
    const messageFilenames = [];

    // multisearch
    const [optionItems, setOptionItems] = useState([]);
    const [optionValues, setOptionValues] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    // Pagination
    const totalPage = 1;
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(staticConfigs.PAGING_END_INDEX);

    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");


    const [searchValue, setSearchValue] = useState("");
    const [modalSaveTemplateName, setmodalSaveTemplateName] = useState("");
    const [templateDatas, setTemplateDatas] = useState([]);

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        let trimmedValue = '';
        trimmedValue = value;
        if (name === 'modalSaveTemplateName') setmodalSaveTemplateName(trimmedValue);
        if (name === 'searchValue') setSearchValue(trimmedValue);
        if (name === "subject") setSubject(trimmedValue);

    }

    function onSearch(e) {
        e.preventDefault()
        setActivePage(1);
        setStartIndex(0);
        setEndIndex(staticConfigs.PAGING_END_INDEX);
        getMessageTemplates(staticConfigs.DEFAULT_PAGING_SIZE, 1)

    }


    function getUserList() {
        setShowLoadingBar(true);
        let result = service.ListPracticeUsers(practicePK);
        result.then((response) => {
            let data = response.data;
            setUserList(data);
            setShowLoadingBar(false);
        });
    }

    useEffect(() => {
        getUserList();
        if (history.location.pathname === '/reply-message') {
            let id = history.location.state.id;
            getInboxMessage(id);
        }
    }, []);

    useEffect(() => {
        getMessageTemplates(staticConfigs.DEFAULT_PAGING_SIZE, activePage);
    }, []);


    function getMessageTemplates(pageSize, page) {
        const result = service.GetMessageTemplateList(practicePK, pageSize, page, searchValue);
        result.then((response) => {
            const rowArray = response.data.results.map((result) => {
                const columns = ListTemplateModal.tableBodyData[0].map((col) => {
                    let colObject = {};
                    if (col.name === 'template_name') {
                        colObject = {
                            ...col,
                            'value': result.template_name,
                            'id': result.id,
                            'is_read': result.is_read
                        };
                    } else if (col.name === 'subject') {
                        colObject = {
                            ...col,
                            'value': result.subject,
                            'id': result.id,
                            'is_read': result.is_read
                        };
                    } else {
                        colObject = col;
                    }
                    return colObject;
                });
                return columns;
            });
            ListTemplateModal.tableBodyData = rowArray.length > 0 ? rowArray : TableBodyData;
            if (response.data) setTemplateDatas(response.data.results);
        });
    }



    const onHandleShowModal = () => {
        getMessageTemplates(staticConfigs.DEFAULT_PAGING_SIZE, activePage);
        setShow(true);
    }

    const onHandleShowSaveModal = () => {
        setShows(true);
        getMessageTemplates(staticConfigs.DEFAULT_PAGING_SIZE, activePage);

    }

    const onHandleModalSave = () => {
        if (!subject) return;
        if (!richText) {
            setWarningRichText(true);
            setTimeout(() => {
                setWarningRichText(false);
            }, 3000);
            return;
        }
        let data = {
            "message": richText,
            "template_name": modalSaveTemplateName,
            "subject": subject,
            "is_deleted": false,
            "practice": practicePK,
        }
        let result = service.CreateMessageTemplate(data,);
        result.then((response) => {
            if (response.status === 201) {
                showNotifyWindow("show", "success", MESSAGE_TEMPLATE_CREATION_SUCCESS);
                setTimeout(() => {
                    history.push(staticConfigs.ROUTE_TEMPLATE);
                }, 2000);
            }
            else {
                showNotifyWindow("show", "error", MESSAGE_TEMPLATE_CREATION_FAILED);
            }
        });

    }
    // Pagination Starts

    function onPagePrevious() {
        let previousPage = startIndex + 1 - staticConfigs.PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex - staticConfigs.PAGING_END_INDEX);
        }
        getMessageTemplates(staticConfigs.DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id);
        setActivePage(page);
        getMessageTemplates(staticConfigs.DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + staticConfigs.PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= staticConfigs.PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex + staticConfigs.PAGING_END_INDEX);
        }
        getMessageTemplates(staticConfigs.DEFAULT_PAGING_SIZE, nextPage);
    }

    const getInboxMessage = (id) => {
        setShowLoadingBar(true);
        let result = service.GetInboxMessageDetail(id);
        result.then((response) => {
            setDraftMessageId('');
            setMessageId(response.data[0].message_id);
            setSelectedValues(response.data[0].sender);
            setPrevMessageDate(new Date(response.data[0].created_on))
            let reply = "Re : " + response.data[0].subject;
            setSubject(reply);
            setRichText(() =>

                `<p>&nbsp;</p>
            <p>&nbsp;</p>
            --- ${PREVIOUS_MESSAGE}  ${moment(response.data[0].created_on).format('MMMM Do YYYY, h:mm:ss a')} ---
            ${response.data[0].body}`

            )
        }).finally(() => {
            setShowLoadingBar(false);
        });
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onSendMessage() {
        if (optionValues.length === 0) return;
        if (!subject) return;
        if (!richText) {
            setWarningRichText(true);
            setTimeout(() => {
                setWarningRichText(false);
            }, 3000);
            return;
        }
        setShowLoadingBar(true);
        let data = {
            "body": richText,
            "subject": subject,
            "receivers": optionValues,
            "is_draft": false,
            "practice": practicePK,
            "message_id": draftMessageId,
            "images": messageFilenames
        }
        let result = service.SendMessage(data);
        result.then((response) => {
            setShowLoadingBar(false);
            if (response.status === 201) {
                showNotifyWindow("show", "success", SEND_MESSAGE_SUCCESS);
                setTimeout(() => {
                    history.push(staticConfigs.ROUTE_INBOX);
                }, 2000);
            }
            else {
                showNotifyWindow("show", "error", SEND_MESSAGE_FAILED);
            }
        });

    }

    function onSaveAsDraft() {
        if (!richText) {
            setWarningRichText(true);
            setTimeout(() => {
                setWarningRichText(false);
            }, 3000);
            return;
        }
        let data = {
            "body": richText,
            "subject": subject,
            "receivers": optionValues,
            "is_draft": true,
            "practice": practicePK,
            "message_id": draftMessageId
        }
        let result = service.SendMessage(data);
        result.then((response) => {
            if (response.status === 201) {
                showNotifyWindow("show", "success", SAVE_MESSAGE_DRAFT);
                setTimeout(() => {
                    history.push(staticConfigs.ROUTE_INBOX);
                }, 2000);
            }
            else {
                showNotifyWindow("show", "error", ADD_ERROR);
            }
        });
    }
    function onCancelMessage() {
        if (subject) {
            onSaveAsDraft();
        }
        history.push(staticConfigs.ROUTE_INBOX);
    }

    function onSearchContact(query) {
        if (query) {
            let result = service.SearchPracticeUsers(practicePK, query);
            result.then((response) => {
                setOptionItems(response.data);
            })
        }
    }
    function openLinkClick(idValue) {
        let obj = templateDatas.find(item => item.id == idValue);
        // destructuring
        const { TextSubject, message } = obj;
        setRichText(message);
        setSubject(TextSubject);
        setShow(false);
    }



    return (
        <div className='col-md-12'>
            <div className="box basic-info-padding bordering border-radius-8"  style={{ padding: "20px"}}> 
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="row">
                    <div className="margin-left15">
                        <CommonButton variant="contained" onClick={onSendMessage}
                            label={i18n.t("inboxScreens.composeScreen.send")}
                            icon="send"
                        />
                    </div>
                    <div className="margin-left15">
                        {checkPermission(permission_key_values_messages.can_add_message_draft) &&
                            <CommonButton variant="contained" onClick={onSaveAsDraft}
                                label={i18n.t("inboxScreens.composeScreen.saveDraft")}
                                icon="pencil"
                            />}
                    </div>
                    <div className="margin-left15">
                        {checkPermission(permission_key_values_messages.can_add_message_template) &&
                            <CommonButton variant="contained" onClick={onHandleShowSaveModal}
                                label={i18n.t("inboxScreens.composeScreen.saveAsTemplates")}
                                icon="save"
                            />}
                    </div>
                        <Modal
                            show={shows}
                            onHide={() => setShows(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Save Templates
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <>
                                    <div className='mt-4 mb-5'>
                                        <div className="patient_search">
                                            <div className="input-responsible">
                                                <div className="form-group padding-top15">
                                                    <TextInput
                                                        type="text"
                                                        name="modalSaveTemplateName"
                                                        value={modalSaveTemplateName}
                                                        label={i18n.t("inboxScreens.template.tableHeadings.templateName")}
                                                        onValueChange={onHandleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-responsible padding-top15">
                                                <div className="margin-top15">
                                                    <button
                                                        type="button"
                                                        onClick={onHandleModalSave}
                                                        className="btn btn-primary-blue">
                                                        {i18n.t("inboxScreens.composeScreen.save")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </>
                            </Modal.Body>
                        </Modal>
                    <div className="margin-left15">
                        <CommonButton variant="outlined" onClick={onCancelMessage}
                            label={i18n.t("inboxScreens.composeScreen.cancel")}
                        />
                    </div>
                </div>
                <Form rm id={'composeScreen'} autoComplete='off' onSubmit={onSendMessage} >
                    <div className='row mt-4'>
                        <div className='col'>
                            <div className={"form-group"}>
                                <MultiSearch onSearchApi={onSearchContact} selectedValues={selectedValues}
                                    optionItems={optionItems} setOptionItems={setOptionItems}
                                    setOptionValues={setOptionValues} dataList={userList}
                                    label={i18n.t("inboxScreens.composeScreen.to")}
                                    name={"ToContacts"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='form-group'>
                                <TextInput type='text' name={'subject'} value={subject} onValueChange={onHandleChange} label={i18n.t("inboxScreens.composeScreen.subject")} />
                            </div>
                        </div>
                        <div className='margin-right15 margin-top26'>
                            {checkPermission(permission_key_values_messages.can_use_template) &&
                                <button type="button" className="small_box btn-primary-blue btn" onClick={onHandleShowModal}>
                                <i className='fas fa-sticky-note ml-3'></i>{i18n.t("inboxScreens.composeScreen.useTemplate")}
                            </button >}
                        </div>

                        <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Email Template
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <>
                                    <div className='mt-4 mb-5'>
                                        <div className="patient_search">
                                            <div className="input-responsible">
                                                <div className="form-group padding-top15">
                                                    <TextInput
                                                        type="text"
                                                        name="searchValue"
                                                        value={searchValue}
                                                        label={i18n.t("inboxScreens.template.tableHeadings.templateName")}
                                                        onValueChange={onHandleChange}
                                                    // onKeyDown={handleKeyDown}
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-responsible padding-top15">
                                                <div className="margin-top15">
                                                    <button
                                                        type="button"
                                                        onClick={(e) => onSearch(e)}
                                                        className="btn btn-primary-blue">
                                                        {i18n.t("inboxScreens.composeScreen.search")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='table-responsive'>
                                        <Table tableObject={ListTemplateModal} onLinkClick={openLinkClick} />
                                    </div>
                                    <div className='mt-2'>
                                        <Pagination
                                            totalPage={totalPage}
                                            activePage={activePage}
                                            startIndex={startIndex}
                                            endIndex={endIndex}
                                            onPagePrevious={onPagePrevious}
                                            onPageUp={onPageUp}
                                            onPageNext={onPageNext}
                                        />
                                    </div>
                                </>

                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className={"mt-4"}>
                        {warningRichText && <span className='danger'><strong>{i18n.t("inboxScreens.composeScreen.MessageIsMandatory")}</strong></span>}
                        <RichTextEditor required={true} state={richText} setState={setRichText} showNotifyWindow={showNotifyWindow} draftMessageId={draftMessageId} setDraftMessageId={setDraftMessageId} />
                        {
                            messageId &&
                            <label className='reply_time_label'>--- {PREVIOUS_MESSAGE} <Moment format='MMMM Do YYYY, h:mm:ss a' >{prevMessageDate}</Moment> ---
                            </label>
                        }
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ComposeScreen