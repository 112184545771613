import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import CalendarInput from '../../commons/input/CalendarInput';
import i18n from '../../../utilities/i18n'
import TextInput from '../../commons/input/input';
import SelectInput from '../../commons/input/SelectInput';
import {
    YesOrNo, SPECIAL_PROGRAM_CODES,
    TRANSPORT_CODE
} from '../../../utilities/dictionaryConstants';
import service5 from '../../dictionaries/accidentTypes/service';
import service3 from '../../dictionaries/transportReasons/service';
import service4 from "./service"
import LoadingContext from '../../../container/loadingContext';
import { DEFAULT_COUNTRY, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import TextAreaInput from '../../commons/textarea/textarea';
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch';
import { format } from 'date-fns';
import { CityInput } from '../../commons/input/CityInput';
import { convertToDecimal } from '../../../utilities/commonUtilities';
import { useSelector } from 'react-redux';
import { getFormWarings, getadditionalData, updateAdditionalDetails } from '../claimManagementSlice/claimManagementSlice';
import { getDelayReasonCode } from '../claimManagementSlice/claimSelectInputSlice';
import { useDispatch } from 'react-redux';
import { getPatientAdditionalDetails, updateAdditionalSaveDetails , getPatientPKDetails} from '../claimManagementSlice/saveManagementSlice';
import moment from 'moment';
import { Divider } from '@mui/material';

let additionalClaimDataFromDb = {};
function AdditionalClaimInfo(props) {
    const dispatch = useDispatch();
    const { BillingInfoData } = useSelector(state => state.claimSaveManagmentData);
    const additionalClaimInfo = useSelector(state => state.claimData.additionclaimInfos);
    const additionalDetails = useSelector(state => state.claimData.additionalclaimData);
    const claimDetails = useSelector(state => state.claimData.commonData);
    const delayReasonCodeList = useSelector(state => state.claimSelectInputData.delayReasonCodeList)
    const { saveMode, additionalclaimData, additionalLoaded, loading, commonData } = useSelector(state => state.claimSaveManagmentData)
    const loadingBar = useSelector(state => state.claimData.loading);
    const setShowLoadingBar = useContext(LoadingContext);
    const [stateList, setStateList] = useState([]);
    const [accidentTypeList, setAccidentTypeList] = useState([]);
    const [transportTypeList, setTransportTypeList] = useState([]);
    const patientDeathDate = props.patientDeathDate;

    //additionalClaiminfo
    const [additionalClaimVariable, setAdditionalClaimVariable] = useState({
        "id": "", "employment": 2, "other_accident": "", "accident_date": "", "last_menstural_date": "",
        "initial_treatment_date": "", "last_seen_date": null, "unable_work_from_date": "",
        "unable_work_to_date": null, "hospitalization_from_date": "", "hospitalization_to_date": null,
        "assumed_care_date": null, "disability_begin_date": null, "disability_end_date": null,
        "relinquished_date": null, "mammography_cert_number": "", "care_plan_number": "",
        "service_authorization_code": "", "clia_number": "", "ide_number": "", "project_demonstration_id": "",
        "special_program_code": "", "patient_homebound": "", "claim_delay_reason": "",
        "claim_note": "", "lab_sales_rep": "", "claim_service_type": "", "clinic_site_name": "",
        "accession_number": "", "ambulance_claim": 2, "weight": "",
        "distance": "", "return_trip_reason": "", "stretcher_reason": "", "certification": null,
        "condition_indicator": {
            "patient_admitted_hospital": false, "patient_moved_by_stretcher": false, "patient_unconscious_shock": false,
            "patient_transported_emergency": false, "patient_physically_restrained": false, "patient_visible_haemorrhaging": false,
            "ambulance_service_medical_necessary": false, "patient_confined_bed_chair": false,
        },
        "lab_notes": "",
        "claim": "", "accident": "", "state": "", "transport_reason": null,
        "pickup_location_data": {
            "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
        },
        "dropoff_location_data": {
            "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
        },
        "transport_code": ""
    });

    const [pickupCity, setPickupCity] = useState("");
    const [pickupState, setPickupState] = useState("");
    const [pickupZip, setPickupZip] = useState("");
    const [pickupCountry, setPickupCountry] = useState("");

    const [dropCity, setDropCity] = useState("");
    const [dropState, setDropState] = useState("");
    const [dropZip, setDropZip] = useState("");
    const [dropCountry, setDropCountry] = useState("");
    const [returnTripValue, setReturnTripValue] = useState(false);
    const [otherAccidentNoId, setOtherAccidentNoId] = useState("");




    function getTransportData(pageSize) {
        const pageNum = 0;
        const result = service3.ListTransportTypes(pageSize, pageNum, props.claimPK);
        result.then(response => {
            setTransportTypeList(response.data);
        });
    }

    function ListOfStates() {
        const result = service4.ListOfUSStates(props.claimPK);
        result.then((response) => {
            setStateList(response.data);
        });
    }
    //additionalClaim
    function getAccidentData(pageSize) {
        const pageNum = 0;
        let accident_no;
        const result = service5.ListAccidentTypes(pageSize, pageNum, props.claimPK);
        result.then(response => {
            if (response.data) setAccidentTypeList(response.data.filter(item => item.active === true));
            else setAccidentTypeList([]);
            if (response.data.length > 0) {
                accident_no = response.data.find((item => item.name.toLowerCase() == 'not related to accident'))
                if (accident_no)
                    props.setAccidentNoId(accident_no.id);
                response.data.forEach((item) => {
                    if (item.name.toLowerCase() === 'related to other accident') {
                        setOtherAccidentNoId(item.id);
                    }
                })
            }
        })
            .finally(() => {
                // setAdditionalClaimVariable((prev) => {
                //     return { ...prev, "accident": accident_no.id }
                // })
            })
    }

    function getAdditionalClaimData(id) {
        if (additionalClaimInfo?.length === 0) {
            dispatch(getadditionalData({ additionalPK: id, claimPk: props.claimPK }))
        }
        else {
            const exist = additionalClaimInfo?.findIndex(item => item.additionalPK === id)
            if (exist >= 0) {
                // dispatch(getAllInformation({ node: "additional_info", pk: id }))
            }
            else {
                dispatch(getadditionalData({ additionalPK: id, claimPk: props.claimPK }))
            }
        }

    }

    //if saved calling api to remove formwarnings
    useEffect(() => {
        if (props.isSaved) {
            if (claimDetails.id) {
                dispatch(getFormWarings(claimDetails.id))
                if (!props.isClosed) {
                    dispatch(getadditionalData({ additionalPK: props.additionalClaim_pks, claimPk: props.claimPK }))
                }
            }
            props.setIsSaved(false)
        }
    }, [props.isSaved])

    useEffect(() => {
        if (additionalDetails && saveMode !== 'Save') {
            let data = {};
            data = additionalDetails;
            setAdditionalClaimVariable({
                accession_number: data.accession_number, accident: data.accident, state: data.state,
                accident_date: data.accident_date && new Date(data.accident_date + " 00:00:00"),
                assumed_care_date: data.assumed_care_date && new Date(data.assumed_care_date + " 00:00:00"),
                disability_begin_date: data.disability_begin_date && new Date(data.disability_begin_date + " 00:00:00"),
                disability_end_date: data.disability_end_date && new Date(data.disability_end_date + " 00:00:00"),
                hospitalization_from_date: data.hospitalization_from_date && new Date(data.hospitalization_from_date + " 00:00:00"),
                hospitalization_to_date: data.hospitalization_to_date && new Date(data.hospitalization_to_date + " 00:00:00"),
                unable_work_from_date: data.unable_work_from_date && new Date(data.unable_work_from_date + " 00:00:00"),
                unable_work_to_date: data.unable_work_to_date && new Date(data.unable_work_to_date + " 00:00:00"),
                initial_treatment_date: data.initial_treatment_date && new Date(data.initial_treatment_date + " 00:00:00"),
                last_menstural_date: data.last_menstural_date && new Date(data.last_menstural_date + " 00:00:00"),
                last_seen_date: data.last_seen_date && new Date(data.last_seen_date + " 00:00:00"),
                relinquished_date: data.relinquished_date && new Date(data.relinquished_date + " 00:00:00"),
                ambulance_claim: data.ambulance_claim,
                care_plan_number: data.care_plan_number, certification: data.certification, claim: data.claim,
                claim_delay_reason: data.claim_delay_reason,
                claim_note: data.claim_note, clia_number: data.clia_number, clinic_site_name: data.clinic_site_name,
                distance: data.distance,
                dropoff_location: data.dropoff_location ? data.dropoff_location : "",
                dropoff_location_data: data.dropoff_location_data ? {
                    "street1": data.dropoff_location_data.street1, "street2": data.dropoff_location_data.street2,
                    "city": data.dropoff_location_data.city, "state": data.dropoff_location_data.state,
                    "country": data.dropoff_location_data.country, "zip_code": data.dropoff_location_data.zip_code
                }
                    : {
                        "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
                    },
                employment: data.employment,
                ide_number: data.ide_number,
                lab_notes: data.lab_notes,
                lab_sales_rep: data.lab_sales_rep,
                claim_service_type: data.claim_service_type,
                mammography_cert_number: data.mammography_cert_number,
                other_accident: data.other_accident,
                condition_indicator: {
                    ambulance_service_medical_necessary: data.ambulance_service_medical_necessary,
                    patient_admitted_hospital: data.patient_admitted_hospital, patient_confined_bed_chair: data.patient_confined_bed_chair,
                    patient_moved_by_stretcher: data.patient_moved_by_stretcher,
                    patient_physically_restrained: data.patient_physically_restrained,
                    patient_transported_emergency: data.patient_transported_emergency, patient_unconscious_shock: data.patient_unconscious_shock,
                    patient_visible_haemorrhaging: data.patient_visible_haemorrhaging,
                },
                patient_homebound: data.patient_homebound,
                pickup_location: data.pickup_location ? data.pickup_location : "",
                pickup_location_data: data.pickup_location_data ? {
                    "street1": data.pickup_location_data.street1, "street2": data.pickup_location_data.street2,
                    "city": data.pickup_location_data.city, "state": data.pickup_location_data.state,
                    "country": data.pickup_location_data.country,
                    "zip_code": data.pickup_location_data.zip_code
                } : {
                    "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
                },
                return_trip_reason: data.return_trip_reason, service_authorization_code: data.service_authorization_code,
                special_program_code: data.special_program_code, stretcher_reason: data.stretcher_reason, transport_code: data.transport_code,
                transport_reason: data.transport_reason,
                weight: data.weight,
                project_demonstration_id: data.project_demonstration_id ? data.project_demonstration_id : ""
            });
            setPickupZip(data.pickup_location_data ? data.pickup_location_data.zip_code : "");
            setPickupCountry(data.pickup_location_data ? data.pickup_location_data.country : DEFAULT_COUNTRY);
            setPickupState(data.pickup_location_data ? data.pickup_location_data.state : "");
            setPickupCity(data.pickup_location_data ? data.pickup_location_data.city : "");

            setDropZip(data.dropoff_location_data ? data.dropoff_location_data.zip_code : "");
            setDropCountry(data.dropoff_location_data ? data.dropoff_location_data.country : DEFAULT_COUNTRY);
            setDropState(data.dropoff_location_data ? data.dropoff_location_data.state : "");
            setDropCity(data.dropoff_location_data ? data.dropoff_location_data.city : "");
        }
    }, [additionalDetails])

    useEffect(() => {
        if (saveMode === 'Save') {
            getPatientAccidentInfo()
        }
    }, [additionalclaimData])


    //already created claim loading
    useEffect(() => {
        setShowLoadingBar(loadingBar)
    }, [loadingBar])

    //new claim data fetching loading
    useEffect(() => {
        setShowLoadingBar(loading)
    }, [loading])

    const getPatientAccidentInfo = () => {
        let data = {};
        data = additionalclaimData;
        setAdditionalClaimVariable({
            accession_number: data.accession_number, accident: data.accident, state: data.state,
            accident_date: data.accident_date && new Date(data.accident_date + " 00:00:00"),
            assumed_care_date: data.assumed_care_date && new Date(data.assumed_care_date + " 00:00:00"),
            disability_begin_date: data.disability_begin_date && new Date(data.disability_begin_date + " 00:00:00"),
            disability_end_date: data.disability_end_date && new Date(data.disability_end_date + " 00:00:00"),
            hospitalization_from_date: data.hospitalization_from_date && new Date(data.hospitalization_from_date + " 00:00:00"),
            hospitalization_to_date: data.hospitalization_to_date && new Date(data.hospitalization_to_date + " 00:00:00"),
            unable_work_from_date: data.unable_work_from_date && new Date(data.unable_work_from_date + " 00:00:00"),
            unable_work_to_date: data.unable_work_to_date && new Date(data.unable_work_to_date + " 00:00:00"),
            initial_treatment_date: data.initial_treatment_date && new Date(data.initial_treatment_date + " 00:00:00"),
            last_menstural_date: data.last_menstural_date && new Date(data.last_menstural_date + " 00:00:00"),
            last_seen_date: data.last_seen_date && new Date(data.last_seen_date + " 00:00:00"),
            relinquished_date: data.relinquished_date && new Date(data.relinquished_date + " 00:00:00"),
            ambulance_claim: data.ambulance_claim ? data.ambulance_claim : 2,
            care_plan_number: data.care_plan_number, certification: data.certification, claim: data.claim,
            claim_delay_reason: data.claim_delay_reason,
            claim_note: data.claim_note, clia_number: data.clia_number, clinic_site_name: data.clinic_site_name,
            distance: data.distance,
            dropoff_location: data.dropoff_location ? data.dropoff_location : "",
            dropoff_location_data: data.dropoff_location_data ? {
                "street1": data.dropoff_location_data.street1, "street2": data.dropoff_location_data.street2,
                "city": data.dropoff_location_data.city, "state": data.dropoff_location_data.state,
                "country": data.dropoff_location_data.country, "zip_code": data.dropoff_location_data.zip_code
            }
                : {
                    "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
                },
            employment: data.employment ? data.employment : 2,
            ide_number: data.ide_number,
            lab_notes: data.lab_notes,
            lab_sales_rep: data.lab_sales_rep,
            claim_service_type: data.claim_service_type,
            mammography_cert_number: data.mammography_cert_number,
            other_accident: data.other_accident,
            condition_indicator: {
                ambulance_service_medical_necessary: data.ambulance_service_medical_necessary ? data.ambulance_service_medical_necessary : false,
                patient_admitted_hospital: data.patient_admitted_hospital ? data.patient_admitted_hospital : false,
                patient_confined_bed_chair: data.patient_confined_bed_chair ? data.patient_confined_bed_chair : false,
                patient_moved_by_stretcher: data.patient_moved_by_stretcher ? data.patient_moved_by_stretcher : false,
                patient_physically_restrained: data.patient_physically_restrained ? data.patient_physically_restrained : false,
                patient_transported_emergency: data.patient_transported_emergency ? data.patient_transported_emergency : false,
                patient_unconscious_shock: data.patient_unconscious_shock ? data.patient_unconscious_shock : false,
                patient_visible_haemorrhaging: data.patient_visible_haemorrhaging ? data.patient_visible_haemorrhaging : false,
            },
            patient_homebound: data.patient_homebound,
            pickup_location: data.pickup_location ? data.pickup_location : "",
            pickup_location_data: data.pickup_location_data ? {
                "street1": data.pickup_location_data.street1, "street2": data.pickup_location_data.street2,
                "city": data.pickup_location_data.city, "state": data.pickup_location_data.state,
                "country": data.pickup_location_data.country,
                "zip_code": data.pickup_location_data.zip_code
            } : {
                "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
            },
            return_trip_reason: data.return_trip_reason, service_authorization_code: data.service_authorization_code,
            special_program_code: data.special_program_code, stretcher_reason: data.stretcher_reason, transport_code: data.transport_code,
            transport_reason: data.transport_reason,
            weight: data.weight,
            project_demonstration_id: data.project_demonstration_id ? data.project_demonstration_id : ""
        });
        setPickupZip(data.pickup_location_data ? data.pickup_location_data.zip_code : "");
        setPickupCountry(data.pickup_location_data ? data.pickup_location_data.country : DEFAULT_COUNTRY);
        setPickupState(data.pickup_location_data ? data.pickup_location_data.state : "");
        setPickupCity(data.pickup_location_data ? data.pickup_location_data.city : "");

        setDropZip(data.dropoff_location_data ? data.dropoff_location_data.zip_code : "");
        setDropCountry(data.dropoff_location_data ? data.dropoff_location_data.country : DEFAULT_COUNTRY);
        setDropState(data.dropoff_location_data ? data.dropoff_location_data.state : "");
        setDropCity(data.dropoff_location_data ? data.dropoff_location_data.city : "");
    }

    useEffect(() => {
        if (props.additionalClaim_pks) {
            let additional_claim_info_pk = props.additionalClaim_pks;
            getAdditionalClaimData(additional_claim_info_pk);
        }
    }, [props.additionalClaim_pks])

    useEffect(() => {
        getTransportData(DEFAULT_PAGING_SIZE);
        getAccidentData(DEFAULT_PAGING_SIZE);
        ListOfStates();
        dispatch(getDelayReasonCode())
    }, []);
    useEffect(() => {
        if (props.patientPK) {
        dispatch(getPatientPKDetails(props.patientPK))
        }
        if (props.patientPK && !props.claimPK && !additionalLoaded)
            dispatch(getPatientAdditionalDetails({ patientPk: props.patientPK, claimPk: props.claimPK }))
    }, [props.patientPK])
    const onHandleChange = (e) => {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.value;
            if (name === "weight" || name === "distance" ) {
                // Use a regular expression to keep only numeric characters
                value = value.replace(/[^0-9.]/g, '');
              }
              
            if (e.target.type !== "checkbox") {
                if (name !== "accident") {
                    // if (name == 'distance') {
                    //     console.log(value)
                    //     // setAdditionalClaimVariable({
                    //     //     ...additionalClaimVariable, [name]: value
                    //     // });
                    // }
                    // else {
                    if (saveMode !== 'Save') {
                        dispatch(updateAdditionalDetails({ name: name, value: value, pk: claimDetails?.pks?.additional_claim_info_pk }));
                    }
                    else {
                        dispatch(updateAdditionalSaveDetails({ name: name, value: value }))
                    }
                }
                else if ((name === "accident") && (value === props.accidentNoId)) {
                    additionalClaimVariable["state"] = "";
                    additionalClaimVariable["other_accident"] = "";
                    if (saveMode !== 'Save') {
                        dispatch(updateAdditionalDetails({ name: name, value: value, pk: claimDetails?.pks?.additional_claim_info_pk }));
                    }
                    else {
                        dispatch(updateAdditionalSaveDetails({ name: name, value: value }))
                    }
                }
                else if ((name === "accident") && (value !== otherAccidentNoId)) {
                    additionalClaimVariable["other_accident"] = "";
                    if (saveMode !== 'Save') {
                        dispatch(updateAdditionalDetails({ name: name, value: value, pk: claimDetails?.pks?.additional_claim_info_pk }));
                    }
                    else {
                        dispatch(updateAdditionalSaveDetails({ name: name, value: value }))
                    }
                }
                else {
                    if (saveMode !== 'Save') {
                        dispatch(updateAdditionalDetails({ name: name, value: value, pk: claimDetails?.pks?.additional_claim_info_pk }));
                    }
                    else {
                        dispatch(updateAdditionalSaveDetails({ name: name, value: value }))
                    }
                }
            }
            else if (e.target.type === "checkbox") {
                let count;
                let newValue = { ...additionalClaimVariable.condition_indicator, [name]: e.target.checked };
                count = Object.values(newValue).filter((item) => item === true);
                if (count.length < 6) {
                    if (saveMode !== 'Save') {
                        dispatch(updateAdditionalDetails({ name: name, value: e.target.checked, pk: claimDetails?.pks?.additional_claim_info_pk }));
                    }
                    else {
                        dispatch(updateAdditionalSaveDetails({ name: name, value: e.target.checked }))
                    }
                }
            }
        }
    };

    useEffect(() => {
        let filteredAdditionalClaimData = {};
        let data = {};
        let {
            employment, accident, state, other_accident, accident_date, transport_code,
            last_menstural_date, initial_treatment_date, last_seen_date, unable_work_from_date, unable_work_to_date,
            hospitalization_from_date, hospitalization_to_date, assumed_care_date, disability_begin_date, disability_end_date,
            relinquished_date,
            mammography_cert_number, care_plan_number, service_authorization_code, clia_number, ide_number, special_program_code,project_demonstration_id,
            patient_homebound, claim_delay_reason, claim_note, lab_sales_rep, claim_service_type, clinic_site_name, accession_number, ambulance_claim,
            weight, transport_reason, distance, return_trip_reason, stretcher_reason, certification,
            condition_indicator,
            pickup_location_data,
            dropoff_location_data,
            lab_notes
        } = additionalClaimVariable;
        let additionalClaimVariableData = {
            employment,
            accident_id: accident, state_id: state, special_program_code, transport_reason_id: transport_reason,
            other_accident, transport_code,
            accident_date, last_menstural_date, initial_treatment_date, last_seen_date, unable_work_from_date,
            unable_work_to_date, hospitalization_from_date, hospitalization_to_date, assumed_care_date, disability_begin_date,
            disability_end_date, relinquished_date,
            mammography_cert_number, care_plan_number, service_authorization_code, clia_number, ide_number,project_demonstration_id,
            patient_homebound, claim_delay_reason, claim_note, lab_sales_rep, claim_service_type, clinic_site_name, accession_number, ambulance_claim,
            weight, distance, return_trip_reason, stretcher_reason, certification,
            ...condition_indicator,
            pickup_address: ambulance_claim === 1 ? pickup_location_data : {},
            dropoff_address: ambulance_claim === 1 ? dropoff_location_data : {},
            pickup_location_data: ambulance_claim === 1 ? pickup_location_data : {},
            dropoff_location_data: ambulance_claim === 1 ? dropoff_location_data : {},
            lab_notes
        }
        for (const [key, value] of Object.entries(additionalClaimVariableData)) {
            if (value) {
                if (key.endsWith("_date")) {
                    try {
                        filteredAdditionalClaimData[key] = format(value, 'yyyy-MM-dd');
                    } catch (e) {
                        filteredAdditionalClaimData[key] = value;
                    }
                } else {
                    filteredAdditionalClaimData[key] = value;
                }
            }
        }
        if (!props.claimPK) {
            delete filteredAdditionalClaimData["pickup_location_data"]
            delete filteredAdditionalClaimData["dropoff_location_data"]
            props.setAdditionalInfoData(filteredAdditionalClaimData)
        }
        else if (props.claimPK) {
            let filteredAdditionalClaimDataFromDB = {}
            Object.keys(additionalClaimDataFromDb).forEach((item) => {
                filteredAdditionalClaimDataFromDB[item] = additionalClaimVariable[item];
            });
            data = {
                ...filteredAdditionalClaimDataFromDB, ...filteredAdditionalClaimData, "pk": props.additionalClaim_pks, ...condition_indicator
            }
            delete data["pickup_location_data"]
            delete data["dropoff_location_data"]
            props.setAdditionalInfoDataDb(data)
        }
    }, [additionalClaimVariable])

    const onHandleDate = (value, name) => {
        const formattedDateForRedux = value instanceof Date ? moment(value).format("yyyy-MM-dd") : null;
        if (formattedDateForRedux != 'Invalid date') {
            const dataValue = value == null ? null : format(value, "yyyy-MM-dd")
            if (saveMode !== 'Save') {
                dispatch(updateAdditionalDetails({
                    name: name, value: dataValue,
                    pk: claimDetails?.pks?.additional_claim_info_pk
                }));
            }
            else {
                dispatch(updateAdditionalSaveDetails({ name: name, value: dataValue }));
            }
        }
        else {
            if (saveMode !== 'Save') {
                dispatch(updateAdditionalDetails({
                    name: name, value: value,
                    pk: claimDetails?.pks?.additional_claim_info_pk
                }));
            }
            else {
                dispatch(updateAdditionalSaveDetails({ name: name, value: value }));
            }
        }
    };
    const onHandleChangeDropOffAdress = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (saveMode !== 'Save') {
            dispatch(updateAdditionalDetails({ name: 'dropoff_location_data', value: { ...additionalClaimVariable.dropoff_location_data, [name]: value }, pk: claimDetails?.pks?.additional_claim_info_pk }));
        }
        else {
            dispatch(updateAdditionalSaveDetails({ name: 'dropoff_location_data', value: { ...additionalClaimVariable.dropoff_location_data, [name]: value } }));
        }
    };
    const onHandleChangePickupAddress = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (saveMode !== 'Save') {
            dispatch(updateAdditionalDetails({ name: 'pickup_location_data', value: { ...additionalClaimVariable.pickup_location_data, [name]: value }, pk: claimDetails?.pks?.additional_claim_info_pk }));
        }
        else {
            dispatch(updateAdditionalSaveDetails({ name: 'pickup_location_data', value: { ...additionalClaimVariable.pickup_location_data, [name]: value } }));
        }
    };
    useEffect(() => {
        if (pickupState && pickupCity && pickupCountry && pickupZip) {
            if (saveMode !== 'Save') {
                dispatch(updateAdditionalDetails({ name: 'pickup_location_data', value: { ...additionalClaimVariable.pickup_location_data, "city": pickupCity, "zip_code": pickupZip, "state": pickupState, "country": pickupCountry }, pk: claimDetails?.pks?.additional_claim_info_pk }));
            }
            else {
                dispatch(updateAdditionalSaveDetails({ name: 'pickup_location_data', value: { ...additionalClaimVariable.pickup_location_data, "city": pickupCity, "zip_code": pickupZip, "state": pickupState, "country": pickupCountry } }));
            }
        } else if (pickupCountry) {
            setAdditionalClaimVariable({
                ...additionalClaimVariable, pickup_location_data: {
                    ...additionalClaimVariable.pickup_location_data,
                    "city": pickupCity, "zip_code": pickupZip, "state": pickupState, "country": pickupCountry
                }
            });
        }
    }, [pickupZip, pickupState, pickupCountry, pickupCity]);
    useEffect(() => {
        if (dropState && dropCity && dropCountry && dropZip) {
            if (saveMode !== 'Save') {
                dispatch(updateAdditionalDetails({ name: 'dropoff_location_data', value: { ...additionalClaimVariable.dropoff_location_data, "city": dropCity, "zip_code": dropZip, "state": dropState, "country": dropCountry }, pk: claimDetails?.pks?.additional_claim_info_pk }));
            }
            else {
                dispatch(updateAdditionalSaveDetails({ name: 'dropoff_location_data', value: { ...additionalClaimVariable.dropoff_location_data, "city": dropCity, "zip_code": dropZip, "state": dropState, "country": dropCountry } }));
            }
        } else if (dropCountry) {
            setAdditionalClaimVariable({
                ...additionalClaimVariable, dropoff_location_data: {
                    ...additionalClaimVariable.dropoff_location_data,
                    "city": dropCity, "zip_code": dropZip, "state": dropState, "country": dropCountry
                }
            });
        }
    }, [dropCountry, dropState, dropCity, dropZip]);

    useEffect(() => {
        if (additionalClaimVariable.ambulance_claim === 1) {
            let returnTripValues = TRANSPORT_CODE.find((item) => (item.name.toLowerCase() === 'return trip'));
            setReturnTripValue(returnTripValues.id);
        } else {
            setReturnTripValue("");
        }
    }, [additionalClaimVariable.ambulance_claim]);

    const onValueBlur = () => {
        const value = additionalClaimVariable.distance ? convertToDecimal(additionalClaimVariable.distance, 2) : ""
        if (saveMode !== 'Save') {
            dispatch(updateAdditionalDetails({ name: 'distance', value: value, pk: claimDetails?.pks?.additional_claim_info_pk }));
        }
        else {
            dispatch(updateAdditionalSaveDetails({ name: 'distance', value: value }))
        }
    }


    return (
        <div>
            <Form autoComplete="off" className="col-md-12 margin-top20">
                <div className="row padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientsConditionRelatedTo")}</label>
                    </div>

                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="col-3 pl-0">
                        <SelectInput data={YesOrNo} name="employment" value={additionalClaimVariable.employment} onValueChange={(e) => onHandleChange(e)}
                        label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.employment")} selectOptionRemove={true} />
                </div>
                <div className='row'>
                    <div className="col-3 ">
                        <SelectInput data={accidentTypeList} name="accident" value={saveMode !== 'Save' ? additionalDetails.accident : additionalclaimData.accident}
                            onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.accident")} selectOptionRemove={true} />
                    </div>
                    {(props.accidentNoId == (saveMode !== 'Save' ? additionalDetails.accident : additionalclaimData.accident)) ? "" :
                        <div className="col-3 pl-0">
                            <div className="form-group">
                                <SelectInput name="state" data={stateList}
                                    value={additionalClaimVariable.state} onValueChange={(e) => onHandleChange(e)} required={true}
                                    label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.Statement")} />
                            </div>
                        </div>
                    }
                    {(saveMode !== 'Save' ? additionalDetails.accident : additionalclaimData.accident) === otherAccidentNoId ?
                        <div className="col-3 pl-0">
                            <TextInput type="text" name="other_accident" value={additionalClaimVariable.other_accident} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.otherAccident")} />
                        </div>
                        : ""}
                </div>
                <div className="row">
                    {(saveMode !== 'Save' ? additionalDetails.accident : additionalclaimData.accident) !== 1 &&
                        <div className="col-3 ">
                            <CalendarInput name="accident_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.accidentDate")}
                                selected={additionalClaimVariable.accident_date} onValueChange={(e) => onHandleDate(e, "accident_date")}
                                required={additionalClaimVariable.accident && additionalClaimVariable.accident != props.accidentNoId ? true : false}
                                maxDate={patientDeathDate ?? new Date()}  minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                            />
                        </div>
                    }
                    {/* In the below conditionally passing the column width to properly align the input controller, for some reason the below controllers...
                        gets mis-aligned when conditional rendering happens on the accident details, last_menstural_date & initial_treatment_date are getting pushed towards left when the className not handled this way  */}
                    {BillingInfoData?.gender_name == "Female"?
                    <div className={(saveMode !== 'Save' ? additionalDetails.accident : additionalclaimData.accident) !== 1 ? "col-3 pl-0" : "col-3" }>
                        <CalendarInput name="last_menstural_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.lastMenstrualDate")}
                            selected={additionalClaimVariable.last_menstural_date} onValueChange={(e) => onHandleDate(e, "last_menstural_date")}
                            maxDate={patientDeathDate ?? new Date()}  minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div> :null}  
                    <div className={(saveMode !== 'Save' ? additionalDetails.accident : additionalclaimData.accident) !== 1 ? "col-3 pl-0" : "col-3" }>
                            <CalendarInput name="initial_treatment_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.initialTreatmentDate")}
                            selected={additionalClaimVariable.initial_treatment_date} onValueChange={(e) => onHandleDate(e, "initial_treatment_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="last_seen_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.lastSeenDate")}
                            selected={additionalClaimVariable.last_seen_date} onValueChange={(e) => onHandleDate(e, "last_seen_date")}
                            maxDate={patientDeathDate ?? new Date()}  minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="col-3">
                        <CalendarInput name="unable_work_from_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.unableToWorkFrom")}
                            selected={additionalClaimVariable.unable_work_from_date} onValueChange={(e) => onHandleDate(e, "unable_work_from_date")}
                            maxDate={patientDeathDate ?? new Date()}  minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="unable_work_to_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.unableToWorkTo")}
                            selected={additionalClaimVariable.unable_work_to_date} onValueChange={(e) => onHandleDate(e, "unable_work_to_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={additionalClaimVariable.unable_work_from_date}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="hospitalization_from_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.hospitalizeFromDate")}
                            selected={additionalClaimVariable.hospitalization_from_date} onValueChange={(e) => onHandleDate(e, "hospitalization_from_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="hospitalization_to_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.hospitalizeToDate")}
                            selected={additionalClaimVariable.hospitalization_to_date} onValueChange={(e) => onHandleDate(e, "hospitalization_to_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={additionalClaimVariable.hospitalization_from_date}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <CalendarInput name="assumed_care_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.assumedCareDate")}
                            selected={additionalClaimVariable.assumed_care_date} onValueChange={(e) => onHandleDate(e, "assumed_care_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="disability_begin_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.disabilityBeginDate")}
                            selected={additionalClaimVariable.disability_begin_date} onValueChange={(e) => onHandleDate(e, "disability_begin_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="disability_end_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.disabilityEndDate")}
                            selected={additionalClaimVariable.disability_end_date} onValueChange={(e) => onHandleDate(e, "disability_end_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={additionalClaimVariable.disability_begin_date}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <CalendarInput name="relinquished_date" label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.relinquishedDate")}
                            selected={additionalClaimVariable.relinquished_date} onValueChange={(e) => onHandleDate(e, "relinquished_date")}
                            maxDate={patientDeathDate ?? new Date()} minDate={saveMode !== 'Save' ? claimDetails?.patient_dob : commonData?.patient_dob}
                        />
                    </div>
                </div>
                <div className="row padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">
                            {i18n.t("searchClaims.professionalClaims.additionalClaimInf.additionalInfo")}
                        </label>
                    </div>

                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <TextInput type="text" name="mammography_cert_number" value={additionalClaimVariable.mammography_cert_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.mamograpghyCertNumber")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group">
                            <TextInput type="text" name="care_plan_number" value={additionalClaimVariable.care_plan_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.carePlanNumber")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group">
                            <TextInput type="text" name="service_authorization_code" value={additionalClaimVariable.service_authorization_code} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.serviceAuthCode")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group">
                            <TextInput type="text" name="clia_number" value={additionalClaimVariable.clia_number ? additionalClaimVariable.clia_number : ''} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.cliaNumber")} />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <TextInput type="text" name="ide_number" value={additionalClaimVariable.ide_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.ideNumber")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={SPECIAL_PROGRAM_CODES} name="special_program_code" value={additionalClaimVariable.special_program_code} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.specialProgramCode")} />
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput name="project_demonstration_id" value={additionalClaimVariable.project_demonstration_id} onValueChange={(e) => onHandleChange(e)}
                                label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.projectDemonstrationId")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <SelectInput data={YesOrNo} name="patient_homebound" value={additionalClaimVariable.patient_homebound} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientHomebound")} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        <SelectInput data={delayReasonCodeList} name="claim_delay_reason" value={additionalClaimVariable.claim_delay_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimDelay")} />
                    </div>
                </div>
                <div className="justify-normal">
                    <div className="div-inline">
                        <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimNote")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        <div className="form-group">
                            <TextAreaInput className="" name="claim_note" value={additionalClaimVariable.claim_note} onValueChange={(e) => onHandleChange(e)}
                                placeholder={i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimNote")} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="lab_sales_rep" value={additionalClaimVariable.lab_sales_rep} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.labSalesRep")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="clinic_site_name" value={additionalClaimVariable.clinic_site_name} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.clinicName")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="accession_number" value={additionalClaimVariable.accession_number} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.accession")} />
                        </div>
                    </div>
                    <div className="col-3 pl-0">
                        <div className="form-group padding-top">
                            <TextInput type="text" name="claim_service_type" value={additionalClaimVariable.claim_service_type} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.claimServiceType")} />
                        </div>
                    </div>
                </div>
                <div className="row padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">
                            {i18n.t("searchClaims.professionalClaims.additionalClaimInf.ambulanceInfo")}
                        </label>
                    </div>

                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className="col-3">
                        <SelectInput data={YesOrNo} name="ambulance_claim" value={additionalClaimVariable.ambulance_claim} onValueChange={(e) => onHandleChange(e)} selectOptionRemove={true} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.ambulanceClaim")} />
                    </div>
                </div>
                {additionalClaimVariable.ambulance_claim !== 2 &&
                    <>
                    <div className="row">
                        <div className="col-3">
                                <div className="form-group padding-top">
                                    <TextInput type="text" id="weight" name="weight" required={true} value={additionalClaimVariable.weight} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.weight")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <SelectInput data={TRANSPORT_CODE} name="transport_code" required={true} value={additionalClaimVariable.transport_code} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.transportCode")} />
                            </div>
                        <div className="col-3 pl-0">
                                <SelectInput data={transportTypeList} name="transport_reason" required={true} value={additionalClaimVariable.transport_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.transportReason")} />
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group padding-top">
                                    <TextInput type="text" id="distance" name="distance" required={true} onValueBlur={() => onValueBlur()} value={additionalClaimVariable.distance} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.distance")} />
                                </div>
                            </div>
                        </div>
                        {(returnTripValue === Number(additionalClaimVariable.transport_code)) &&
                            <div className="justify-normal">
                                <div className="input-responsible  input-responsible-cliams">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="return_trip_reason" required={true} value={additionalClaimVariable.return_trip_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.returnTripReason")} />
                                    </div>
                                </div>
                            </div>
                        }
                    <div className="row">
                        <div className="col-3">
                                <div className="form-group padding-top">
                                <TextInput type="text" name="stretcher_reason" required={true} value={additionalClaimVariable.stretcher_reason} onValueChange={(e) => onHandleChange(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.stretcherReason")} />
                            </div>
                        </div>
                        <div className="col-3 pl-0">
                            <SelectInput data={YesOrNo} name="certification" required={true} value={additionalClaimVariable.certification} onValueChange={(e) => onHandleChange(e)}
                                label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.certification")} />
                        </div>
                        </div>
                    <h6 className="padding-top10 padding-left3">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.conditionIndicator")}</h6>

                    <div className="row">
                            <div className="input-responsible">
                                <div className="row pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_admitted_hospital" name="patient_admitted_hospital"
                                                    checked={additionalClaimVariable.condition_indicator.patient_admitted_hospital} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_admitted_hospital">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientAdmittedToHsptl")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-responsible pr-0">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_moved_by_stretcher" name="patient_moved_by_stretcher"
                                                    checked={additionalClaimVariable.condition_indicator.patient_moved_by_stretcher} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_moved_by_stretcher">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientMovedStretcher")}</label>
                                    </div>

                                </div>
                            </div>
                            <div className="">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_unconscious_shock" name="patient_unconscious_shock"
                                                    checked={additionalClaimVariable.condition_indicator.patient_unconscious_shock} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_unconscious_shock">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientUnconscious")}</label>
                                    </div>

                                </div>
                            </div>
                        </div>


                    <div className="row">
                            <div className="input-responsible">
                                <div className="row pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_transported_emergency" name="patient_transported_emergency"
                                                    checked={additionalClaimVariable.condition_indicator.patient_transported_emergency} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_transported_emergency">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientTransportInEmergency")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-responsible pr-0">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_physically_restrained" name="patient_physically_restrained"
                                                    checked={additionalClaimVariable.condition_indicator.patient_physically_restrained} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_physically_restrained">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientPhysicallyRestrained")}</label>
                                    </div>

                            </div>
                        </div>
                        <div className="">
                            <div className="justify-normal pb-0">
                                <div className="col-1 max-width-3">
                                    <div className="form-group">
                                        <div className="custom-checkbox lh-0">
                                            <input type="checkbox" id="patient_confined_bed_chair" name="patient_confined_bed_chair"
                                                checked={additionalClaimVariable.condition_indicator.patient_confined_bed_chair} onChange={(e) => onHandleChange(e)}
                                            />
                                            <label className="checkbox" htmlFor="patient_confined_bed_chair">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col padding-top5">
                                    <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patietConfinedToBed")}</label>
                                </div>

                            </div>
                        </div>
                        </div>

                    <div className="row">
                            <div className="input-responsible">
                                <div className="row pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="patient_visible_haemorrhaging" name="patient_visible_haemorrhaging"
                                                    checked={additionalClaimVariable.condition_indicator.patient_visible_haemorrhaging} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="patient_visible_haemorrhaging">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                        <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.patientVisibleHaemorhag")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-responsible">
                                <div className="justify-normal pb-0">
                                    <div className="col-1 max-width-3">
                                        <div className="form-group">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="ambulance_service_medical_necessary" name="ambulance_service_medical_necessary"
                                                    checked={additionalClaimVariable.condition_indicator.ambulance_service_medical_necessary} onChange={(e) => onHandleChange(e)}
                                                />
                                                <label className="checkbox" htmlFor="ambulance_service_medical_necessary">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col padding-top5">
                                    <label className="padding-top5 margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.ambulanceMedicalNecessary")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row padding-top20 padding-left15">
                            <div className="div-inline">
                                <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupAddress")}</label>
                            </div>

                    </div>
                    <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                        <div className="row padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <TextInput type="text" name="street1" required={true} value={additionalClaimVariable.pickup_location_data.street1} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupStreet1")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="street2" value={additionalClaimVariable.pickup_location_data.street2} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupStreet2")} />
                                </div>
                            </div>
                        </div>
                        <div className="row padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <CityInput type="text" id="pickup_location_data_city" name="city" required={true} value={additionalClaimVariable.pickup_location_data.city} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupCity")}
                                        zip={additionalClaimVariable.pickup_location_data.zip_code} setValue={setAdditionalClaimVariable} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="state" required={true} value={additionalClaimVariable.pickup_location_data.state} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupState")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <GeoCodeSearch id="pickupZip" name="pickupZip" required={true}
                                        label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupZip")}
                                        setlocState={setPickupState} setLocCountry={setPickupCountry} setLocZipCode={setPickupZip}
                                        setLocCity={setPickupCity} defaultValue={pickupZip} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="country" value={additionalClaimVariable.pickup_location_data.country} onValueChange={(e) => onHandleChangePickupAddress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.pickupCountry")} />
                                </div>
                            </div>
                        </div>

                        <div className="row padding-left15">
                            <div className="div-inline">
                                <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.drop-offAddress")}</label>
                            </div>
                    </div>
                    <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                        <div className="row padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <TextInput type="text" name="street1" required={true} value={additionalClaimVariable.dropoff_location_data.street1} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropStreet1")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="street2" value={additionalClaimVariable.dropoff_location_data.street2} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropStreet2")} />
                                </div>
                            </div>
                        </div>
                        <div className="row  padding-left3">
                            <div className="col-3">
                                <div className="form-group">
                                    <CityInput type="text" id='dropoff_location_data_city' name="city" required={true} value={additionalClaimVariable.dropoff_location_data.city} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropCity")}
                                        zip={additionalClaimVariable.dropoff_location_data.zip_code} setValue={setAdditionalClaimVariable} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="state" required={true} value={additionalClaimVariable.dropoff_location_data.state} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropState")} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <GeoCodeSearch id="dropZip" name="dropZip" required={true}
                                        label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropZip")}
                                        setlocState={setDropState} setLocCountry={setDropCountry} setLocZipCode={setDropZip}
                                        setLocCity={setDropCity} defaultValue={dropZip} />
                                </div>
                            </div>
                        <div className="col-3 pl-0">
                                <div className="form-group">
                                    <TextInput type="text" name="country" value={additionalClaimVariable.dropoff_location_data.country} onValueChange={(e) => onHandleChangeDropOffAdress(e)} label={i18n.t("searchClaims.professionalClaims.additionalClaimInf.dropCountry")} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="row padding-top20 padding-left15">
                    <div className="div-inline">
                        <label className="margin-top-3px">{i18n.t("searchClaims.professionalClaims.additionalClaimInf.labNote")}</label>
                    </div>
                </div>
                <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
                <div className="row">
                    <div className='col-9'>
                    <div className="form-group">
                        <TextAreaInput className="" name="lab_notes" value={additionalClaimVariable.lab_notes} onValueChange={(e) => onHandleChange(e)}
                            placeholder={i18n.t("searchClaims.professionalClaims.additionalClaimInf.labNote")} />
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default AdditionalClaimInfo